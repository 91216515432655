import moment from 'moment';

import { Modal } from 'flowbite-react';
import { TextArea } from '../../../atoms/TextArea';
import { CommonButton } from '../../../atoms/CommonButton';
import { TableContent } from '../../../table/TableContent';
import { CommonAlert } from '../../../atoms/Alert';
import { Spinner } from '../../../atoms/Spinner';

const columns = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    render: (text: string) =>
      text === 'No Notes Available!' ? text : moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : '',
  },
  {
    title: 'Comment',
    dataIndex: 'comment',
    key: 'comment',
    render: (text: any, record: any) => (
      <span>
        {record?.user ? <span className="font-bold">{record.user}: </span> : ''}
        {record?.comment}
      </span>
    ),
  },
];

export const NotesModal = ({
  visibleNotesModal,
  handleOnClose,
  dataSource,
  onChangeNotesFields,
  editNotesFormData,
  onSubmit,
  dataSourceLoading,
  alertObj,
  visibleAlert,
  handleAlertClose,
}: any) => {
  return (
    <Modal show={visibleNotesModal} onClose={handleOnClose}>
      <Modal.Header>View Notes</Modal.Header>
      <Modal.Body>
        {visibleAlert && alertObj?.error && (
          <CommonAlert
            color={alertObj?.color}
            message={alertObj?.message}
            onClose={handleAlertClose}
            alertClassName="w-1/2"
          />
        )}
        <div className="grid md:grid-cols-1 gap-5 mt-2 mb-4">
          <TextArea
            onChange={onChangeNotesFields}
            name="note"
            label="Note"
            value={editNotesFormData?.note ?? ''}
            required
          />
        </div>

        {dataSourceLoading ? (
          <Spinner />
        ) : (
          <TableContent
            columns={columns}
            dataSource={dataSource?.length > 0 ? dataSource : [{ date: 'No Notes Available!', comment: '' }]}
          />
        )}
      </Modal.Body>
      <Modal.Footer className="grid md:grid-cols-2">
        <div className="col-span-1"></div>
        <div className="col-span-1 grid md:grid-cols-2 gap-2">
          <CommonButton type="button" label={'Close'} buttonType="secondary" onClick={handleOnClose} />
          <CommonButton type="button" label={'Save'} buttonType="primary" onClick={onSubmit} />
        </div>
      </Modal.Footer>
    </Modal>
  );
};
