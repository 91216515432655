import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks/hooks';
import moment from 'moment';

import { MainHeader } from '../../components/mainHeader/MainHeader';
import { PageTopic } from '../../components/pageTopic/PageTopic';
import { TableContent } from '../../components/table/TableContent';
import { EmptyContent } from '../../components/emptyContent/EmptyContent';
import { CommonAlert } from '../../components/atoms/Alert';
import { Pagination } from '../../components/pagination/Pagination';
import { Spinner } from '../../components/atoms/Spinner';
import { ExpectedVsCollectionsReportAdvanceSearch } from '../../components/tableSearch/ExpectedVsCollectionsReportAdvanceSearch';

import {
  getExpectedVsCollectionsReportRequest,
  getExpectedVsCollectionsReportSelector,
  clearFetchExpectedVsCollectionsReportResponse,
} from '../../redux/slices/reports/getExpectedVsCollectionsReportSlice';
import {
  getSystemProvidersRequest,
  getSystemProvidersSelector,
} from '../../redux/slices/system/getSystemProvidersSlice';

import { formatCurrency } from '../../utils/commonFunctions';

const PAGE_SIZE = 10;

const columns = [
  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
  },
  {
    title: 'Payer',
    dataIndex: 'payer',
    key: 'payer',
  },
  {
    title: 'Amount Billed',
    dataIndex: 'amountBilled',
    key: 'amountBilled',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Date Billed',
    dataIndex: 'billedDate',
    key: 'billedDate',
    render: (text: string) => {
      return moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : '';
    },
  },
  {
    title: 'Amount Expected',
    dataIndex: 'expectedAmount',
    key: 'expectedAmount',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Amount Received',
    dataIndex: 'receivedAmount',
    key: 'receivedAmount',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Date Received',
    dataIndex: 'receivedDate',
    key: 'receivedDate',
    render: (text: string) => {
      return moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : '';
    },
  },
];

const dummyDataSource = [
  {
    state: 'MI',
    payer: 'MI AETNA',
    amountBilled: 280,
    dateBilled: '2024-10-14',
    amountExpected: 280,
    amountReceived: 197.21,
    dateReceived: '2024-10-23',
  },
  {
    state: 'MI',
    payer: 'MI AETNA',
    amountBilled: 112.32,
    dateBilled: '2024-10-16',
    amountExpected: 112.32,
    amountReceived: 89.93,
    dateReceived: '2024-10-21',
  },
  {
    state: 'MI',
    payer: 'MI AETNA',
    amountBilled: 87.21,
    dateBilled: '2024-09-12',
    amountExpected: 0,
    amountReceived: 0,
    dateReceived: '2024-10-03',
  },
];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  {
    id: 'expectedVsCollectionsReport',
    label: 'Expected vs Collections - Detailed Report',
    status: 'active',
    link: 'expected-vs-collection-report',
  },
];

const initialAlertState = {
  color: '',
  message: '',
  error: false,
};

export const ExpectedVsCollectionsReportPage = () => {
  const dispatch = useAppDispatch();

  const {
    fetchExpectedVsCollectionsReportData,
    fetchExpectedVsCollectionsReportStatus,
    fetchExpectedVsCollectionsReportLoading,
  } = useAppSelector(getExpectedVsCollectionsReportSelector);
  const { systemProvidersProviderData } = useAppSelector(getSystemProvidersSelector);

  const [dataSource, setDataSource] = useState<any[]>([]);
  const [filterObject, setFilterObject] = useState<any>({
    type: 'DATE_CREATED',
    dateFrom: null,
    dateTo: null,
    providerId: null,
    state: null,
  });
  const [searchTerm, setSearchTerm] = useState<string>('');

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>(initialAlertState);

  useEffect(() => {
    fetchSystemProviders();
    setVisibleAlert(false);
    setAlertObj(initialAlertState);
  }, []);

  useEffect(() => {
    if (filterObject) {
      fetchExpectedVsCollectionsReport(0, searchTerm);
    }
  }, [filterObject, searchTerm]);

  useEffect(() => {
    if (fetchExpectedVsCollectionsReportStatus === 'SUCCESS') {
      setDataSource(fetchExpectedVsCollectionsReportData?.items);
      setCurrentPage(fetchExpectedVsCollectionsReportData?.currentPage);
      setTotalPages(fetchExpectedVsCollectionsReportData?.totalPages);
      dispatch(clearFetchExpectedVsCollectionsReportResponse());
    } else if (fetchExpectedVsCollectionsReportStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj(initialAlertState);
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearFetchExpectedVsCollectionsReportResponse());
    }
  }, [fetchExpectedVsCollectionsReportStatus]);

  const onPageChange = (page: any) => {
    setCurrentPage(page - 1);
    fetchExpectedVsCollectionsReport(page - 1);
  };

  const fetchExpectedVsCollectionsReport = async (pageNumber = currentPage, searchValue = searchTerm) => {
    const updatedFilterObject = { page: pageNumber, size: PAGE_SIZE, search: searchValue, ...filterObject };
    dispatch(getExpectedVsCollectionsReportRequest({ filters: updatedFilterObject }));
  };

  const fetchSystemProviders = async () => {
    dispatch(getSystemProvidersRequest());
  };

  const handleSearch = (data: any) => {
    const updatedData = { ...data };

    updatedData.providerId = updatedData.providerId ? parseInt(updatedData.providerId, 10) : null;

    updatedData.state = updatedData.state || null;

    setCurrentPage(0);
    setFilterObject(updatedData);
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
    setAlertObj(initialAlertState);
  };

  return (
    <div className="main-content">
      <MainHeader />
      <PageTopic
        mainTitle="Expected vs Collections - Detailed Report"
        enablePrimaryButton={false}
        breadCrumbArr={breadCrumbArr}
      />
      <ExpectedVsCollectionsReportAdvanceSearch onSubmit={handleSearch} />

      {visibleAlert && alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}

      <div className="flex justify-end mb-4">
        <div className="flex items-center">
          <label htmlFor="search" className="mr-2 text-gray-600">
            Search:
          </label>
          <input
            type="text"
            className="border border-gray-300 rounded px-2 py-1 focus:outline-0 focus:ring-primaryDefault focus:border-primaryDefault"
            name="search"
            title="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      {fetchExpectedVsCollectionsReportLoading ? (
        <Spinner />
      ) : (
        <>
          {dataSource?.length > 0 ? (
            <>
              <TableContent columns={columns} dataSource={dataSource} enableActions={false} />
              {totalPages > 1 && (
                <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
              )}
            </>
          ) : (
            <EmptyContent mode="VIEW" enableCreateButton={false} />
          )}
        </>
      )}
    </div>
  );
};
