import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks/hooks';

import { TableContent } from '../../../table/TableContent';
import { Pagination } from '../../../pagination/Pagination';
import { ConfirmModal } from '../visit/ConfirmModal';
import { CommonAlert } from '../../../atoms/Alert';
import { EmptyContent } from '../../../emptyContent/EmptyContent';
import { Spinner } from '../../../atoms/Spinner';
import { AddPayment } from './AddPayment';

import { IoIosInformationCircleOutline } from 'react-icons/io';

import {
  getPaymentByIdRequest,
  getPaymentByIdSelector,
  clearFetchPaymentByIdResponse,
} from '../../../../redux/slices/patient/getPaymentByIdSlice';
import {
  editPatientPaymentRequest,
  editPatientPaymentSelector,
  clearEditPatientPaymentResponse,
} from '../../../../redux/slices/patient/editPaymentSlice';
import {
  deletePatientPaymentRequest,
  deletePatientPaymentSelector,
  clearDeletePatientPaymentResponse,
} from '../../../../redux/slices/patient/deletePaymentSlice';
import { getPaymentListSelector } from '../../../../redux/slices/patient/getPaymentListSlice';
import { getSystemBillingCodeListSelector } from '../../../../redux/slices/system/getSystemBillingCodeListSlice';

import moment from 'moment';

import { formatCurrency, formatDate } from '../../../../utils/commonFunctions';
import { createPatientPaymentValidation } from '../../../../utils/patientValidation';

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Payment Date',
    dataIndex: 'paymentDate',
    key: 'paymentDate',
    render: (text: string) => {
      return moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : '';
    },
  },
  {
    title: 'Billing Code',
    dataIndex: 'billingCode',
    key: 'billingCode',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Check Number',
    dataIndex: 'checkNumber',
    key: 'checkNumber',
  },
  {
    title: 'Who Paid',
    dataIndex: 'whoPaid',
    key: 'whoPaid',
  },
  {
    title: 'Creator',
    dataIndex: 'creator',
    key: 'creator',
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (text: string) => {
      return moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : '';
    },
  },
];

const dummyDataSource = [
  {
    id: 1001,
    paymentDate: '2023-09-15',
    billingCode: '302',
    amount: '250.00',
    checkNumber: 'CHK-10234',
    whoPaid: 'John Doe',
    creator: 'Admin',
    createdAt: '2023-09-01',
  },
  {
    id: 1002,
    paymentDate: '2023-09-18',
    billingCode: 'BC-002',
    amount: '320.50',
    checkNumber: 'CHK-10235',
    whoPaid: 'Jane Smith',
    creator: 'Admin',
    createdAt: '2023-09-02',
  },
  {
    id: 1003,
    paymentDate: '2023-09-20',
    billingCode: 'BC-003',
    amount: '400.75',
    checkNumber: 'CHK-10236',
    whoPaid: 'Robert Brown',
    creator: 'Admin',
    createdAt: '2023-09-03',
  },
  {
    id: 1004,
    paymentDate: '2023-09-25',
    billingCode: 'BC-004',
    amount: '150.00',
    checkNumber: 'CHK-10237',
    whoPaid: 'Emily Davis',
    creator: 'Admin',
    createdAt: '2023-09-04',
  },
  {
    id: 1005,
    paymentDate: '2023-09-30',
    billingCode: 'BC-005',
    amount: '500.00',
    checkNumber: 'CHK-10238',
    whoPaid: 'Michael Clark',
    creator: 'Admin',
    createdAt: '2023-09-05',
  },
];

export const PaymentList = ({
  dataSource,
  fetchPatientById,
  fetchPaymentList,
  setVisiblePaymentList,
  selectedId,
  onPageChange,
  totalPages,
  currentPage,
  mode,
  billingCodeList,
  whoPaidList,
  visitCptId,
}: any) => {
  const dispatch = useAppDispatch();
  const { deleteTransactionPaymentStatus, deleteTransactionPaymentError } =
    useAppSelector(deletePatientPaymentSelector);
  const { editTransactionPaymentStatus, editTransactionPaymentError } = useAppSelector(editPatientPaymentSelector);
  const { fetchTransactionPaymentByIdStatus, fetchTransactionPaymentByIdData } = useAppSelector(getPaymentByIdSelector);
  const { fetchSystemBillingCodeListData } = useAppSelector(getSystemBillingCodeListSelector);
  const { fetchPaymentListLoading } = useAppSelector(getPaymentListSelector);

  const [selectedPaymentId, setSelectedPaymentId] = useState<number | null>(null);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [visibleEditModal, setVisibleEditModal] = useState<boolean>(false);
  const [editPaymentFormData, setEditPaymentFormData] = useState<any>({});
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [errorObj, setErrorObj] = useState<any>({});
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>({
    color: '',
    message: '',
    error: false,
  });

  useEffect(() => {
    if (editTransactionPaymentStatus === 'SUCCESS') {
      fetchPaymentList();
      setVisibleEditModal(false);
      setAlertObj({
        color: 'success',
        message: 'Successfully updated !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearEditPatientPaymentResponse());
    } else if (editTransactionPaymentStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: editTransactionPaymentError?.message ?? 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearEditPatientPaymentResponse());
    }
  }, [editTransactionPaymentStatus]);

  useEffect(() => {
    if (deleteTransactionPaymentStatus === 'SUCCESS') {
      setOpenConfirmModal(false);
      fetchPaymentList();
      setAlertObj({
        color: 'success',
        message: 'Successfully deleted !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    } else if (deleteTransactionPaymentStatus === 'FAILED') {
      setOpenConfirmModal(false);
      setAlertObj({
        color: 'failure',
        message: deleteTransactionPaymentError?.message ?? 'Something went wrong!',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
    }
    dispatch(clearDeletePatientPaymentResponse());
  }, [deleteTransactionPaymentStatus]);

  useEffect(() => {
    if (fetchTransactionPaymentByIdStatus === 'SUCCESS') {
      setEditPaymentFormData(fetchTransactionPaymentByIdData);
      setVisibleEditModal(true);
      dispatch(clearFetchPaymentByIdResponse());
    } else if (fetchTransactionPaymentByIdStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Error when fetching payment edit data!',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearFetchPaymentByIdResponse());
    }
  }, [fetchTransactionPaymentByIdStatus]);

  const onEdit = (event: any, rowIndex: any) => {
    event.preventDefault();
    const dataSet = dataSource[rowIndex];
    setSelectedPaymentId(dataSet?.id);
    fetchPaymentDataById(dataSet?.id);
  };

  const onDelete = (event: any, rowIndex: any) => {
    event.preventDefault();
    const dataset = dataSource[rowIndex];
    setSelectedPaymentId(dataset?.id);
    setOpenConfirmModal(true);
  };

  const fetchPaymentDataById = (id: number) => {
    dispatch(getPaymentByIdRequest({ paymentId: id }));
  };

  const confirmDelete = () => {
    dispatch(deletePatientPaymentRequest({ paymentId: selectedPaymentId }));
  };

  const onCloseConfirm = () => {
    setOpenConfirmModal(false);
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
  };

  const createErrorAlert = (validationDetails: any) => {
    const errorMessages = Object.values(validationDetails.newErrors);
    return {
      message: (
        <div>
          {errorMessages.map((msg: any, index) => (
            <div key={index} className="flex items-center">
              <IoIosInformationCircleOutline />
              <span className="ml-2">{msg}</span>
            </div>
          ))}
        </div>
      ),
      color: 'failure',
      error: true,
    };
  };

  const convertBeforeSubmit = (formData: any) => {
    const obj = {
      ...formData,
      whoPaid: parseInt(formData?.whoPaid, 10),
      allowedAmount: formData?.allowedAmount ? Number(parseFloat(formData?.allowedAmount).toFixed(2)) : undefined,
      amount: formData?.amount ? Number(parseFloat(formData?.amount).toFixed(2)) : undefined,
      paymentDate: formatDate(formData?.paymentDate),
      bankPostingDate: formatDate(formData?.bankPostingDate),
      visitCptId: visitCptId ?? 0,
    };

    return obj;
  };

  const handleEditPayment = () => {
    const validationDetails = createPatientPaymentValidation(editPaymentFormData);

    if (Object.keys(validationDetails?.newErrors)?.length > 0) {
      setAlertObj(createErrorAlert(validationDetails));
      setVisibleAlert(true);
    } else {
      const billingCodeType = editPaymentFormData?.billingCodeType;
      const amount = parseFloat(editPaymentFormData?.amount);
      const allowedAmount = editPaymentFormData?.allowedAmount ? parseFloat(editPaymentFormData.allowedAmount) : null;

      if (billingCodeType === 13 && (amount !== 0 || (allowedAmount !== null && allowedAmount !== 0))) {
        setAlertObj({
          color: 'failure',
          message: 'This transaction code can not apply with any Amount/Allowed Amount!',
          error: true,
        });
        setVisibleAlert(true);
        return;
      }

      const obj = {
        paymentId: selectedPaymentId,
        paymentData: convertBeforeSubmit(editPaymentFormData),
      };

      dispatch(editPatientPaymentRequest(obj));
    }
  };

  const handleBillingCodeChange = (e: any) => {
    const selectedCode = fetchSystemBillingCodeListData?.find((code: any) => code.id === Number(e.target.value));

    setEditPaymentFormData((prev: any) => ({
      ...prev,
      billingCodeType: selectedCode ? selectedCode?.type : '',
      billingCode: selectedCode ? selectedCode?.id : '',
    }));
  };

  const handlePaymentFormChange = (e: any) => {
    const { name, value, type, checked } = e.target;

    if (errorObj?.hasOwnProperty(name)) {
      delete errorObj[name];
    }

    const convertedValue = type === 'checkbox' ? checked : value;

    setEditPaymentFormData((prev: any) => ({
      ...prev,
      [name]: convertedValue,
    }));
  };

  const handlePaymentDateChange = (name: string, value: Date) => {
    if (errorObj?.hasOwnProperty(name)) {
      delete errorObj[name];
    }
    setEditPaymentFormData((prev: any) => ({
      ...prev,
      [name]: moment(value).format('YYYY-MM-DD'),
    }));
  };

  const handleOnCloseAddPaymentModel = () => {
    setEditPaymentFormData({});
    setVisibleAlert(false);
    setErrorObj({});
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
    setVisibleEditModal(false);
  };

  // console.log('Edit Form Data', editPaymentFormData);

  return (
    <>
      {visibleAlert && !alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}

      {fetchPaymentListLoading ? (
        <Spinner />
      ) : (
        <>
          {dataSource && dataSource?.length > 0 ? (
            <>
              <TableContent
                columns={columns}
                dataSource={dataSource}
                enableActions={mode === 'EDIT'}
                enableEdit={true}
                enableDelete={true}
                onDelete={onDelete}
                onEdit={onEdit}
              />
              {totalPages > 1 && (
                <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
              )}
            </>
          ) : (
            <EmptyContent mode="VIEW" enableCreateButton={false} />
          )}
        </>
      )}

      {openConfirmModal && (
        <ConfirmModal
          openConfirm={openConfirmModal}
          setOpenConfirm={onCloseConfirm}
          title="Delete Payment"
          content="Are you sure you want to delete this payment?"
          submitButtonTitle="Delete"
          cancelButtonTitle="Cancel"
          handleSubmit={confirmDelete}
          visibleAlert={visibleAlert}
          alertObj={alertObj}
          alertClassName="w-1/2"
          handleAlertClose={handleAlertClose}
        />
      )}

      {visibleEditModal && (
        <AddPayment
          visibleAddPaymentModel={visibleEditModal}
          addPaymentFormData={editPaymentFormData}
          onChange={handlePaymentFormChange}
          onDateChange={handlePaymentDateChange}
          onBillingCodeChange={handleBillingCodeChange}
          handleOnClose={handleOnCloseAddPaymentModel}
          onSubmit={handleEditPayment}
          visibleAlert={visibleAlert}
          alertObj={alertObj}
          handleAlertClose={handleAlertClose}
          billingCodesList={billingCodeList}
          whoPaidList={whoPaidList}
        />
      )}
    </>
  );
};
