import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks/hooks';
import moment from 'moment';

import { InputField } from '../../../components/atoms/InputField';
import { PrimaryButton } from '../../../components/atoms/PrimaryButton';
import { CommonButton } from '../../../components/atoms/CommonButton';
import { CustomSearchButton } from '../../../components/list/patients/visit/AddCPTModal';
import { SelectInput } from '../../../components/atoms/SelectInput';
import { DatePickerField } from '../../../components/atoms/DatePickerField';
import { CommonAlert } from '../../../components/atoms/Alert';
import { Spinner } from '../../../components/atoms/Spinner';
import { MainHeader } from '../../../components/mainHeader/MainHeader';
import { PageTopic } from '../../../components/pageTopic/PageTopic';
import { BulkPaymentsCustomTable } from '../../../components/table/BulkPaymentsCustomTable';
import { PatientSearchModal } from '../../../components/billing/batchPayment/bulkPayments/PatientSearchModal';
import { AddDescriptionModal } from '../../../components/billing/batchPayment/bulkPayments/AddDescriptionModal';

import {
  getBatchPaymentByIdForApplyRequest,
  getBatchPaymentByIdForApplySelector,
  clearFetchBatchPaymentDetailsByIdForApplyResponse,
} from '../../../redux/slices/payments/batchPayments/getBatchPaymentByIdForApplySlice';
import {
  getBulkPaymentsListRequest,
  getBulkPaymentsListSelector,
  clearFetchBulkPaymentsListResponse,
} from '../../../redux/slices/payments/batchPayments/getBulkPaymentsListSlice';
import {
  getSystemBillingCodeListRequest,
  getSystemBillingCodeListSelector,
  clearFetchSystemBillingCodesResponse,
} from '../../../redux/slices/system/getSystemBillingCodeListSlice';
import {
  addBulkLinePaymentsRequest,
  addBulkLinePaymentsSelector,
  clearAddBulkLinePaymentsResponse,
} from '../../../redux/slices/payments/batchPayments/addBulkLinePaymentsSlice';
import {
  getBulkPaymentWhoPaidOptionsRequest,
  getBulkPaymentWhoPaidOptionsSelector,
  clearFetchBulkPaymentWhoPaidOptionsResponse,
} from '../../../redux/slices/payments/batchPayments/getBulkPaymentWhoPaidOptionsSlice';

import { BulkPaymentsPropsInterface } from '../../../interfaces/paymentInterfaces';

import { formatCurrency, formatDatePickerDate } from '../../../utils/commonFunctions';

const columns = (
  billingCodes: any,
  handleBillingCodeChange: any,
  handleRowDataChange: any,
  handleBankPostingDateChange: any,
  findRowBankPostingDate: any,
  findRowAmountBillingCode: any,
  findRowCheckNumberAllowedAmount: any,
  calculateBalanceDeductableAmount: any,
  batchType: any
) => {
  const isLockbox = batchType === 'LOCKBOX';

  const initialColumns = [
    {
      title: 'DOS',
      dataIndex: 'dos',
      key: 'dos',
      render: (text: string, record: any) => {
        return record.newRow ? <></> : moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : '';
      },
    },
    {
      title: 'CPT',
      dataIndex: 'cpt',
      key: 'cpt',
    },
    {
      title: 'Units',
      dataIndex: 'unit',
      key: 'unit',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (text: number, record: any) => {
        return record.newRow ? <></> : formatCurrency(text ?? 0);
      },
    },
    {
      title: 'Modifier',
      dataIndex: 'modifier',
      key: 'modifier',
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      key: 'balance',
      render: (text: number, record: any) => {
        if (record.newRow) return <></>;

        const deductions = calculateBalanceDeductableAmount(record?.patientVisitCptId);

        const updatedBalance = text - deductions;

        return formatCurrency(updatedBalance ?? 0);
      },
    },
    {
      title: 'Billing Code',
      dataIndex: 'billingCode',
      key: 'billingCode',
      render: (text: any, record: any) => {
        return (
          <SelectInput
            options={billingCodes}
            enableDefaultPlaceholder={true}
            name="billingCode"
            value={findRowAmountBillingCode(record?.patientVisitCptId, record?.rowId)?.billingCode ?? ''}
            // defaultValue={record?.billingCode ?? undefined}
            enableLabel={false}
            onChange={(e: any) => handleBillingCodeChange(record?.patientVisitCptId, e.target.value, record?.rowId)}
            disabled={record?.claim === 0}
          />
        );
      },
      width: '256px',
    },
    {
      title: 'Claim ID',
      dataIndex: 'claim',
      key: 'claim',
      // render: (text: any, record: any) => {
      //   if (record.newRow) return <></>;
      //   const claimIds = text.split(',').map((id: any) => ({ id, name: id }));
      //   return <SelectInput options={claimIds} enableDefaultPlaceholder={true} enableLabel={false} />;
      // },
    },
    {
      title: 'Amount',
      dataIndex: 'amountFillable',
      key: 'amountFillable',
      render: (text: any, record: any) => {
        return (
          <InputField
            placeholder="Amount"
            enableLabel={false}
            name="amount"
            type="number"
            value={findRowAmountBillingCode(record?.patientVisitCptId, record?.rowId)?.amount ?? ''}
            onChange={(e: any) => handleRowDataChange(record?.patientVisitCptId, e, record?.rowId)}
            disabled={record?.claim === 0}
          />
        );
      },
      width: '180px',
    },
    {
      title: 'Allowed',
      dataIndex: 'allowedAmount',
      key: 'allowedAmount',
      render: (text: any, record: any) => {
        return record.newRow ? (
          <></>
        ) : (
          <InputField
            placeholder="Allowed Amount"
            enableLabel={false}
            name="allowedAmount"
            value={findRowCheckNumberAllowedAmount(record?.patientVisitCptId)?.allowedAmount ?? ''}
            type="number"
            onChange={(e: any) => handleRowDataChange(record?.patientVisitCptId, e, record?.rowId)}
            disabled={record?.claim === 0}
          />
        );
      },
      width: '180px',
    },
  ];
  if (isLockbox) {
    initialColumns.splice(
      initialColumns.findIndex((column) => column.key === 'claim') + 1,
      0,
      {
        title: 'Bank Posting Date',
        dataIndex: 'bankPostingDate',
        key: 'bankPostingDate',
        render: (text: any, record: any) => {
          return record.newRow ? (
            <></>
          ) : (
            <DatePickerField
              name="bankPostingDate"
              selectedDate={formatDatePickerDate(findRowBankPostingDate(record?.patientVisitCptId))}
              onChange={(date: Date | null) => {
                if (date) {
                  handleBankPostingDateChange(record?.patientVisitCptId, date);
                }
              }}
              disabled={record?.claim === 0}
            />
          );
        },
        width: isLockbox ? '200px' : '',
      },
      {
        title: 'Check Number',
        dataIndex: 'checkNumber',
        key: 'checkNumber',
        render: (text: any, record: any) => {
          return record.newRow ? (
            <></>
          ) : (
            <InputField
              placeholder="Check Number"
              enableLabel={false}
              name="checkNumber"
              value={findRowCheckNumberAllowedAmount(record?.patientVisitCptId)?.checkNumber ?? ''}
              onChange={(e: any) => handleRowDataChange(record?.patientVisitCptId, e, record?.rowId)}
              disabled={record?.claim === 0}
            />
          );
        },
        width: isLockbox ? '180px' : '',
      }
    );
  }
  return initialColumns;
};

const dummyDataSource = {
  patient: {
    patientId: 218,
    patientName: 'Vishal Hank',
  },
  whoPaid: [
    {
      value: 'Patient',
      label: 'Vishal Hank',
    },
    {
      value: 'Primary',
      label: 'Medicare',
    },
    {
      value: 'Secondary',
      label: 'Health Care',
    },
    {
      value: 'Tertiary',
      label: 'Divine Life',
    },
  ],
  items: [
    {
      dos: '2024-01-01',
      cpt: '99213',
      units: 1,
      amount: 150,
      modifier: '25',
      balance: 50,
      claimId: '13425,12123,43434',
    },
    {
      dos: '2024-02-01',
      cpt: '99354',
      units: 2,
      amount: 300,
      modifier: '',
      balance: 100,
      claimId: '89321',
    },
  ],
};

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  { id: 'bulkPayment', label: 'Bulk Payment', status: 'active', link: 'bulk-payment' },
];

const initialAlertState = {
  color: '',
  message: '',
  error: false,
};

export const BulkPayments = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { fetchBatchPaymentByIdForApplyData, fetchBatchPaymentByIdForApplyStatus } = useAppSelector(
    getBatchPaymentByIdForApplySelector
  );
  const {
    fetchBulkPaymentListStatus,
    fetchBulkPaymentListData,
    fetchBulkPaymentListLoading,
    fetchBulkPaymentListError,
  } = useAppSelector(getBulkPaymentsListSelector);
  const { fetchSystemBillingCodeListData, fetchSystemBillingCodeListStatus } = useAppSelector(
    getSystemBillingCodeListSelector
  );
  const { addBulkLinePaymentsStatus } = useAppSelector(addBulkLinePaymentsSelector);
  const { fetchBulkPaymentWhoPaidOptionsStatus, fetchBulkPaymentWhoPaidOptionsData } = useAppSelector(
    getBulkPaymentWhoPaidOptionsSelector
  );

  const [patientSearchData, setPatientSearchData] = useState<any>({});
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [batchDetails, setBatchDetails] = useState<any>({});
  const [batchType, setBatchType] = useState<string | null>(null);
  const [paymentsData, setPaymentsData] = useState<any>({ paymentDetails: [] });
  const [insurancesList, setInsuranceList] = useState<string[]>([]);
  const [whoPaidOptions, setWhoPaidOptions] = useState<{ id: number; name: string }[]>([]);
  const [billingCodeList, setBillingCodeList] = useState<any[]>([]);
  const [patientData, setPatientData] = useState<any>({});
  const [selectedPatientVisitCptId, setSelectedPatientVisitCptId] = useState<number | null>(null);
  const [visiblePatientSearchModal, setVisiblePatientSearchModal] = useState<boolean>(false);
  const [visibleAddDescriptionModal, setVisibleAddDescriptionModal] = useState<boolean>(false);
  const [previousPatientId, setPreviousPatientId] = useState<string | null>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [visibleBillingCodeAlert, setVisibleBillingCodeAlert] = useState<boolean>(false);
  const [isVisitsEmpty, setIsVisitsEmpty] = useState<boolean>(false);
  const [hasCompleteCptDetail, setHasCompleteCptDetail] = useState<boolean>(false);
  const [rowDescription, setRowDescription] = useState<string>('');
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [whoPaidReloading, setWhoPaidReloading] = useState<boolean>(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>(initialAlertState);
  const [billingCodeAlertMessage, setBillingCodeAlertMessage] = useState<string>('');
  const [batchDataLoading, setBatchDataLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchSystemBillingCodes();
    setVisibleAlert(false);
    setVisibleBillingCodeAlert(false);
    setBillingCodeAlertMessage('');
    setAlertObj(initialAlertState);
  }, []);

  useEffect(() => {
    if (id) {
      fetchBatchPaymentByIdForApply();
    }
  }, [id]);

  useEffect(() => {
    if (fetchBatchPaymentByIdForApplyStatus === 'SUCCESS') {
      setBatchDetails(fetchBatchPaymentByIdForApplyData);
      setBatchType(fetchBatchPaymentByIdForApplyData?.batchType);
      setBatchDataLoading(false);
      dispatch(clearFetchBatchPaymentDetailsByIdForApplyResponse());
    } else if (fetchBatchPaymentByIdForApplyStatus === 'FAILED') {
      setBatchDataLoading(false);
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong when fetching batch data!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearFetchBatchPaymentDetailsByIdForApplyResponse());
    }
  }, [fetchBatchPaymentByIdForApplyStatus]);

  useEffect(() => {
    if (fetchBulkPaymentListStatus === 'SUCCESS') {
      const isCasesEmpty = fetchBulkPaymentListData?.cases?.length === 0;
      // const isAllVisitCptListsEmpty = fetchBulkPaymentListData?.cases?.every(
      //   (caseData: any) => caseData?.visitCptList?.length === 0
      // );

      if (isCasesEmpty) {
        setIsVisitsEmpty(true);
        setAlertObj({
          color: 'success',
          message: 'No visits available for this patient!',
          error: true,
        });
        setVisibleAlert(true);
        setTimeout(() => {
          setAlertObj(initialAlertState);
          setVisibleAlert(false);
        }, 5000);
      } else {
        const updatedDataCases = addUniqueRowId(fetchBulkPaymentListData?.cases);
        setDataSource(updatedDataCases);

        const initializedPaymentDetails = initializePaymentDetails(updatedDataCases);
        setPaymentsData({ paymentDetails: initializedPaymentDetails });
        // setDataSource(fetchBulkPaymentListData?.cases);
        setPatientData(fetchBulkPaymentListData?.patient);

        // const formattedOptions = formatWhoPaidOptions(fetchBulkPaymentListData?.whoPaid);
        // setWhoPaidOptions(formattedOptions);

        const insuranceArray = createInsuranceList(fetchBulkPaymentListData?.whoPaid);
        setInsuranceList(insuranceArray);
      }
      setRowDescription('');
      setIsLoaded(true);
      dispatch(clearFetchBulkPaymentsListResponse());
    } else if (fetchBulkPaymentListStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: fetchBulkPaymentListError?.message ?? 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj(initialAlertState);
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearFetchBulkPaymentsListResponse());
    }
  }, [fetchBulkPaymentListStatus]);

  useEffect(() => {
    if (fetchBulkPaymentWhoPaidOptionsStatus === 'SUCCESS') {
      const formattedOptions = formatWhoPaidOptions(fetchBulkPaymentWhoPaidOptionsData);
      setWhoPaidOptions(formattedOptions);
      setWhoPaidReloading(false);
      dispatch(clearFetchBulkPaymentWhoPaidOptionsResponse());
    } else if (fetchBulkPaymentWhoPaidOptionsStatus === 'FAILED') {
      setWhoPaidReloading(false);
    }
  }, [fetchBulkPaymentWhoPaidOptionsStatus]);

  useEffect(() => {
    if (addBulkLinePaymentsStatus === 'SUCCESS') {
      setSubmitting(false);
      setAlertObj({
        color: 'success',
        message: 'Submitted Successfully!',
        error: true,
      });
      setVisibleAlert(true);
      fetchBulkPaymentsList(patientData?.id);
      fetchBatchPaymentByIdForApply();
      setPaymentsData({ paymentDetails: [] });
      setIsLoaded(false);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj(initialAlertState);
      }, 3000);
      dispatch(clearAddBulkLinePaymentsResponse());
    } else if (addBulkLinePaymentsStatus === 'FAILED') {
      setSubmitting(false);
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj(initialAlertState);
      }, 3000);
      dispatch(clearAddBulkLinePaymentsResponse());
    }
  }, [addBulkLinePaymentsStatus]);

  useEffect(() => {
    if (fetchSystemBillingCodeListStatus === 'SUCCESS') {
      const formattedBillingCodes = formatBillingCodes(fetchSystemBillingCodeListData);
      setBillingCodeList(formattedBillingCodes);
    }
  }, [fetchSystemBillingCodeListStatus]);

  useEffect(() => {
    if (patientSearchData?.patientId && patientSearchData.patientId !== previousPatientId) {
      setPatientSearchData((prev: any) => ({
        ...prev,
        patientName: '',
        patientId: patientSearchData.patientId,
      }));
    }
  }, [patientSearchData.patientId, previousPatientId]);

  // useEffect(() => {
  //   const completeDetailExists = paymentsData?.paymentDetails?.some((caseData: any) =>
  //     caseData?.cptDetails?.some(isCptDetailsComplete)
  //   );

  //   setHasCompleteCptDetail(completeDetailExists);
  // }, [paymentsData]);

  const addUniqueRowId = (cases: any[]) => {
    return cases.map((caseObj: any, caseIndex: number) => {
      const filteredVisitCptList =
        caseObj?.visitCptList?.filter((visit: any) => visit.claim !== 0 && visit.balance !== 0) ?? [];

      const updatedVisitCptList = filteredVisitCptList.map((visit: any, index: number) => ({
        ...visit,
        rowId: `${caseIndex}-${index}`,
      }));

      return {
        ...caseObj,
        visitCptList: updatedVisitCptList,
      };
    });
  };

  const initializePaymentDetails = (cases: any[]) => {
    return cases.map((caseObj: any) => ({
      caseId: caseObj.caseId,
      cptDetails: caseObj.visitCptList.map((visit: any) => ({
        patientVisitCptId: visit.patientVisitCptId,
        claimId: visit.claim ?? null,
        bankPostingDate: null,
        checkNumber: null,
        allowedAmount: null,
        description: null,
        lineDetails: [],
      })),
    }));
  };

  const createInsuranceList = (whoPaid: any[] = []) =>
    whoPaid
      .filter((item) => item.type)
      .map((item) => `${item.type.charAt(0).toUpperCase() + item.type.slice(1).toLowerCase()}: ${item.name}`);

  const findCaseData = (patientVisitCptId: any) => {
    return (
      dataSource
        ?.flatMap((caseData) => caseData?.visitCptList?.map((visit: any) => ({ caseId: caseData.caseId, visit })))
        .find((entry) => entry.visit.patientVisitCptId === patientVisitCptId) || null
    );
  };

  const handleUpdatePaymentsData = (patientVisitCptId: any, updateData: { [key: string]: any }, rowIndex?: any) => {
    const caseDetails = findCaseData(patientVisitCptId);

    // console.log('Case Details', caseDetails);
    if (caseDetails) {
      setPaymentsData((prev: any) => {
        const updatedPaymentDetails = [...prev.paymentDetails];
        let caseIndex = updatedPaymentDetails.findIndex((detail: any) => detail.caseId === caseDetails.caseId);

        if (caseIndex === -1) {
          updatedPaymentDetails.push({
            caseId: caseDetails?.caseId,
            cptDetails: [],
          });
          caseIndex = updatedPaymentDetails?.length - 1;
        }

        const visitIndex = updatedPaymentDetails[caseIndex].cptDetails.findIndex(
          (visit: any) => visit?.patientVisitCptId === patientVisitCptId
        );

        const lineDetailFields = ['amount', 'billingCode', 'billingCodeType'];

        if (visitIndex === -1) {
          const cptDetailsUpdate: any = {
            patientVisitCptId,
            claimId: caseDetails?.visit?.claim ?? null,
            lineDetails: [],
          };

          Object.keys(updateData).forEach((key) => {
            if (lineDetailFields.includes(key)) {
              const existingLineDetail = cptDetailsUpdate.lineDetails.find(
                (detail: any) => detail.rowIndex === rowIndex
              );

              if (existingLineDetail) {
                existingLineDetail[key] = updateData[key];
              } else {
                cptDetailsUpdate.lineDetails.push({
                  rowIndex,
                  [key]: updateData[key],
                });
              }
            } else {
              cptDetailsUpdate[key] = updateData[key];
            }
          });

          updatedPaymentDetails[caseIndex].cptDetails.push(cptDetailsUpdate);
        } else {
          const visit = updatedPaymentDetails[caseIndex].cptDetails[visitIndex];

          Object.keys(updateData).forEach((key) => {
            if (lineDetailFields.includes(key)) {
              const existingLineDetail = visit.lineDetails.find((detail: any) => detail.rowIndex === rowIndex);

              if (existingLineDetail) {
                existingLineDetail[key] = updateData[key];
              } else {
                visit.lineDetails.push({
                  rowIndex,
                  [key]: updateData[key],
                });
              }
            } else {
              visit[key] = updateData[key];
            }
          });

          updatedPaymentDetails[caseIndex].cptDetails[visitIndex] = visit;
          // updatedPaymentDetails[caseIndex].cptDetails[visitIndex] = {
          //   ...updatedPaymentDetails[caseIndex].cptDetails[visitIndex],
          //   ...updateData,
          // };
        }

        return { ...prev, paymentDetails: updatedPaymentDetails };
      });
    }
  };

  // const isCptDetailsComplete = (cptDetail: any) => {
  //   if (!cptDetail) return false;

  //   const requiredFields = ['patientVisitCptId'];

  //   if (batchType === 'LOCKBOX') {
  //     requiredFields.push('checkNumber', 'bankPostingDate');
  //   }

  //   const hasRequiredFields = requiredFields.every(
  //     (field) => cptDetail[field] !== undefined && cptDetail[field] !== null && cptDetail[field] !== ''
  //   );

  //   if (!hasRequiredFields) {
  //     return false;
  //   }

  //   if (!cptDetail?.lineDetails || cptDetail.lineDetails.length === 0) {
  //     return false;
  //   }

  //   const isLineDetailComplete = cptDetail.lineDetails.some((lineDetail: any) => {
  //     const requiredLineFields = ['billingCode', 'billingCodeType', 'amount'];
  //     return requiredLineFields.every(
  //       (field) => lineDetail[field] !== undefined && lineDetail[field] !== null && lineDetail[field] !== ''
  //     );
  //   });

  //   return isLineDetailComplete;
  // };

  const findRowDescription = (patientVisitCptId: any) =>
    paymentsData?.paymentDetails
      ?.flatMap((caseDetail: any) => caseDetail?.cptDetails)
      .find((cpt: any) => cpt.patientVisitCptId === patientVisitCptId)?.description || null;

  const findRowBankPostingDate = (patientVisitCptId: any) =>
    paymentsData?.paymentDetails
      ?.flatMap((caseDetail: any) => caseDetail?.cptDetails)
      .find((cpt: any) => cpt.patientVisitCptId === patientVisitCptId)?.bankPostingDate || null;

  const findRowCheckNumberAllowedAmount = (patientVisitCptId: any) => {
    const cptDetails = paymentsData?.paymentDetails
      ?.flatMap((caseDetail: any) => caseDetail?.cptDetails)
      .find((cpt: any) => cpt.patientVisitCptId === patientVisitCptId);

    if (!cptDetails) {
      return { checkNumber: null, allowedAmount: null };
    }

    return {
      checkNumber: cptDetails.checkNumber || null,
      allowedAmount: cptDetails.allowedAmount || null,
    };
  };

  const findRowAmountBillingCode = (patientVisitCptId: any, rowId: any) => {
    const cptDetails = paymentsData?.paymentDetails
      ?.flatMap((caseDetail: any) => caseDetail?.cptDetails)
      .find((cpt: any) => cpt.patientVisitCptId === patientVisitCptId);

    if (!cptDetails) {
      return { amount: null, billingCode: null };
    }

    const lineDetail = cptDetails.lineDetails?.find((line: any) => line.rowIndex === rowId);

    return {
      amount: lineDetail?.amount || null,
      billingCode: lineDetail?.billingCode || null,
    };
  };

  const calculateBalanceDeductableAmount = (patientVisitCptId: any): number => {
    const cptRowLineDetails = paymentsData?.paymentDetails
      ?.flatMap((caseDetail: any) => caseDetail?.cptDetails)
      .find((cpt: any) => cpt.patientVisitCptId === patientVisitCptId)
      ?.lineDetails?.filter((line: any) => line?.rowIndex != null);

    if (!cptRowLineDetails || cptRowLineDetails.length === 0) {
      return 0;
    }

    return cptRowLineDetails.reduce((sum: number, line: any) => {
      const billingCodeType = line?.billingCodeType ? parseInt(line.billingCodeType, 10) : null;

      if (billingCodeType == null || billingCodeType === 13) {
        return sum;
      }

      const convertedAmount = Number(line.amount);
      return sum + (!isNaN(convertedAmount) ? convertedAmount : 0);
    }, 0);
  };

  const handleWhoPaidChange = (e: any) => {
    setPaymentsData((prev: any) => ({
      ...prev,
      whoPaid: e.target.value,
    }));
  };

  const fetchBatchPaymentByIdForApply = () => {
    dispatch(getBatchPaymentByIdForApplyRequest({ paymentId: id }));
  };

  const fetchBulkPaymentsList = (patientId: number) => {
    dispatch(getBulkPaymentsListRequest({ patientId }));
  };

  const fetchBulkPaymentWhoPaidOptions = (patientId: number) => {
    dispatch(getBulkPaymentWhoPaidOptionsRequest(patientId));
  };

  const onSearch = () => {
    setVisibleBillingCodeAlert(false);
    setBillingCodeAlertMessage('');
    setPaymentsData({ paymentDetails: [] });
    setIsLoaded(false);
    setIsVisitsEmpty(false);
    fetchBulkPaymentsList(patientSearchData?.patientId);
    fetchBulkPaymentWhoPaidOptions(patientSearchData?.patientId);
  };

  const handleReload = () => {
    setPaymentsData((prev: any) => ({
      ...prev,
      whoPaid: '',
    }));

    setWhoPaidReloading(true);

    const patientId = patientSearchData?.patientId;

    if (patientId) {
      fetchBulkPaymentWhoPaidOptions(patientId);
    }
  };

  const convertBeforeSubmit = (paymentData: any) => {
    const obj = {
      ...paymentData,
      patientId: parseInt(paymentData?.patientId, 10),
      whoPaid: paymentData?.whoPaid ? parseInt(paymentData.whoPaid, 10) : null,
      paymentDetails: paymentData?.paymentDetails?.map((caseData: any) => ({
        ...caseData,
        caseId: parseInt(caseData?.caseId, 10),
        cptDetails: caseData?.cptDetails?.map((cptDetail: any) => ({
          ...cptDetail,
          patientVisitCptId: parseInt(cptDetail.patientVisitCptId, 10),
          claimId: cptDetail?.claimId ? parseInt(cptDetail.claimId, 10) : null,
          bankPostingDate: cptDetail.bankPostingDate || null,
          checkNumber: cptDetail.checkNumber || null,
          allowedAmount: cptDetail.allowedAmount ? Number(parseFloat(cptDetail.allowedAmount).toFixed(2)) : null,
          lineDetails: cptDetail.lineDetails
            .sort((a: any, b: any) => {
              const rowA = parseInt(a.rowIndex.split('-')[1]);
              const rowB = parseInt(b.rowIndex.split('-')[1]);
              return rowA - rowB;
            })
            .map((lineDetail: any) => ({
              billingCode:
                lineDetail.billingCode !== '' && lineDetail.billingCode != null
                  ? parseInt(lineDetail.billingCode, 10)
                  : null,
              billingCodeType:
                lineDetail.billingCodeType !== '' && lineDetail.billingCodeType != null
                  ? parseInt(lineDetail.billingCodeType, 10)
                  : null,
              amount: lineDetail.amount ? Number(parseFloat(lineDetail.amount).toFixed(2)) : null,
            })),
        })),
      })),
    };

    return obj;
  };

  const onSubmit = () => {
    // if (!paymentsData?.whoPaid) {
    //   setAlertObj({
    //     color: 'failure',
    //     message: 'Who Paid is required!',
    //     error: true,
    //   });
    //   setVisibleAlert(true);
    //   setTimeout(() => {
    //     setAlertObj(initialAlertState);
    //     setVisibleAlert(false);
    //   }, 4000);
    //   return;
    // }

    // const filteredPaymentsData = paymentsData?.paymentDetails
    //   .map((caseData: any) => ({
    //     ...caseData,
    //     cptDetails: caseData?.cptDetails?.filter(isCptDetailsComplete).map((cptDetail: any) => ({
    //       ...cptDetail,
    //       lineDetails: cptDetail.lineDetails.filter((lineDetail: any) => {
    //         const requiredLineFields = ['billingCode', 'billingCodeType', 'amount'];
    //         return requiredLineFields.every(
    //           (field) => lineDetail[field] !== undefined && lineDetail[field] !== null && lineDetail[field] !== ''
    //         );
    //       }),
    //     })),
    //   }))
    //   .filter((caseData: any) => caseData?.cptDetails?.length > 0);

    const filteredPaymentsData = paymentsData?.paymentDetails?.filter(
      (caseData: any) => caseData?.cptDetails?.length > 0
    );

    // console.log('Filtered', filteredPaymentsData);

    const invalidAmountDenialCodes = new Set(
      filteredPaymentsData
        ?.flatMap((caseData: any) => caseData?.cptDetails)
        .flatMap((cptDetail: any) => cptDetail?.lineDetails)
        .filter(
          (lineDetail: any) =>
            lineDetail?.billingCodeType != null &&
            lineDetail?.billingCodeType !== '' &&
            lineDetail?.amount != null &&
            lineDetail?.amount !== '' &&
            parseInt(lineDetail.billingCodeType, 10) === 13 &&
            parseFloat(lineDetail.amount) !== 0
        )
        .map(
          (lineDetail: any) =>
            fetchSystemBillingCodeListData?.find((code: any) => code.id === lineDetail.billingCode)?.code
        )
        .filter(Boolean)
    );

    if (invalidAmountDenialCodes.size > 0) {
      setBillingCodeAlertMessage(
        `${Array.from(invalidAmountDenialCodes).join(
          ', '
        )} are denial codes. Please enter amount as 0 for these entries.`
      );
      setVisibleBillingCodeAlert(true);
      return;
    }

    const paymentDataObj = {
      patientId: patientData?.id,
      whoPaid: paymentsData?.whoPaid,
      checkNumber: batchDetails?.checkNumber ?? null,
      paymentDate: moment(batchDetails?.checkDate).isValid()
        ? moment(batchDetails?.checkDate).format('YYYY-MM-DD')
        : null,
      description: batchDetails?.description ?? null,
      paymentDetails: filteredPaymentsData,
    };

    const payload = {
      batchId: batchDetails?.id,
      paymentsData: convertBeforeSubmit(paymentDataObj),
    };

    setVisibleBillingCodeAlert(false);
    setBillingCodeAlertMessage('');
    setSubmitting(true);

    dispatch(addBulkLinePaymentsRequest(payload));
  };

  const fetchSystemBillingCodes = () => {
    dispatch(getSystemBillingCodeListRequest());
  };

  const formatBillingCodes = (codes: any[]) => {
    return codes.map((code) => ({
      id: code.id,
      name: `${code.code ? code.code + ' - ' : ''}${code.name}`,
    }));
  };

  const handleRowDataChange = (patientVisitCptId: any, e: any, rowIndex: any) => {
    const { name, value } = e.target;
    handleUpdatePaymentsData(patientVisitCptId, { [name]: value }, rowIndex);
  };

  const handleBillingCodeChange = (patientVisitCptId: any, selectedValue: string, rowIndex: any) => {
    const selectedCode = fetchSystemBillingCodeListData?.find((code: any) => code.id === Number(selectedValue));

    handleUpdatePaymentsData(
      patientVisitCptId,
      {
        billingCodeType: selectedCode ? selectedCode?.type : '',
        billingCode: selectedCode ? selectedCode?.id : '',
      },
      rowIndex
    );
  };

  const handleBankPostingDateChange = (patientVisitCptId: any, date: any) => {
    handleUpdatePaymentsData(patientVisitCptId, { bankPostingDate: moment(date).format('YYYY-MM-DD') });
  };

  const onChangeId = (e: any) => {
    setPatientSearchData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSelectPatient = (data: any) => {
    setPatientSearchData((prev: any) => ({ ...prev, patientName: data?.name, patientId: data?.id }));
    setPreviousPatientId(data?.id);
  };

  const handleAddRow = (event: any, rowIndex: any, caseIndex: any) => {
    event.preventDefault();

    setDataSource((prev: any) => {
      const updatedCases = [...prev];

      const updatedVisitCptList = [...updatedCases[caseIndex].visitCptList];
      const rowPatientVisitCptId = updatedVisitCptList[rowIndex].patientVisitCptId;

      const caseData = findCaseData(rowPatientVisitCptId);

      const newRow = {
        newRow: true,
        patientVisitCptId: rowPatientVisitCptId,
        rowId: `${caseIndex}-${updatedVisitCptList.length}`,
      };

      updatedVisitCptList.splice(rowIndex + 1, 0, newRow);

      updatedVisitCptList.forEach((row: any, index: number) => {
        row.rowId = `${caseIndex}-${index}`;
      });

      updatedCases[caseIndex] = {
        ...updatedCases[caseIndex],
        visitCptList: updatedVisitCptList,
      };

      const tempPaymentsData = { ...paymentsData };
      const caseDetails = tempPaymentsData.paymentDetails.find((caseItem: any) => caseItem.caseId === caseData.caseId);

      if (caseDetails && caseDetails.cptDetails) {
        caseDetails.cptDetails.forEach((cpt: any) => {
          cpt.lineDetails?.forEach((line: any) => {
            const [caseIdx, lineIdx] = line.rowIndex.split('-').map(Number);
            if (caseIdx === caseIndex && lineIdx >= rowIndex + 1) {
              line.rowIndex = `${caseIdx}-${lineIdx + 1}`;
            }
          });
        });
      }

      return updatedCases;
    });
  };

  const handleClickNote = (event: any, rowIndex: any, caseIndex: any) => {
    event.preventDefault();
    const dataSet = dataSource[caseIndex]?.visitCptList[rowIndex];
    const description = findRowDescription(dataSet?.patientVisitCptId);
    setRowDescription(description || '');
    setSelectedPatientVisitCptId(dataSet?.patientVisitCptId);
    setVisibleAddDescriptionModal(true);
  };

  const handleAddDescription = (data: any) => {
    const isUpdate = !!findRowDescription(selectedPatientVisitCptId);

    handleUpdatePaymentsData(selectedPatientVisitCptId, { description: data });
    setAlertObj({
      color: 'success',
      message: isUpdate ? 'Description Updated!' : 'Description Added!',
      error: false,
    });
    setVisibleAlert(true);
    setTimeout(() => {
      setAlertObj(initialAlertState);
      setVisibleAlert(false);
      setSelectedPatientVisitCptId(null);
      setVisibleAddDescriptionModal(false);
    }, 1000);
  };

  const handlePatientClick = () => {
    navigate(`/patients?id=${patientData?.id}`, {
      state: { fromBulkPayments: true },
    });
  };

  const formatWhoPaidOptions = (whoPaid: any[] = []) =>
    whoPaid.map((item) => {
      const formattedName = item.type
        ? `${item?.name} (${item.type.charAt(0).toUpperCase() + item.type.slice(1).toLowerCase()})`
        : item?.name;
      return { id: item?.id, name: formattedName };
    });

  const handleDescriptionModalClose = () => {
    setSelectedPatientVisitCptId(null);
    setVisibleAddDescriptionModal(false);
    setRowDescription('');
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
    setAlertObj(initialAlertState);
  };

  const handleBillingAlertClose = () => {
    setVisibleBillingCodeAlert(false);
    setBillingCodeAlertMessage('');
  };

  // console.log('dataSource', dataSource);
  // console.log('Billing codes', billingCodeList);
  // console.log('paymentData', paymentsData);
  // console.log('rowDescription', rowDescription);
  return (
    <div className="main-content">
      <MainHeader />
      <PageTopic mainTitle="Bulk Payment" enablePrimaryButton={false} breadCrumbArr={breadCrumbArr} />
      {visibleAlert && alertObj?.error && (
        <CommonAlert
          color={alertObj?.color}
          message={alertObj?.message}
          onClose={handleAlertClose}
          alertClassName="w-1/3"
        />
      )}

      {batchDataLoading ? (
        <Spinner />
      ) : (
        <>
          <div className="grid md:grid-cols-5 gap-4 mt-6">
            <InputField label="Batch ID" name="id" disabled value={batchDetails?.id ?? undefined} />
            <InputField
              label="Payment Date"
              name="checkDate"
              disabled
              value={
                batchDetails?.checkDate && moment(batchDetails.checkDate).isValid()
                  ? moment(batchDetails.checkDate).format('MM/DD/YYYY')
                  : undefined
              }
            />
            <InputField
              label="Check Number"
              name="checkNumber"
              disabled
              value={batchDetails?.checkNumber ?? undefined}
            />
            <InputField
              label="Description"
              name="description"
              disabled
              value={batchDetails?.description ?? undefined}
            />
            <InputField
              label="UnApplied"
              name="unAppliedAmount"
              disabled
              value={formatCurrency(batchDetails?.unAppliedAmount ?? 0)}
            />
          </div>
          <div className="grid md:grid-cols-5 gap-4 mt-6 items-end">
            <div className="flex items-end">
              {' '}
              <InputField
                label=""
                name="patientName"
                placeholder="Patient Name"
                disabled
                value={patientSearchData?.patientName ?? undefined}
              />
              <CustomSearchButton onClick={() => setVisiblePatientSearchModal(true)} />
            </div>

            <InputField
              label=""
              name="patientId"
              placeholder="Patient ID"
              value={patientSearchData?.patientId ?? ''}
              onChange={onChangeId}
            />
            <PrimaryButton
              type="button"
              label="Search"
              style={{ height: '40px', maxWidth: '100px' }}
              onClick={onSearch}
            />
          </div>
        </>
      )}

      {fetchBulkPaymentListLoading ? (
        <Spinner />
      ) : (
        isLoaded &&
        !isVisitsEmpty && (
          <div className="mt-14">
            {visibleBillingCodeAlert && (
              <CommonAlert
                color="failure"
                message={billingCodeAlertMessage}
                onClose={handleBillingAlertClose}
                alertClassName="w-5/12"
              />
            )}
            <div className="text-black mt-4">
              Patient Name:{' '}
              <span className="text-customRed no-underline cursor-pointer" onClick={handlePatientClick}>
                {patientData?.name ?? ''}
              </span>
            </div>
            <div className="grid md:grid-cols-4 gap-4 mt-2 mb-8 items-end">
              <SelectInput
                defaultPlaceholder="Select Who Paid"
                options={whoPaidOptions}
                name="whoPaid"
                value={paymentsData?.whoPaid ?? undefined}
                disabled={whoPaidReloading}
                onChange={handleWhoPaidChange}
              />
              <div className="col-span-1 flex justify-start space-x-2">
                <PrimaryButton
                  type="button"
                  label={whoPaidReloading ? 'Reloading...' : 'Reload'}
                  style={{ height: '40px', maxWidth: '100px' }}
                  onClick={handleReload}
                />
                <CommonButton
                  type="button"
                  label="Submit"
                  buttonType="primary"
                  style={{ height: '40px', maxWidth: '100px' }}
                  onClick={onSubmit}
                  loading={submitting}
                  // disabled={!hasCompleteCptDetail}
                />
              </div>
              <div className="col-span-2"></div>
            </div>

            <div className="overflow-x-auto">
              <BulkPaymentsCustomTable
                columns={columns(
                  billingCodeList,
                  handleBillingCodeChange,
                  handleRowDataChange,
                  handleBankPostingDateChange,
                  findRowBankPostingDate,
                  findRowAmountBillingCode,
                  findRowCheckNumberAllowedAmount,
                  calculateBalanceDeductableAmount,
                  batchType
                )}
                cases={dataSource}
                insurances={insurancesList}
                onHandleNote={handleClickNote}
                onHandleAdd={handleAddRow}
              />
            </div>
          </div>
        )
      )}

      {visiblePatientSearchModal && (
        <PatientSearchModal
          patientSearchModalOpen={visiblePatientSearchModal}
          setOpenPatientSearchModal={setVisiblePatientSearchModal}
          selectPatient={handleSelectPatient}
        />
      )}

      {visibleAddDescriptionModal && (
        <AddDescriptionModal
          addDescriptionModalOpen={visibleAddDescriptionModal}
          onCloseModal={handleDescriptionModalClose}
          value={rowDescription}
          onSubmit={handleAddDescription}
          visibleAlert={visibleAlert}
          alertObj={alertObj}
          handleAlertClose={handleAlertClose}
        />
      )}
    </div>
  );
};
