import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  UpdateBankReconciledPaymentInterface,
  UpdateBankReconciledPaymentInitialInterface,
} from '../../../interfaces/reportsInterfaces';
import { updateBankReconciledPaymentAPIRequest } from '../../../api/reports/payment';

const initialState: UpdateBankReconciledPaymentInitialInterface = {
  editBankReconciledPaymentLoading: false,
  editBankReconciledPaymentData: {},
  editBankReconciledPaymentStatus: 'IDLE',
  editBankReconciledPaymentError: null,
};

export const updateBankReconciledPaymentRequest: any = createAsyncThunk(
  'reports/bank-reconciled/payment/update',
  async (payment: UpdateBankReconciledPaymentInterface, thunkAPI: any) => {
    try {
      const response: any = await updateBankReconciledPaymentAPIRequest(payment);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const updateBankReconciledPaymentSlice = createSlice({
  name: 'updateBankReconciledPayment',
  initialState,
  reducers: {
    clearUpdateBankReconciledPaymentResponse: (state) => {
      state.editBankReconciledPaymentLoading = false;
      state.editBankReconciledPaymentStatus = 'IDLE';
      state.editBankReconciledPaymentData = {};
      state.editBankReconciledPaymentError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateBankReconciledPaymentRequest.pending, (state, action) => {
        state.editBankReconciledPaymentLoading = true;
        state.editBankReconciledPaymentStatus = 'PENDING';
      })
      .addCase(updateBankReconciledPaymentRequest.fulfilled, (state, action) => {
        state.editBankReconciledPaymentData = action.payload;
        state.editBankReconciledPaymentLoading = false;
        state.editBankReconciledPaymentStatus = 'SUCCESS';
      })
      .addCase(updateBankReconciledPaymentRequest.rejected, (state, action) => {
        state.editBankReconciledPaymentLoading = false;
        state.editBankReconciledPaymentStatus = 'FAILED';
        state.editBankReconciledPaymentError = action.payload;
      });
  },
});

export const { clearUpdateBankReconciledPaymentResponse } = updateBankReconciledPaymentSlice.actions;

export const updateBankReconciledPaymentSelector = (state: RootState) => state.UpdateBankReconciledPayment;

export default updateBankReconciledPaymentSlice.reducer;
