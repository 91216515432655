import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { AddClaimNotesInterface, AddClaimNotesInitialInterface } from '../../../interfaces/billingInterface';
import { addClaimNotesAPIRequest } from '../../../api/billing/claims';

const initialState: AddClaimNotesInitialInterface = {
  addClaimNotesLoading: false,
  addClaimNotesData: {},
  addClaimNotesStatus: 'IDLE',
  addClaimNotesError: null,
};

export const addClaimNotesRequest: any = createAsyncThunk(
  'claim/notes/add',
  async (claimNoteData: AddClaimNotesInterface, thunkAPI: any) => {
    try {
      const response: any = await addClaimNotesAPIRequest(claimNoteData);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const addClaimNotesSlice = createSlice({
  name: 'addClaimNotes',
  initialState,
  reducers: {
    clearAddClaimNotesResponse: (state) => {
      state.addClaimNotesLoading = false;
      state.addClaimNotesStatus = 'IDLE';
      state.addClaimNotesData = {};
      state.addClaimNotesError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addClaimNotesRequest.pending, (state, action) => {
        state.addClaimNotesLoading = true;
        state.addClaimNotesStatus = 'PENDING';
      })
      .addCase(addClaimNotesRequest.fulfilled, (state, action) => {
        state.addClaimNotesLoading = false;
        state.addClaimNotesStatus = 'SUCCESS';
        state.addClaimNotesData = action.payload;
      })
      .addCase(addClaimNotesRequest.rejected, (state, action) => {
        state.addClaimNotesLoading = false;
        state.addClaimNotesStatus = 'FAILED';
        state.addClaimNotesError = action.payload;
      });
  },
});

export const { clearAddClaimNotesResponse } = addClaimNotesSlice.actions;

export const addClaimNotesSelector = (state: RootState) => state.AddClaimNotes;

export default addClaimNotesSlice.reducer;
