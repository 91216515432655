import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks/hooks';
import moment from 'moment';
import { debounce } from 'lodash';

import { MainHeader } from '../../../components/mainHeader/MainHeader';
import { PageTopic } from '../../../components/pageTopic/PageTopic';
import { Pagination } from '../../../components/pagination/Pagination';
import { TableContent } from '../../../components/table/TableContent';
import { DatePickerField } from '../../../components/atoms/DatePickerField';
import { SelectInput } from '../../../components/atoms/SelectInput';
import { CommonAlert } from '../../../components/atoms/Alert';
import { Spinner } from '../../../components/atoms/Spinner';
import { EmptyContent } from '../../../components/emptyContent/EmptyContent';
import { BankReconciliationReportHeader } from '../../../components/reports/BankReconciliationReportHeader';
import { AddMiscPaymentModal } from '../../../components/reports/bankReconciled/AddMiscPaymentModal';
import { ViewMiscPaymentsListModal } from '../../../components/reports/bankReconciled/ViewMiscPaymentsListModal';
import { ConfirmModal } from '../../../components/list/patients/visit/ConfirmModal';

import { IoIosInformationCircleOutline } from 'react-icons/io';

import {
  getBankReconciliationReconciledRequest,
  getBankReconcilationReconciledSelector,
  clearFetchBankReconcilationReconciledResponse,
} from '../../../redux/slices/reports/getBankReconcilationReconciledSlice';
import {
  addBankToBeReconciledMiscPaymentRequest,
  addBankToBeReconciledMiscPaymentSelector,
  clearAddBankToBeReconciledMiscPaymentResponse,
} from '../../../redux/slices/reports/addBankToBeReconciledMiscPaymentSlice';
import {
  updateBankReconciledPaymentRequest,
  updateBankReconciledPaymentSelector,
  clearUpdateBankReconciledPaymentResponse,
} from '../../../redux/slices/reports/editBankReconciledPaymentSlice';
import {
  getToBeReconciledMiscPaymentsRequest,
  getToBeReconciledMiscPaymentsSelector,
  clearFetchToBeReconciledMiscPaymentsResponse,
} from '../../../redux/slices/reports/getBankToBeReconciledMiscPaymentListSlice';
import {
  deleteToBeReconciledMiscPaymentRequest,
  deleteToBeReconciledMiscPaymentSelector,
  clearDeleteToBeReconciledMiscPaymentResponse,
} from '../../../redux/slices/reports/deleteBankToBeReconciledMiscPaymentSlice';

import { bankTransactionType } from '../../../constants/billingConstants';

import { formatDatePickerDate, formatCurrency } from '../../../utils/commonFunctions';
import { addBankReconciliationMiscPaymentValidation } from '../../../utils/reportsValidation';

const PAGE_SIZE = 10;

const columns = (rowData: any, handleBankPostingDate: any, handleBankTransactionTypeChange: any) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Batch Type',
    dataIndex: 'batchType',
    key: 'batchType',
  },
  {
    title: 'Check Number',
    dataIndex: 'checkNumber',
    key: 'checkNumber',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Payment Amount',
    dataIndex: 'paymentAmount',
    key: 'paymentAmount',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Posted Amount',
    dataIndex: 'postedAmount',
    key: 'postedAmount',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Miscellaneous Amount',
    dataIndex: 'miscellaneousAmount',
    key: 'miscellaneousAmount',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    key: 'balance',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Payment Received',
    dataIndex: 'paymentReceived',
    key: 'paymentReceived',
    render: (text: string) => (text ? (moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : '') : ''),
  },
  {
    title: 'Payer Posted date',
    dataIndex: 'postedDate',
    key: 'postedDate',
    render: (text: string) => (text ? (moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : '') : ''),
  },
  {
    title: 'Bank Posting Date',
    dataIndex: 'bankPostingDate',
    key: 'bankPostingDate',
    render: (text: any, record: any) => (
      <DatePickerField
        name="bankPostingDate"
        selectedDate={formatDatePickerDate(rowData?.[`${record?.id}_${record?.batchPaymentType}`]?.bankPostingDate)}
        onChange={(date: Date | null) => {
          if (date) {
            handleBankPostingDate(date, `${record?.id}_${record?.batchPaymentType}`);
          }
        }}
      />
    ),
    width: '160px',
  },
  {
    title: 'Bank Transaction Type',
    dataIndex: 'transactionType',
    key: 'transactionType',
    render: (text: any, record: any) => (
      <SelectInput
        options={bankTransactionType}
        enableDefaultPlaceholder={true}
        name="transactionType"
        value={rowData?.[`${record?.id}_${record?.batchPaymentType}`]?.transactionType ?? ''}
        onChange={(e: any) =>
          handleBankTransactionTypeChange(e.target.value, `${record?.id}_${record?.batchPaymentType}`)
        }
      />
    ),
  },
  {
    title: 'Reconciled',
    dataIndex: 'reconciled',
    key: 'reconciled',
    render: () => (
      <span className="flex justify-center border border-gray-300 font-semibold rounded px-2 py-1">Yes</span>
    ),
  },
];

const dummyDataSource = [
  {
    id: '1',
    batchType: 'Type A',
    checkNumber: '12345',
    description: 'Description A',
    paymentAmount: '$100.00',
    postedAmount: '$90.00',
    miscellaneousAmount: '$10.00',
    balance: '$10.00',
    paymentReceived: '2024-05-01',
    postedDate: '2024-05-01',
    bankPostingDate: '2024-05-02',
    transactionType: 'LOCKBOX',
    reconciled: 'Yes',
  },
  {
    id: '2',
    batchType: 'Type A',
    checkNumber: '12345',
    description: 'Description A',
    paymentAmount: '$100.00',
    postedAmount: '$90.00',
    miscellaneousAmount: '$10.00',
    balance: '$10.00',
    paymentReceived: '2024-05-01',
    postedDate: '2024-05-01',
    bankPostingDate: '2024-05-02',
    transactionType: 'LOCKBOX',
    reconciled: 'Yes',
  },
];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  {
    id: 'bank-reconciliation-to-be-reconciled-report',
    label: 'Bank Reconciliation - Reconciled',
    status: 'active',
    link: 'bank-reconciliation-to-be-reconciled-report',
  },
];

const initialAlertState = {
  color: '',
  message: '',
  error: false,
};

export const BankReconciledReportPage = () => {
  const dispatch = useAppDispatch();

  const {
    fetchBankReconcilationReconciledStatus,
    fetchBankReconcilationReconciledData,
    fetchBankReconcilationReconciledLoading,
  } = useAppSelector(getBankReconcilationReconciledSelector);
  const {
    addBankToBeReconciledMiscPaymentStatus,
    addBankToBeReconciledMiscPaymentData,
    addBankToBeReconciledMiscPaymentError,
  } = useAppSelector(addBankToBeReconciledMiscPaymentSelector);
  const { editBankReconciledPaymentStatus, editBankReconciledPaymentData, editBankReconciledPaymentError } =
    useAppSelector(updateBankReconciledPaymentSelector);
  const {
    fetchToBeReconciledMiscPaymentListStatus,
    fetchToBeReconciledMiscPaymentListData,
    fetchToBeReconciledMiscPaymentListError,
  } = useAppSelector(getToBeReconciledMiscPaymentsSelector);
  const { deleteToBeReconciledMiscPaymentStatus, deleteToBeReconciledMiscPaymentError } = useAppSelector(
    deleteToBeReconciledMiscPaymentSelector
  );

  const currentYear = moment().year();
  const currentMonth = moment().format('MMM');

  const [dataSource, setDataSource] = useState<any>([]);
  const [rowData, setRowData] = useState<any>({});
  const [filterObject, setFilterObject] = useState<any>({
    year: currentYear.toString(),
    month: currentMonth.toString(),
    batchType: null,
  });
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [selectedBatchForView, setSelectedBatchForView] = useState<any>({});
  const [selectedMiscPaymentId, setSelectedMiscPaymentId] = useState<number | null>(null);
  const [addPaymentFormData, setAddPaymentFormData] = useState<any>({});
  const [paymentListData, setPaymentListData] = useState<any>({});
  const [visibleAddPaymentModel, setVisibleAddPaymentModel] = useState<boolean>(false);
  const [visiblePaymentList, setVisiblePaymentList] = useState<boolean>(false);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>(initialAlertState);
  const [fetchLoading, setFetchLoading] = useState<boolean>(true);

  useEffect(() => {
    setVisibleAlert(false);
    setAlertObj(initialAlertState);
  }, []);

  useEffect(() => {
    // if (filterObject) {
    //   fetchBankReconciliationReconciledReport(0, searchTerm);
    // }

    const debouncedFetch = debounce((searchTerm) => {
      if (filterObject) {
        fetchBankReconciliationReconciledReport(0, searchTerm);
      }
    }, 550);

    setFetchLoading(true);
    debouncedFetch(searchTerm);

    return () => {
      debouncedFetch.cancel();
    };
  }, [filterObject, searchTerm]);

  useEffect(() => {
    if (fetchBankReconcilationReconciledStatus === 'SUCCESS') {
      const updatedRowData = fetchBankReconcilationReconciledData?.items?.reduce((acc: any, item: any) => {
        const key = `${item.id}_${item.batchPaymentType}`;
        acc[key] = {
          ...item,
          transactionType: item.transactionType || 'EFT',
        };
        return acc;
      }, {});

      setRowData(updatedRowData);
      setDataSource(fetchBankReconcilationReconciledData?.items);
      setCurrentPage(fetchBankReconcilationReconciledData?.currentPage);
      setTotalPages(fetchBankReconcilationReconciledData?.totalPages);
      setFetchLoading(false);
      dispatch(clearFetchBankReconcilationReconciledResponse());
    } else if (fetchBankReconcilationReconciledStatus === 'FAILED') {
      setFetchLoading(false);
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong!',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj(initialAlertState);
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearFetchBankReconcilationReconciledResponse());
    }
  }, [fetchBankReconcilationReconciledStatus]);

  useEffect(() => {
    if (addBankToBeReconciledMiscPaymentStatus === 'SUCCESS') {
      if (addBankToBeReconciledMiscPaymentData?.status === true) {
        setFetchLoading(true);
        fetchBankReconciliationReconciledReport();
        setVisibleAddPaymentModel(false);
        setAddPaymentFormData({});
        setSelectedId(null);
        setAlertObj({
          color: 'success',
          message: 'Successfully created !',
          error: false,
        });
        setVisibleAlert(true);
      } else if (addBankToBeReconciledMiscPaymentData?.status === false) {
        setAlertObj({
          color: 'failure',
          message: addBankToBeReconciledMiscPaymentData?.message ?? 'Something went wrong!',
          error: true,
        });
        setVisibleAlert(true);
      }

      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj(initialAlertState);
      }, 3000);

      dispatch(clearAddBankToBeReconciledMiscPaymentResponse());
    } else if (addBankToBeReconciledMiscPaymentStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: addBankToBeReconciledMiscPaymentError?.message ?? 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);

      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj(initialAlertState);
      }, 3000);

      dispatch(clearAddBankToBeReconciledMiscPaymentResponse());
    }
  }, [addBankToBeReconciledMiscPaymentStatus]);

  useEffect(() => {
    if (editBankReconciledPaymentStatus === 'SUCCESS') {
      if (editBankReconciledPaymentData?.status === true) {
        setFetchLoading(true);
        fetchBankReconciliationReconciledReport();
        setAlertObj({
          color: 'success',
          message: 'Successfully updated !',
          error: false,
        });
        setVisibleAlert(true);
      } else if (editBankReconciledPaymentData?.status === false) {
        setAlertObj({
          color: 'failure',
          message: editBankReconciledPaymentData?.message ?? 'Something went wrong!',
          error: false,
        });
        setVisibleAlert(true);
      }

      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj(initialAlertState);
      }, 3000);

      dispatch(clearUpdateBankReconciledPaymentResponse());
    } else if (editBankReconciledPaymentStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: editBankReconciledPaymentError?.message ?? 'Something went wrong !',
        error: false,
      });
      setVisibleAlert(true);

      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj(initialAlertState);
      }, 3000);

      dispatch(clearUpdateBankReconciledPaymentResponse());
    }
  }, [editBankReconciledPaymentStatus]);

  useEffect(() => {
    if (fetchToBeReconciledMiscPaymentListStatus === 'SUCCESS') {
      setPaymentListData(fetchToBeReconciledMiscPaymentListData);
      setVisiblePaymentList(true);
      dispatch(clearFetchToBeReconciledMiscPaymentsResponse());
    } else if (fetchToBeReconciledMiscPaymentListStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: fetchToBeReconciledMiscPaymentListError?.message ?? 'Something went wrong !',
        error: false,
      });
      setVisibleAlert(true);

      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj(initialAlertState);
      }, 3000);

      dispatch(clearFetchToBeReconciledMiscPaymentsResponse());
    }
  }, [fetchToBeReconciledMiscPaymentListStatus]);

  useEffect(() => {
    if (deleteToBeReconciledMiscPaymentStatus === 'SUCCESS') {
      setOpenConfirmModal(false);
      setSelectedMiscPaymentId(null);
      fetchMiscPaymentList(selectedBatchForView?.id, selectedBatchForView?.batchPaymentType);
      setAlertObj({
        color: 'success',
        message: 'Successfully deleted !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj(initialAlertState);
      }, 3000);
      dispatch(clearDeleteToBeReconciledMiscPaymentResponse());
    } else if (deleteToBeReconciledMiscPaymentStatus === 'FAILED') {
      setOpenConfirmModal(false);
      setAlertObj({
        color: 'failure',
        message: deleteToBeReconciledMiscPaymentError?.message ?? 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj(initialAlertState);
      }, 3000);

      dispatch(clearDeleteToBeReconciledMiscPaymentResponse());
    }
  }, [deleteToBeReconciledMiscPaymentStatus]);

  const onPageChange = (page: any) => {
    setCurrentPage(page - 1);
    setFetchLoading(true);
    fetchBankReconciliationReconciledReport(page - 1);
  };

  const fetchBankReconciliationReconciledReport = async (pageNumber = currentPage, searchValue = searchTerm) => {
    const updatedFilterObject = { page: pageNumber, size: PAGE_SIZE, ...filterObject, search: searchValue };
    dispatch(getBankReconciliationReconciledRequest({ filters: updatedFilterObject }));
  };

  const fetchMiscPaymentList = (id: number, batchPaymentType: boolean) => {
    dispatch(
      getToBeReconciledMiscPaymentsRequest({
        id,
        batchPaymentType,
      })
    );
  };

  const deleteMiscPayment = () => {
    dispatch(deleteToBeReconciledMiscPaymentRequest({ id: selectedMiscPaymentId }));
  };

  const createErrorAlert = (validationDetails: any) => {
    const errorMessages = Object.values(validationDetails.newErrors);
    return {
      message: (
        <div>
          {errorMessages.map((msg: any, index) => (
            <div key={index} className="flex items-center">
              <IoIosInformationCircleOutline />
              <span className="ml-2">{msg}</span>
            </div>
          ))}
        </div>
      ),
      color: 'failure',
      error: true,
    };
  };

  const handleBankTransactionTypeChange = (value: any, id: any) => {
    setRowData((prev: any) => ({
      ...prev,
      [id]: {
        ...prev[id],
        transactionType: value,
      },
    }));
  };

  const handleBankPostingDate = (date: any, id: any) => {
    setRowData((prev: any) => ({
      ...prev,
      [id]: {
        ...prev[id],
        bankPostingDate: moment(date).format('YYYY-MM-DD'),
      },
    }));
  };

  const onAdd = (event: any, rowIndex: any) => {
    event.preventDefault();
    const dataSet = dataSource[rowIndex];
    setSelectedId(dataSet?.id);
    setAddPaymentFormData({
      batchType: dataSet?.batchType,
      checkNumber: dataSet?.checkNumber,
      batchPaymentType: dataSet?.batchPaymentType,
    });
    setVisibleAddPaymentModel(true);
  };
  const onView = (event: any, rowIndex: any) => {
    event.preventDefault();
    const dataSet = dataSource[rowIndex];
    setSelectedId(dataSet?.id);
    setSelectedBatchForView(dataSet);
    fetchMiscPaymentList(dataSet?.id, dataSet?.batchPaymentType);
  };

  const handleUpdateClicked = (event: any, rowIndex: any) => {
    event.preventDefault();

    const dataSet = dataSource[rowIndex];

    if (!dataSet || !dataSet.id || dataSet.batchPaymentType == null) {
      return;
    }

    const key = `${dataSet.id}_${dataSet.batchPaymentType}`;

    const paymentRecordData = rowData[key];

    if (!paymentRecordData) {
      return;
    }

    const payload = {
      id: dataSet.id,
      paymentData: {
        bankPostingDate: paymentRecordData.bankPostingDate,
        btType: paymentRecordData.transactionType,
        batchPaymentType: dataSet.batchPaymentType,
      },
    };

    dispatch(updateBankReconciledPaymentRequest(payload));
  };

  const convertBeforeSubmit = (paymentData: any) => {
    const obj = {
      ...paymentData,
      batchType: paymentData?.batchType || null,
      checkNumber: paymentData?.checkNumber ?? null,
      amount: Number(parseFloat(paymentData?.amount).toFixed(2)),
    };

    return obj;
  };

  const handlePaymentFormChange = (e: any) => {
    const { name, value } = e.target;

    setAddPaymentFormData((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddPayment = () => {
    const validationDetails = addBankReconciliationMiscPaymentValidation(addPaymentFormData);

    if (Object.keys(validationDetails?.newErrors)?.length > 0) {
      setAlertObj(createErrorAlert(validationDetails));
      setVisibleAlert(true);
    } else {
      const payload = {
        id: selectedId,
        paymentData: convertBeforeSubmit(addPaymentFormData),
      };

      dispatch(addBankToBeReconciledMiscPaymentRequest(payload));
    }
  };

  const handleDeleteMiscPayment = (event: any, rowIndex: any) => {
    event.preventDefault();
    const dataSet = paymentListData?.items[rowIndex];
    setSelectedMiscPaymentId(dataSet?.id);
    setOpenConfirmModal(true);
  };

  const handleSearch = (data: any) => {
    const updatedData = { ...data };

    updatedData.month = updatedData.month || null;

    updatedData.batchType = updatedData.batchType || null;

    setCurrentPage(0);
    setFilterObject(updatedData);
  };

  const handleOnCloseAddPaymentModel = () => {
    setAddPaymentFormData({});
    setSelectedId(null);
    setVisibleAlert(false);
    setAlertObj(initialAlertState);
    setVisibleAddPaymentModel(false);
  };

  const handleOnCloseViewMiscPaymentsModel = () => {
    setFetchLoading(true);
    fetchBankReconciliationReconciledReport();
    setSelectedId(null);
    setSelectedBatchForView({});
    setPaymentListData({});
    setVisiblePaymentList(false);
  };

  const onCloseConfirm = () => {
    setSelectedMiscPaymentId(null);
    setOpenConfirmModal(false);
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
    setAlertObj(initialAlertState);
  };

  return (
    <div className="main-content">
      <MainHeader />
      <PageTopic
        mainTitle="Bank Reconciliation - Reconciled"
        enablePrimaryButton={false}
        breadCrumbArr={breadCrumbArr}
      />

      <BankReconciliationReportHeader onSubmit={handleSearch} />

      {visibleAlert && !alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}

      <div className="flex justify-end mb-4">
        <div className="flex items-center">
          <label htmlFor="search" className="mr-2 text-gray-600">
            Search:
          </label>
          <input
            type="text"
            className="border border-gray-300 rounded px-2 py-1 focus:outline-0 focus:ring-primaryDefault focus:border-primaryDefault"
            name="search"
            title="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      {fetchLoading ? (
        <Spinner />
      ) : (
        <>
          {dataSource?.length > 0 ? (
            <>
              <div className="overflow-x-auto">
                <TableContent
                  columns={columns(rowData, handleBankPostingDate, handleBankTransactionTypeChange)}
                  dataSource={dataSource}
                  enableActions={true}
                  enablePrimaryButton
                  enableAdd
                  enableView
                  primaryButtonLabel="Update"
                  onHandleAdd={onAdd}
                  onView={onView}
                  onClickPrimaryButton={handleUpdateClicked}
                  isReport
                />
              </div>
              {totalPages > 1 && (
                <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
              )}
            </>
          ) : (
            <EmptyContent mode="VIEW" enableCreateButton={false} />
          )}
        </>
      )}

      {visibleAddPaymentModel && (
        <AddMiscPaymentModal
          visibleAddMiscPaymentModal={visibleAddPaymentModel}
          handleOnClose={handleOnCloseAddPaymentModel}
          addMiscPaymentFormData={addPaymentFormData}
          onSubmit={handleAddPayment}
          onChange={handlePaymentFormChange}
          visibleAlert={visibleAlert}
          alertObj={alertObj}
          handleAlertClose={handleAlertClose}
        />
      )}

      {visiblePaymentList && (
        <ViewMiscPaymentsListModal
          visibleViewMiscPaymentListModal={visiblePaymentList}
          handleOnClose={handleOnCloseViewMiscPaymentsModel}
          paymentListData={paymentListData}
          deletePayment={handleDeleteMiscPayment}
          visibleAlert={visibleAlert}
          alertObj={alertObj}
          handleAlertClose={handleAlertClose}
        />
      )}

      {openConfirmModal && (
        <ConfirmModal
          openConfirm={openConfirmModal}
          setOpenConfirm={onCloseConfirm}
          title="Delete Payment"
          content="Are you sure you want to delete this payment?"
          submitButtonTitle="Delete"
          cancelButtonTitle="Cancel"
          handleSubmit={deleteMiscPayment}
          visibleAlert={visibleAlert}
          alertObj={alertObj}
          alertClassName="w-1/2"
          handleAlertClose={handleAlertClose}
        />
      )}
    </div>
  );
};
