import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  FetchBankReconciliationReportInterface,
  FetchBankReconciliationReportInitialInterface,
} from '../../../interfaces/reportsInterfaces';
import { getBankReconciliationReportAPIRequest } from '../../../api/reports/payment';

const initialState: FetchBankReconciliationReportInitialInterface = {
  fetchBankReconciliationReportLoading: false,
  fetchBankReconciliationReportData: {
    items: [],
    totals: {},
  },
  fetchBankReconciliationReportError: null,
  fetchBankReconciliationReportStatus: 'IDLE',
};

export const getBankReconciliationReportRequest: any = createAsyncThunk(
  'reports/bank-reconcilation-report/get',
  async (reportData: FetchBankReconciliationReportInterface, thunkAPI: any) => {
    try {
      const response: any = await getBankReconciliationReportAPIRequest(reportData);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getBankReconciliationReportSlice = createSlice({
  name: 'fetchBankReconciliationReport',
  initialState,
  reducers: {
    clearFetchBankReconciliationReportResponse: (state) => {
      state.fetchBankReconciliationReportLoading = false;
      state.fetchBankReconciliationReportData = { items: [], totals: {} };
      state.fetchBankReconciliationReportStatus = 'IDLE';
      state.fetchBankReconciliationReportError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBankReconciliationReportRequest.pending, (state, action) => {
        state.fetchBankReconciliationReportLoading = true;
        state.fetchBankReconciliationReportStatus = 'PENDING';
      })
      .addCase(getBankReconciliationReportRequest.fulfilled, (state, action) => {
        state.fetchBankReconciliationReportLoading = false;
        state.fetchBankReconciliationReportStatus = 'SUCCESS';
        state.fetchBankReconciliationReportData = action.payload;
      })
      .addCase(getBankReconciliationReportRequest.rejected, (state, action) => {
        state.fetchBankReconciliationReportLoading = false;
        state.fetchBankReconciliationReportStatus = 'FAILED';
        state.fetchBankReconciliationReportError = action.payload;
      });
  },
});

export const { clearFetchBankReconciliationReportResponse } = getBankReconciliationReportSlice.actions;

export const getBankReconciliationReportSelector = (state: RootState) => state.GetBankReconciliationReport;

export default getBankReconciliationReportSlice.reducer;
