import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { FetchFinancialDRArInterface, FetchFinancialDRArInitialInterface } from '../../../interfaces/reportsInterfaces';
import { getFinancialDRArAPIRequest } from '../../../api/reports/accountRecievable';

const initialState: FetchFinancialDRArInitialInterface = {
  fetchFinancialDRArLoading: false,
  fetchFinancialDRArData: {
    totals: {},
    items: [],
  },
  fetchFinancialDRArError: null,
  fetchFinancialDRArStatus: 'IDLE',
};

export const getFinancialDRArRequest: any = createAsyncThunk(
  'reports/financial-dashboard-ar/get',
  async (reportData: FetchFinancialDRArInterface, thunkAPI: any) => {
    try {
      const response: any = await getFinancialDRArAPIRequest(reportData);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getFinancialDRArSlice = createSlice({
  name: 'fetchFinancialDRAr',
  initialState,
  reducers: {
    clearFetchFinancialDRArResponse: (state) => {
      state.fetchFinancialDRArLoading = false;
      state.fetchFinancialDRArData = { totals: {}, items: [] };
      state.fetchFinancialDRArStatus = 'IDLE';
      state.fetchFinancialDRArError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFinancialDRArRequest.pending, (state, action) => {
        state.fetchFinancialDRArLoading = true;
        state.fetchFinancialDRArStatus = 'PENDING';
      })
      .addCase(getFinancialDRArRequest.fulfilled, (state, action) => {
        state.fetchFinancialDRArLoading = false;
        state.fetchFinancialDRArStatus = 'SUCCESS';
        state.fetchFinancialDRArData = action.payload;
      })
      .addCase(getFinancialDRArRequest.rejected, (state, action) => {
        state.fetchFinancialDRArLoading = false;
        state.fetchFinancialDRArStatus = 'FAILED';
        state.fetchFinancialDRArError = action.payload;
      });
  },
});

export const { clearFetchFinancialDRArResponse } = getFinancialDRArSlice.actions;

export const getFinancialDRArSelector = (state: RootState) => state.GetFinancialDRAr;

export default getFinancialDRArSlice.reducer;
