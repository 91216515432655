import { InputFieldPropsInterface } from '../../interfaces/atomInterfaces';
import { Tooltip } from 'flowbite-react';
import { BsInfoCircle } from 'react-icons/bs';

export const InputField: React.FC<InputFieldPropsInterface> = ({
  enableLabel = true,
  label = '',
  placeholder = '',
  type = 'text',
  required = false,
  onChange = () => {},
  name = '',
  value,
  defaultValue,
  disabled = false,
  error = false,
  tooltip = '',
  errorMessage = '',
  className = 'bg-gray1 border border-gray2 text-gray9 text-sm rounded-lg focus:ring-1 focus:ring-primaryDefault focus:border-primaryDefault outline-0 block w-full p-2.5 dark:bg-gray9 dark:border-gray8 dark:placeholder-gray4 dark:text-white dark:focus:ring-primaryDefault dark:focus:border-primaryDefault',
}) => {
  return (
    <>
      <div>
        {enableLabel && (
          <label
            htmlFor="input_field"
            className="block mb-2 text-sm font-normal text-gray-900 dark:text-white flex items-center"
          >
            {label} {required && <span className="text-red-500">*</span>}{' '}
            {tooltip && (
              <span className="ml-1">
                <Tooltip content={tooltip} placement="top">
                  <BsInfoCircle className="text-gray-500 cursor-pointer" />
                </Tooltip>
              </span>
            )}
          </label>
        )}
        <input
          type={type}
          id="input_filed"
          className={`${className} ${disabled ? 'opacity-80 cursor-not-allowed' : ''}`}
          placeholder={placeholder}
          required={required}
          onChange={onChange}
          name={name}
          value={value}
          defaultValue={defaultValue}
          disabled={disabled}
        />
        {/* {error && errorMessage ? (
          <label htmlFor="input_field" className="block mt-2 mb-2 text-sm font-normal text-red-900 dark:text-white">
            {errorMessage}
          </label>
        ) : (
          <></>
        )} */}
      </div>
    </>
  );
};
