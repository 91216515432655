import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  FetchReimbursementReportInterface,
  FetchReimbursementReportInitialInterface,
} from '../../../interfaces/reportsInterfaces';
import { getReimbursementReportAPIRequest } from '../../../api/reports/reimbursementReport';

const initialState: FetchReimbursementReportInitialInterface = {
  fetchReimbursementReportLoading: false,
  fetchReimbursementReportData: {
    currentPage: 0,
    items: [],
    totalItems: 0,
    totalPages: 0,
  },
  fetchReimbursementReportError: null,
  fetchReimbursementReportStatus: 'IDLE',
};

export const getReimbursementReportRequest: any = createAsyncThunk(
  'reports/reimbursement/get',
  async (reportData: FetchReimbursementReportInterface, thunkAPI: any) => {
    try {
      const response: any = await getReimbursementReportAPIRequest(reportData);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getReimbursementReportSlice = createSlice({
  name: 'fetchReimbursementReport',
  initialState,
  reducers: {
    clearFetchReimbursementReportResponse: (state) => {
      state.fetchReimbursementReportLoading = false;
      state.fetchReimbursementReportData = { currentPage: 0, items: [], totalItems: 0, totalPages: 0 };
      state.fetchReimbursementReportStatus = 'IDLE';
      state.fetchReimbursementReportError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReimbursementReportRequest.pending, (state, action) => {
        state.fetchReimbursementReportLoading = true;
        state.fetchReimbursementReportStatus = 'PENDING';
      })
      .addCase(getReimbursementReportRequest.fulfilled, (state, action) => {
        state.fetchReimbursementReportLoading = false;
        state.fetchReimbursementReportStatus = 'SUCCESS';
        state.fetchReimbursementReportData = action.payload;
      })
      .addCase(getReimbursementReportRequest.rejected, (state, action) => {
        state.fetchReimbursementReportLoading = false;
        state.fetchReimbursementReportStatus = 'FAILED';
        state.fetchReimbursementReportError = action.payload;
      });
  },
});

export const { clearFetchReimbursementReportResponse } = getReimbursementReportSlice.actions;

export const getReimbursementReportSelector = (state: RootState) => state.GetReimbursementReport;

export default getReimbursementReportSlice.reducer;
