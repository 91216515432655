import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  FetchSecondaryAgingReportInterface,
  FetchSecondaryAgingReportInitialInterface,
} from '../../../interfaces/reportsInterfaces';
import { getSecondaryAgingReportAPIRequest } from '../../../api/reports/accountRecievable';

const initialState: FetchSecondaryAgingReportInitialInterface = {
  fetchSecondaryAgingReportLoading: false,
  fetchSecondaryAgingReportData: {
    currentPage: 0,
    items: [],
    totalItems: 0,
    totalPages: 0,
  },
  fetchSecondaryAgingReportError: null,
  fetchSecondaryAgingReportStatus: 'IDLE',
};

export const getSecondaryAgingReportRequest: any = createAsyncThunk(
  'reports/secondary-aging/get',
  async (reportData: FetchSecondaryAgingReportInterface, thunkAPI: any) => {
    try {
      const response: any = await getSecondaryAgingReportAPIRequest(reportData);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getSecondaryAgingReportSlice = createSlice({
  name: 'fetchSecondaryAgingReport',
  initialState,
  reducers: {
    clearFetchSecondaryAgingReportResponse: (state) => {
      state.fetchSecondaryAgingReportLoading = false;
      state.fetchSecondaryAgingReportData = { currentPage: 0, items: [], totalItems: 0, totalPages: 0 };
      state.fetchSecondaryAgingReportStatus = 'IDLE';
      state.fetchSecondaryAgingReportError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSecondaryAgingReportRequest.pending, (state, action) => {
        state.fetchSecondaryAgingReportLoading = true;
        state.fetchSecondaryAgingReportStatus = 'PENDING';
      })
      .addCase(getSecondaryAgingReportRequest.fulfilled, (state, action) => {
        state.fetchSecondaryAgingReportLoading = false;
        state.fetchSecondaryAgingReportStatus = 'SUCCESS';
        state.fetchSecondaryAgingReportData = action.payload;
      })
      .addCase(getSecondaryAgingReportRequest.rejected, (state, action) => {
        state.fetchSecondaryAgingReportLoading = false;
        state.fetchSecondaryAgingReportStatus = 'FAILED';
        state.fetchSecondaryAgingReportError = action.payload;
      });
  },
});

export const { clearFetchSecondaryAgingReportResponse } = getSecondaryAgingReportSlice.actions;

export const getSecondaryAgingReportSelector = (state: RootState) => state.GetSecondaryAgingReport;

export default getSecondaryAgingReportSlice.reducer;
