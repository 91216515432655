import { useAppSelector } from '../../../hooks/storeHooks/hooks';

import moment from 'moment';

import { Modal } from 'flowbite-react';
import { SelectInput } from '../../atoms/SelectInput';
import { TextArea } from '../../atoms/TextArea';
import { TableContent } from '../../table/TableContent';
import { CommonButton } from '../../atoms/CommonButton';
import { CommonAlert } from '../../atoms/Alert';
import { Spinner } from '../../atoms/Spinner';
import { claimStatus } from '../../../constants/claimConstants';

import { getReasonListSelector } from '../../../redux/slices/system/getReasonListSlice';
import { getClaimStatusListSelector } from '../../../redux/slices/system/getClaimStatusListSlice';
import { getCategoryListSelector } from '../../../redux/slices/system/getCategoryListSlice';
import { getARCategoryListSelector } from '../../../redux/slices/system/getARCategoryListSlice';

const columns = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    render: (text: string) =>
      text === 'No Notes Available!' ? text : moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : '',
  },
  {
    title: 'Comment',
    dataIndex: 'comment',
    key: 'comment',
    render: (text: any, record: any) => (
      <span>
        {record?.user ? <span className="font-bold">{record.user}: </span> : ''}
        {record?.comment}
      </span>
    ),
  },
];

export const EditStatusModal = ({
  editStatusModalOpen,
  handleOnClose,
  editStatusFormData,
  dataSource,
  dataSourceLoading,
  onSubmit,
  onChangeFields,
  alertObj,
  visibleAlert,
  handleAlertClose,
}: any) => {
  const { reasonListData } = useAppSelector(getReasonListSelector);
  const { claimStatusListData } = useAppSelector(getClaimStatusListSelector);
  const { categoryListData } = useAppSelector(getCategoryListSelector);
  const { arCategoryListData } = useAppSelector(getARCategoryListSelector);

  return (
    <Modal show={editStatusModalOpen} onClose={handleOnClose}>
      <Modal.Header>Change Status</Modal.Header>
      <Modal.Body>
        {visibleAlert && alertObj?.error && (
          <CommonAlert
            color={alertObj?.color}
            message={alertObj?.message}
            onClose={handleAlertClose}
            alertClassName="w-1/2"
          />
        )}
        <div className="grid md:grid-cols-2 gap-4 mt-6">
          <SelectInput
            label="Status"
            options={claimStatusListData}
            defaultPlaceholder="Select Claim Status"
            name="statusId"
            onChange={onChangeFields}
            value={editStatusFormData?.statusId ?? ''}
            required
          />
          <SelectInput
            label="Category"
            options={categoryListData}
            defaultPlaceholder="Select Category / PA"
            name="categoryId"
            onChange={onChangeFields}
            value={editStatusFormData?.categoryId ?? ''}
          />
        </div>
        <div className="grid md:grid-cols-2 gap-4 mt-6">
          <SelectInput
            label="Reason"
            options={reasonListData}
            defaultPlaceholder="Select Reason"
            name="reasonId"
            onChange={onChangeFields}
            value={editStatusFormData?.reasonId ?? ''}
            required
          />
          <SelectInput
            label="AR-Category"
            options={arCategoryListData}
            defaultPlaceholder="Select AR Category / PA"
            name="arCategoryId"
            onChange={onChangeFields}
            value={editStatusFormData?.arCategoryId ?? ''}
          />
        </div>
        <div className="grid md:grid-cols-1 mt-6 mb-4">
          <TextArea
            onChange={onChangeFields}
            name="notes"
            label="Notes"
            value={editStatusFormData?.notes ?? ''}
            required
          />
        </div>
        {dataSourceLoading ? (
          <Spinner />
        ) : (
          <TableContent
            enableActions={false}
            columns={columns}
            dataSource={dataSource?.length > 0 ? dataSource : [{ date: 'No Notes Available!', comment: '' }]}
          />
        )}
      </Modal.Body>
      <Modal.Footer className="grid md:grid-cols-2">
        <div className="col-span-1"></div>
        <div className="col-span-1 grid md:grid-cols-2 gap-2">
          <CommonButton type="button" label={'Close'} buttonType="secondary" onClick={handleOnClose} />
          <CommonButton type="button" label={'Submit'} buttonType="primary" onClick={onSubmit} />
        </div>
      </Modal.Footer>
    </Modal>
  );
};
