import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  UpdateBankReconciledBatchInterface,
  UpdateBankReconciledBatchInitialInterface,
} from '../../../interfaces/reportsInterfaces';

const initialState: UpdateBankReconciledBatchInitialInterface = {
  editBankReconciledRecordLoading: false,
  editBankReconciledRecordData: {},
  editBankReconciledRecordStatus: 'IDLE',
  editBankReconciledRecordError: null,
};

export const updateBankReconciledBatchRequest: any = createAsyncThunk(
  'reports/bank-reconciled/batch/update',
  async (payment: UpdateBankReconciledBatchInterface, thunkAPI: any) => {
    try {
      //   const response: any = await updateBankReconciledBatchAPIRequest(payment);
      //   return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const updateBankReconciledBatchSlice = createSlice({
  name: 'updateBankReconciledBatch',
  initialState,
  reducers: {
    clearUpdateBankReconciledBatchResponse: (state) => {
      state.editBankReconciledRecordLoading = false;
      state.editBankReconciledRecordStatus = 'IDLE';
      state.editBankReconciledRecordData = {};
      state.editBankReconciledRecordError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateBankReconciledBatchRequest.pending, (state, action) => {
        state.editBankReconciledRecordLoading = true;
        state.editBankReconciledRecordStatus = 'PENDING';
      })
      .addCase(updateBankReconciledBatchRequest.fulfilled, (state, action) => {
        state.editBankReconciledRecordData = action.payload;
        state.editBankReconciledRecordLoading = false;
        state.editBankReconciledRecordStatus = 'SUCCESS';
      })
      .addCase(updateBankReconciledBatchRequest.rejected, (state, action) => {
        state.editBankReconciledRecordLoading = false;
        state.editBankReconciledRecordStatus = 'FAILED';
        state.editBankReconciledRecordError = action.payload;
      });
  },
});

export const { clearUpdateBankReconciledBatchResponse } = updateBankReconciledBatchSlice.actions;

export const updateBankReconciledBatchSelector = (state: RootState) => state.UpdateBankReconciledBatch;

export default updateBankReconciledBatchSlice.reducer;
