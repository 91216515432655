import {
  CreateClaimInterface,
  EditClaimInterface,
  FetchClaimsInterface,
  SendClaimsInterface,
  FetchClaimEditStatusByIdInterface,
  UpdateClaimStatusInterface,
  AddClaimNotesInterface,
} from '../../interfaces/billingInterface';
import { authenticatedRequest } from '../../utils/commonAxios';

export const getClaimsAPIRequest = async (claimData: FetchClaimsInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/claim/search?page=${claimData?.page}&size=${claimData?.size}`,
    'post',
    {
      data: claimData?.filters ?? {},
    }
  );
};

export const sendClaimAPIRequest = async (claimData: SendClaimsInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/claim/${claimData?.claimId}/send-claim`,
    'post',
    {
      data: {},
    }
  );
};

export const createClaimAPIRequest = async (claimData: CreateClaimInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/patient/${claimData?.patientId}/visit/${claimData?.visitId}/create-claim`,
    'post',
    {
      data: {},
    }
  );
};

export const getClaimByIdAPIRequest = async (claimData: SendClaimsInterface) => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/claim/${claimData?.claimId}`, 'get');
};

export const editClaimAPIRequest = async (claimData: EditClaimInterface) => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/claim/${claimData?.claimId}`, 'post', {
    data: claimData?.claimEdit,
  });
};

export const sendBulkClaimsAPIRequest = async () => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/claim/send-claim-list`, 'post', {
    data: {},
  });
};

export const getClaimEditStatusByIdAPIRequest = async (searchParams: FetchClaimEditStatusByIdInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/claim/${searchParams?.claimId}/edit-status`,
    'get',
    {}
  );
};

export const updateClaimStatusAPIRequest = async (claimData: UpdateClaimStatusInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/claim/${claimData?.claimId}/edit-status`,
    'put',
    {
      data: { ...claimData?.claimStatusData },
    }
  );
};

export const getClaimEditStatusHistoryByIdAPIRequest = async (searchParams: FetchClaimEditStatusByIdInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/claim/${searchParams?.claimId}/edit-status/history`,
    'get',
    {}
  );
};

export const addClaimNotesAPIRequest = async (claimNoteData: AddClaimNotesInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/claim/${claimNoteData?.claimId}/note`,
    'post',
    {
      data: { note: claimNoteData?.note },
    }
  );
};

export const getClaimNotesHistoryByIdAPIRequest = async (claimId: number) => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/claim/${claimId}/note-history`, 'get', {});
};
