import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  DeleteReconciledMiscPaymentInterface,
  DeleteReconciledMiscPaymentInitialInterface,
} from '../../../interfaces/reportsInterfaces';

const initialState: DeleteReconciledMiscPaymentInitialInterface = {
  deleteReconciledMiscPaymentLoading: false,
  deleteReconciledMiscPaymentData: {},
  deleteReconciledMiscPaymentStatus: 'IDLE',
  deleteReconciledMiscPaymentError: null,
};

export const deleteReconciledMiscPaymentRequest: any = createAsyncThunk(
  'reports/bank-reconciled/payment/delete',
  async (searchParams: DeleteReconciledMiscPaymentInterface, thunkAPI: any) => {
    try {
      //   const response: any = await deleteReconciledMiscPaymentAPIRequest(searchParams);
      //   return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const deleteReconciledMiscPaymentSlice = createSlice({
  name: 'deleteReconciledMiscPayment',
  initialState,
  reducers: {
    clearDeleteReconciledMiscPaymentResponse: (state) => {
      state.deleteReconciledMiscPaymentLoading = false;
      state.deleteReconciledMiscPaymentData = {};
      state.deleteReconciledMiscPaymentStatus = 'IDLE';
      state.deleteReconciledMiscPaymentError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteReconciledMiscPaymentRequest.pending, (state, action) => {
        state.deleteReconciledMiscPaymentLoading = true;
        state.deleteReconciledMiscPaymentStatus = 'PENDING';
      })
      .addCase(deleteReconciledMiscPaymentRequest.fulfilled, (state, action) => {
        state.deleteReconciledMiscPaymentLoading = false;
        state.deleteReconciledMiscPaymentStatus = 'SUCCESS';
        state.deleteReconciledMiscPaymentData = action.payload;
      })
      .addCase(deleteReconciledMiscPaymentRequest.rejected, (state, action) => {
        state.deleteReconciledMiscPaymentLoading = false;
        state.deleteReconciledMiscPaymentError = action.payload;
        state.deleteReconciledMiscPaymentStatus = 'FAILED';
      });
  },
});

export const { clearDeleteReconciledMiscPaymentResponse } = deleteReconciledMiscPaymentSlice.actions;

export const deleteReconciledMiscPaymentSelector = (state: RootState) => state.DeleteReconciledMiscPayment;

export default deleteReconciledMiscPaymentSlice.reducer;
