import { authenticatedRequest } from '../../utils/commonAxios';
import {
  FetchPrimaryAgingReportInterface,
  FetchSecondaryAgingReportInterface,
  FetchCrossoverAgingReportInterface,
  FetchArAnalysisInterface,
  FetchFinancialDRArInterface,
  FetchInsuranceAgingSubReportsInterface,
} from '../../interfaces/reportsInterfaces';
import { queryParamGenerator } from '../../utils/commonFunctions';

export const getPrimaryAgingReportAPIRequest = async (reportData: FetchPrimaryAgingReportInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/report/account-receivable/primary-insurance?page=${reportData?.page}&size=${reportData?.size}&search=${reportData?.search}`,
    'get',
    {}
  );
};

export const getSecondaryAgingReportAPIRequest = async (reportData: FetchSecondaryAgingReportInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/report/account-receivable/secondary-insurance?page=${reportData?.page}&size=${reportData?.size}&search=${reportData?.search}`,
    'get',
    {}
  );
};

export const getCrossoverAgingReportAPIRequest = async (reportData: FetchCrossoverAgingReportInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/report/account-receivable/cross-over?page=${reportData?.page}&size=${reportData?.size}&search=${reportData?.search}`,
    'get',
    {}
  );
};

export const getArAnalysisAPIRequest = async (reportData: FetchArAnalysisInterface) => {
  const queryParams = queryParamGenerator(reportData?.filters);

  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/report/account-receivable/ar-analysis${queryParams}`,
    'get',
    {}
  );
};

export const getFinancialDRArAPIRequest = async (reportData: FetchFinancialDRArInterface) => {
  const queryParams = queryParamGenerator(reportData?.filters);

  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/report/account-receivable/financial${queryParams}`,
    'get',
    {}
  );
};

export const getPrimaryAgingSummaryAPIRequest = async () => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/report/account-receivable/primary-insurance-summery`,
    'get',
    {}
  );
};

export const getSecondaryAgingSummaryAPIRequest = async () => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/report/account-receivable/secondary-insurance-summery`,
    'get',
    {}
  );
};

export const getCrossoverAgingSummaryAPIRequest = async () => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/report/account-receivable/cross-over-summery`,
    'get',
    {}
  );
};

export const getPrimaryAgingSubReportAPIRequest = async (reportData: FetchInsuranceAgingSubReportsInterface) => {
  const { insuranceId, reportId, filters } = reportData;
  const queryParams = queryParamGenerator(filters);

  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/report/account-receivable/primary-aging-sub/${insuranceId}/${reportId}/search${queryParams}`,
    'get',
    {}
  );
};

export const getSecondaryAgingSubReportAPIRequest = async (reportData: FetchInsuranceAgingSubReportsInterface) => {
  const { insuranceId, reportId, filters } = reportData;
  const queryParams = queryParamGenerator(filters);

  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/report/account-receivable/secondary-aging-sub/${insuranceId}/${reportId}/search${queryParams}`,
    'get',
    {}
  );
};

export const getSecondaryAgingSubReportSummaryAPIRequest = async (
  reportData: FetchInsuranceAgingSubReportsInterface
) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/report/account-receivable/secondary-aging-sub-summary/${reportData?.insuranceId}/${reportData?.reportId}`,
    'get',
    {}
  );
};

export const getCrossoverCarrierAgingSubReportAPIRequest = async (
  reportData: FetchInsuranceAgingSubReportsInterface
) => {
  const { insuranceId, reportId, filters } = reportData;
  const queryParams = queryParamGenerator(filters);

  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/report/account-receivable/crossover-aging-sub/${insuranceId}/${reportId}/search${queryParams}`,
    'get',
    {}
  );
};

export const getCrossoverCarrierAgingSubReportSummaryAPIRequest = async (
  reportData: FetchInsuranceAgingSubReportsInterface
) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/report/account-receivable/crossover-aging-sub-summary/${reportData?.insuranceId}/${reportData?.reportId}`,
    'get',
    {}
  );
};
