import { useState, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks/hooks';

import { MainHeader } from '../../components/mainHeader/MainHeader';
import { PageTopic } from '../../components/pageTopic/PageTopic';
import { VisitCountReportCustomTable } from '../../components/table/VisitCountReportCustomTable';
import { EmptyContent } from '../../components/emptyContent/EmptyContent';
import { CommonAlert } from '../../components/atoms/Alert';
import { Spinner } from '../../components/atoms/Spinner';
import { VisitCountReportAdvanceSearch } from '../../components/tableSearch/VisitCountReportAdvanceSearch';

import {
  getVisitCountReportRequest,
  getVisitCountReportSelector,
  clearVisitCountReportResponse,
} from '../../redux/slices/reports/getVisitCountReportSlice';
import { getSystemProvidersRequest } from '../../redux/slices/system/getSystemProvidersSlice';
import { getSystemFacilityRequest } from '../../redux/slices/system/getSystemFacilitiesSlice';

const columns = (filterBy: string) => [
  {
    title: filterBy === 'PROVIDER' ? 'Facility' : 'Provider',
    dataIndex: 'entity',
    key: 'entity',
  },
  {
    title: 'January',
    dataIndex: 'january',
    key: 'january',
  },
  {
    title: 'February',
    dataIndex: 'february',
    key: 'february',
  },
  {
    title: 'March',
    dataIndex: 'march',
    key: 'march',
  },
  {
    title: 'April',
    dataIndex: 'april',
    key: 'april',
  },
  {
    title: 'May',
    dataIndex: 'may',
    key: 'may',
  },
  {
    title: 'June',
    dataIndex: 'june',
    key: 'june',
  },
  {
    title: 'July',
    dataIndex: 'july',
    key: 'july',
  },
  {
    title: 'August',
    dataIndex: 'august',
    key: 'august',
  },
  {
    title: 'September',
    dataIndex: 'september',
    key: 'september',
  },
  {
    title: 'October',
    dataIndex: 'october',
    key: 'october',
  },
  {
    title: 'November',
    dataIndex: 'november',
    key: 'november',
  },
  {
    title: 'December',
    dataIndex: 'december',
    key: 'december',
  },
  {
    title: 'Count',
    dataIndex: 'count',
    key: 'count',
  },
];

const dummyDataSource = [
  {
    mainEntity: 'Physician Care Solutions PC',
    totalCount: 16758,
    data: [
      {
        entity: 'Mohammed Yazbek',
        january: 0,
        february: 0,
        march: 0,
        april: 0,
        may: 3816,
        june: 3437,
        july: 3226,
        august: 3004,
        september: 2487,
        october: 0,
        november: 0,
        december: 0,
        count: 15970,
      },
      {
        entity: 'Abdulla Abdel Hafeez',
        january: 0,
        february: 0,
        march: 0,
        april: 0,
        may: 180,
        june: 86,
        july: 172,
        august: 181,
        september: 151,
        october: 0,
        november: 0,
        december: 0,
        count: 770,
      },
      {
        entity: 'Bashir Ahmed',
        january: 0,
        february: 0,
        march: 0,
        april: 0,
        may: 2,
        june: 0,
        july: 0,
        august: 0,
        september: 0,
        october: 0,
        november: 0,
        december: 0,
        count: 2,
      },
      {
        entity: 'Boris Rosenfeld',
        january: 0,
        february: 0,
        march: 0,
        april: 0,
        may: 15,
        june: 1,
        july: 0,
        august: 0,
        september: 0,
        october: 0,
        november: 0,
        december: 0,
        count: 16,
      },
    ],
  },
  {
    mainEntity: 'Medicare PLC',
    totalCount: 186,
    data: [
      {
        entity: 'Bashir Ahmed',
        january: 0,
        february: 0,
        march: 0,
        april: 0,
        may: 0,
        june: 6,
        july: 4,
        august: 4,
        september: 2,
        october: 0,
        november: 0,
        december: 0,
        count: 16,
      },
      {
        entity: 'Boris Rosenfeld',
        january: 0,
        february: 0,
        march: 0,
        april: 0,
        may: 0,
        june: 57,
        july: 71,
        august: 21,
        september: 21,
        october: 0,
        november: 0,
        december: 0,
        count: 170,
      },
    ],
  },
];

const interchangedDummyDataSource = [
  {
    mainEntity: 'Mohammed Yazbek',
    totalCount: 15970,
    data: [
      {
        entity: 'Physician Care Solutions PC',
        january: 0,
        february: 0,
        march: 0,
        april: 0,
        may: 3816,
        june: 3437,
        july: 3226,
        august: 3004,
        september: 2487,
        october: 0,
        november: 0,
        december: 0,
        count: 15970,
      },
      {
        entity: 'Medicare PLC',
        january: 0,
        february: 0,
        march: 0,
        april: 0,
        may: 0,
        june: 6,
        july: 4,
        august: 4,
        september: 2,
        october: 0,
        november: 0,
        december: 0,
        count: 18,
      },
    ],
  },
  {
    mainEntity: 'Abdulla Abdel Hafeez',
    totalCount: 770,
    data: [
      {
        entity: 'Physician Care Solutions PC',
        january: 0,
        february: 0,
        march: 0,
        april: 0,
        may: 180,
        june: 86,
        july: 172,
        august: 181,
        september: 151,
        october: 0,
        november: 0,
        december: 0,
        count: 770,
      },
    ],
  },
  {
    mainEntity: 'Bashir Ahmed',
    totalCount: 18,
    data: [
      {
        entity: 'Physician Care Solutions PC',
        january: 0,
        february: 0,
        march: 0,
        april: 0,
        may: 2,
        june: 6,
        july: 4,
        august: 4,
        september: 2,
        october: 0,
        november: 0,
        december: 0,
        count: 18,
      },
    ],
  },
  {
    mainEntity: 'Boris Rosenfeld',
    totalCount: 186,
    data: [
      {
        entity: 'Physician Care Solutions PC',
        january: 0,
        february: 0,
        march: 0,
        april: 0,
        may: 15,
        june: 58,
        july: 71,
        august: 21,
        september: 21,
        october: 0,
        november: 0,
        december: 0,
        count: 186,
      },
    ],
  },
];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  { id: 'visit-count', label: 'Visit Count Report', status: 'active', link: 'visit-count' },
];

const initialAlertState = {
  color: '',
  message: '',
  error: false,
};

export const VisitCountReportPage = () => {
  const dispatch = useAppDispatch();

  const { fetchVisitCountReportData, fetchVisitCountReportStatus, fetchVisitCountReportLoading } =
    useAppSelector(getVisitCountReportSelector);

  const [dataSource, setDataSource] = useState<any[]>([]);
  const [filterObject, setFilterObject] = useState<any>({
    providerId: null,
    facilityId: null,
    state: null,
    entityType: 'FACILITY',
    dateFrom: null,
    dateTo: null,
  });

  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>(initialAlertState);

  useEffect(() => {
    fetchSystemProviders();
    fetchSystemFacilities();
    setVisibleAlert(false);
    setAlertObj(initialAlertState);
  }, []);

  useEffect(() => {
    // if (filterObject?.filterBy === 'PROVIDER') {
    //   setDataSource(interchangedDummyDataSource);
    // } else {
    //   setDataSource(dummyDataSource);
    // }
    if (filterObject) {
      fetchVisitCountReport();
    }
  }, [filterObject]);

  useEffect(() => {
    if (fetchVisitCountReportStatus === 'SUCCESS') {
      setDataSource(fetchVisitCountReportData);
      dispatch(clearVisitCountReportResponse());
    } else if (fetchVisitCountReportStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj(initialAlertState);
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearVisitCountReportResponse());
    }
  }, [fetchVisitCountReportStatus]);

  const fetchVisitCountReport = () => {
    dispatch(getVisitCountReportRequest({ filters: filterObject }));
  };

  const fetchSystemProviders = () => {
    dispatch(getSystemProvidersRequest());
  };

  const fetchSystemFacilities = () => {
    dispatch(getSystemFacilityRequest());
  };

  const handleSearch = (data: any) => {
    const updatedData = { ...data };

    updatedData.providerId = updatedData.providerId ? parseInt(updatedData.providerId, 10) : null;

    updatedData.facilityId = updatedData.facilityId ? parseInt(updatedData.facilityId, 10) : null;

    updatedData.state = updatedData.state || null;

    // if (!updatedData?.entityType || updatedData?.entityType === '') {
    //   delete updatedData.entityType;
    // }

    setFilterObject(updatedData);
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
    setAlertObj(initialAlertState);
  };

  return (
    <div className="main-content">
      <MainHeader />
      <PageTopic mainTitle="Visit Count Report" enablePrimaryButton={false} breadCrumbArr={breadCrumbArr} />

      <VisitCountReportAdvanceSearch onSubmit={handleSearch} />

      {visibleAlert && alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}

      {fetchVisitCountReportLoading ? (
        <Spinner />
      ) : (
        <>
          {dataSource?.length > 0 ? (
            <div className="overflow-x-auto pb-7">
              <VisitCountReportCustomTable columns={columns(filterObject?.entityType)} dataSource={dataSource} />
            </div>
          ) : (
            <EmptyContent mode="VIEW" enableCreateButton={false} />
          )}
        </>
      )}
    </div>
  );
};
