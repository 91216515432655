import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { UpdateClaimStatusInterface, UpdateClaimStatusInitialInterface } from '../../../interfaces/billingInterface';
import { updateClaimStatusAPIRequest } from '../../../api/billing/claims';

const initialState: UpdateClaimStatusInitialInterface = {
  editClaimStatusByIdLoading: false,
  editClaimStatusByIdData: {},
  editClaimStatusByIdStatus: 'IDLE',
  editClaimStatusByIdError: null,
};

export const updateClaimStatusRequest: any = createAsyncThunk(
  'claim/claim-status/edit',
  async (claimData: UpdateClaimStatusInterface, thunkAPI: any) => {
    try {
      const response: any = await updateClaimStatusAPIRequest(claimData);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const updateClaimStatusSlice = createSlice({
  name: 'updateClaimStatus',
  initialState,
  reducers: {
    clearUpdateClaimStatusResponse: (state) => {
      state.editClaimStatusByIdLoading = false;
      state.editClaimStatusByIdStatus = 'IDLE';
      state.editClaimStatusByIdData = {};
      state.editClaimStatusByIdError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateClaimStatusRequest.pending, (state, action) => {
        state.editClaimStatusByIdLoading = true;
        state.editClaimStatusByIdStatus = 'PENDING';
      })
      .addCase(updateClaimStatusRequest.fulfilled, (state, action) => {
        state.editClaimStatusByIdData = action.payload;
        state.editClaimStatusByIdLoading = false;
        state.editClaimStatusByIdStatus = 'SUCCESS';
      })
      .addCase(updateClaimStatusRequest.rejected, (state, action) => {
        state.editClaimStatusByIdLoading = false;
        state.editClaimStatusByIdStatus = 'FAILED';
        state.editClaimStatusByIdError = action.payload;
      });
  },
});

export const { clearUpdateClaimStatusResponse } = updateClaimStatusSlice.actions;

export const updateClaimStatusSelector = (state: RootState) => state.UpdateClaimStatusById;

export default updateClaimStatusSlice.reducer;
