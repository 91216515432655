import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  FetchClaimEditStatusByIdInterface,
  FetchClaimEditStatusHistoryByIdInitialInterface,
} from '../../../interfaces/billingInterface';
import { getClaimEditStatusHistoryByIdAPIRequest } from '../../../api/billing/claims';

const initialState: FetchClaimEditStatusHistoryByIdInitialInterface = {
  fetchClaimEditStatusHistoryByIdLoading: false,
  fetchClaimEditStatusHistoryByIdData: [],
  fetchClaimEditStatusHistoryByIdError: null,
  fetchClaimEditStatusHistoryByIdStatus: 'IDLE',
};

export const getClaimEditStatusHistoryByIdRequest: any = createAsyncThunk(
  'claim/claim-edit-status-history/get/id',
  async (searchParams: FetchClaimEditStatusByIdInterface, thunkAPI: any) => {
    try {
      const response: any = await getClaimEditStatusHistoryByIdAPIRequest(searchParams);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getClaimEditStatusHistoryByIdSlice = createSlice({
  name: 'fetchClaimEditStatusHistoryById',
  initialState,
  reducers: {
    clearFetchClaimEditStatusHistoryByIdResponse: (state) => {
      state.fetchClaimEditStatusHistoryByIdLoading = false;
      state.fetchClaimEditStatusHistoryByIdStatus = 'IDLE';
      state.fetchClaimEditStatusHistoryByIdData = [];
      state.fetchClaimEditStatusHistoryByIdError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClaimEditStatusHistoryByIdRequest.pending, (state, action) => {
        state.fetchClaimEditStatusHistoryByIdLoading = true;
        state.fetchClaimEditStatusHistoryByIdStatus = 'PENDING';
      })
      .addCase(getClaimEditStatusHistoryByIdRequest.fulfilled, (state, action) => {
        state.fetchClaimEditStatusHistoryByIdLoading = false;
        state.fetchClaimEditStatusHistoryByIdStatus = 'SUCCESS';
        state.fetchClaimEditStatusHistoryByIdData = action.payload;
      })
      .addCase(getClaimEditStatusHistoryByIdRequest.rejected, (state, action) => {
        state.fetchClaimEditStatusHistoryByIdLoading = false;
        state.fetchClaimEditStatusHistoryByIdError = action.payload;
        state.fetchClaimEditStatusHistoryByIdStatus = 'FAILED';
      });
  },
});

export const { clearFetchClaimEditStatusHistoryByIdResponse } = getClaimEditStatusHistoryByIdSlice.actions;

export const getClaimEditStatusHistoryByIdSelector = (state: RootState) => state.GetClaimEditStatusHistoryById;

export default getClaimEditStatusHistoryByIdSlice.reducer;
