import {
  FetchBatchPaymentInterface,
  AddBatchPaymentInterface,
  UpdateBatchPaymentInterface,
  FetchBatchPaymentDetailsByIdInterface,
  FetchBulkPaymentListInterface,
  CloseBatchPaymentInterface,
  AddBulkLinePaymentsInterface,
} from '../../interfaces/paymentInterfaces';
import { authenticatedRequest } from '../../utils/commonAxios';

export const getBatchPaymentsAPIRequest = async (searchParams: FetchBatchPaymentInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/payment/batch/search?page=${searchParams?.page}&size=${searchParams?.size}`,
    'post',
    {
      data: searchParams?.filters ?? {},
    }
  );
};

export const getBatchPaymentByIdAPIRequest = async (searchParams: FetchBatchPaymentDetailsByIdInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/payment/batch/${searchParams?.paymentId}`,
    'get',
    {
      data: {},
    }
  );
};

export const addBatchPaymentAPIRequest = async (payment: AddBatchPaymentInterface) => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/payment/batch`, 'post', {
    data: { ...payment },
  });
};

export const updateBatchPaymentAPIRequest = async (payment: UpdateBatchPaymentInterface) => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/payment/batch/${payment?.paymentId}`, 'put', {
    data: { ...payment?.paymentData },
  });
};

export const getBatchPaymentTransactionsByIdAPIRequest = async (
  searchParams: FetchBatchPaymentDetailsByIdInterface
) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/payment/batch/${searchParams?.paymentId}/view-transactions`,
    'get',
    {
      data: {},
    }
  );
};

export const closeBatchPaymentAPIRequest = async (payment: CloseBatchPaymentInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/payment/batch/${payment?.paymentId}/close`,
    'put',
    {
      data: {},
    }
  );
};

export const getBulkPaymentsListAPIRequest = async (searchParams: FetchBulkPaymentListInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/patient/${searchParams?.patientId}/search-bulk-payment`,
    'get',
    {
      data: {},
    }
  );
};

export const getBatchPaymentByIdForApplyAPIRequest = async (searchParams: FetchBatchPaymentDetailsByIdInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/payment/batch/${searchParams?.paymentId}/apply`,
    'get',
    {
      data: {},
    }
  );
};

export const addBulkLinePaymentsAPIRequest = async (paymentsData: AddBulkLinePaymentsInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/payment/batch/${paymentsData?.batchId}/line-payment`,
    'post',
    {
      data: { ...paymentsData?.paymentsData },
    }
  );
};

export const getBulkPaymentWhoPaidOptionsAPIRequest = async (patientId: number) => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/patient/${patientId}/paid-by`, 'get', {});
};
