export const visitBilledProviderType = [
  { id: 6, name: 'Providers (MD/DO)' },
  { id: 1, name: 'Mid-Level Providers (NP/PA/APN/PA-C)' },
];

export const procedureProductivityProcedureType = [
  { id: 'CCM', name: 'CCM' },
  { id: 'SNF', name: 'SNF' },
  { id: 'BH', name: 'BH' },
];

export const procedureProductivityFilterBy = [
  { id: 'DOE', name: 'Filter By DOE' },
  { id: 'DOS', name: 'Filter By DOS' },
];

export const chargeDetailVisitReportFilterBy = [
  { id: 'DATE_CREATED', name: 'Filter By Date Created' },
  { id: 'DATE_OF_SERVICE', name: 'Filter By Date of Service' },
];
