import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks/hooks';
import moment from 'moment';

import { MainHeader } from '../../../components/mainHeader/MainHeader';
import { PageTopic } from '../../../components/pageTopic/PageTopic';
import { CommonAlert } from '../../../components/atoms/Alert';
import { Spinner } from '../../../components/atoms/Spinner';
import { BankReconciliationReportHeader } from '../../../components/reports/BankReconciliationReportHeader';
import { BankReconciliationReportCustomTable } from '../../../components/table/BankReconciliationReportCustomTable';

import {
  getBankReconciliationReportRequest,
  getBankReconciliationReportSelector,
  clearFetchBankReconciliationReportResponse,
} from '../../../redux/slices/reports/getBankReconcilationReportSlice';

import { formatCurrency } from '../../../utils/commonFunctions';

const columns = [
  {
    title: 'Bank Posting Date(BOA)',
    dataIndex: 'bankPostingDate',
    key: 'bankPostingDate',
    render: (text: string) => (text ? (moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : '') : ''),
    width: 140,
  },
  {
    title: 'Payer Posted Date',
    dataIndex: 'payerPostedDate',
    key: 'payerPostedDate',
    render: (text: string) => (text ? (moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : '') : ''),
    width: 140,
  },
  {
    title: 'Reconciled Date',
    dataIndex: 'reconciledDate',
    key: 'reconciledDate',
    render: (text: string) => (text ? (moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : '') : ''),
    width: 140,
  },
  {
    title: 'Batch Type',
    dataIndex: 'batchType',
    key: 'batchType',
    width: 120,
  },
  {
    title: 'Check Number',
    dataIndex: 'checkNumber',
    key: 'checkNumber',
    width: 170,
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    width: 200,
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Posted Amount',
    dataIndex: 'postedAmount',
    key: 'postedAmount',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Miscellaneous Amount',
    dataIndex: 'miscellaneousAmount',
    key: 'miscellaneousAmount',
    render: (text: any, record: any) => {
      if (text === 0) return null;
      return `${formatCurrency(text ?? 0)}${record?.miscCategory ? ` - ${record.miscCategory}` : ''}`;
    },
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    key: 'balance',
    render: (text: number) => formatCurrency(text ?? 0),
  },
];

const dummyDataSource = {
  totals: {
    amount: 0.0,
    postedAmount: 0.0,
    miscellaneousAmount: '$0.0',
    balance: 0.0,
  },
  items: [
    {
      date: '12/01/2024',
      totals: {
        amount: 0.0,
        postedAmount: 0.0,
        miscellaneousAmount: '$0.0',
        balance: 0.0,
      },
      detailedData: [
        {
          bankPostingDate: '12/01/2024',
          payerPostedDate: '11/13/2024',
          reconciledDate: '11/27/2024',
          batchType: 'EOBs',
          checkNumber: '824318000203383',
          description: 'Aetna-AS',
          amount: '$0.00',
          postedAmount: '$0.00',
          miscellaneousAmount: '$220.06 - Missing',
          balance: '$0.00',
        },
      ],
    },
    {
      date: '12/03/2024',
      totals: {
        amount: '$854.41',
        postedAmount: '$594.84',
        miscellaneousAmount: '$126.81',
        balance: '$132.76',
      },
      detailedData: [
        {
          bankPostingDate: '12/03/2024',
          payerPostedDate: '12/02/2024',
          reconciledDate: '12/05/2024',
          batchType: 'ERNs',
          checkNumber: '898739910',
          description: '',
          amount: '$342.10',
          postedAmount: '$342.10',
          miscellaneousAmount: '',
          balance: '$0.00',
        },
        {
          bankPostingDate: '12/03/2024',
          payerPostedDate: '12/03/2024',
          reconciledDate: '12/05/2024',
          batchType: 'ERNs',
          checkNumber: '11167541186',
          description: '',
          amount: '$385.50',
          postedAmount: '$252.74',
          miscellaneousAmount: '$8352.98 - Non-Our',
          balance: '$132.76',
        },
        {
          bankPostingDate: '12/03/2024',
          payerPostedDate: '11/27/2024',
          reconciledDate: '12/05/2024',
          batchType: 'EOBs',
          checkNumber: '882433101039112',
          description: 'AETNA~GS',
          amount: '$126.81',
          postedAmount: '$0.00',
          miscellaneousAmount: '$126.81 - Non-Our',
          balance: '$0.00',
        },
      ],
    },
  ],
};

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  {
    id: 'bank-reconciliation-report',
    label: 'Bank Reconciliation Report',
    status: 'active',
    link: 'bank-reconciliation-report',
  },
];

const initialAlertState = {
  color: '',
  message: '',
  error: false,
};

export const BankReconciliationReportPage = () => {
  const dispatch = useAppDispatch();

  const {
    fetchBankReconciliationReportStatus,
    fetchBankReconciliationReportData,
    fetchBankReconciliationReportLoading,
  } = useAppSelector(getBankReconciliationReportSelector);

  const currentYear = moment().year();
  const currentMonth = moment().format('MMM');

  const [dataSource, setDataSource] = useState<any>({ items: [], totals: {} });
  const [filterObject, setFilterObject] = useState<any>({
    year: currentYear.toString(),
    month: currentMonth.toString(),
    batchType: null,
    date: null,
  });

  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>(initialAlertState);

  useEffect(() => {
    setVisibleAlert(false);
    setAlertObj(initialAlertState);
  }, []);

  useEffect(() => {
    if (filterObject) {
      getBankReconciliationReport();
    }
  }, [filterObject]);

  useEffect(() => {
    if (fetchBankReconciliationReportStatus === 'SUCCESS') {
      setDataSource(fetchBankReconciliationReportData);
      dispatch(clearFetchBankReconciliationReportResponse());
    } else if (fetchBankReconciliationReportStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj(initialAlertState);
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearFetchBankReconciliationReportResponse());
    }
  }, [fetchBankReconciliationReportStatus]);

  const getBankReconciliationReport = () => {
    dispatch(getBankReconciliationReportRequest({ filters: filterObject }));
  };

  const handleSearch = (data: any) => {
    const updatedData = { ...data };

    updatedData.batchType = updatedData.batchType || null;

    updatedData.date = updatedData.date || null;

    setFilterObject(updatedData);
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
    setAlertObj(initialAlertState);
  };

  return (
    <div className="main-content">
      <MainHeader />
      <PageTopic mainTitle="Bank Reconciliation Report" enablePrimaryButton={false} breadCrumbArr={breadCrumbArr} />

      <BankReconciliationReportHeader onSubmit={handleSearch} showInputField />

      {visibleAlert && alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}

      {fetchBankReconciliationReportLoading ? (
        <Spinner />
      ) : (
        dataSource && (
          <>
            <div className="overflow-x-auto">
              <BankReconciliationReportCustomTable columns={columns} dataSource={dataSource} />
            </div>
          </>
        )
      )}
    </div>
  );
};
