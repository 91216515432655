import { useState, useEffect } from 'react';

import moment from 'moment';

import { PrimaryButton } from '../atoms/PrimaryButton';
import { FaFileExcel } from 'react-icons/fa';
import { InputField } from '../atoms/InputField';
import { DatePickerField } from '../atoms/DatePickerField';
import { CommonButton } from '../atoms/CommonButton';

import { formatDatePickerDate } from '../../utils/commonFunctions';
import './tableSearch.css';

export const AdvancedBatchSearch = ({ onSubmit, clearFilters, onExport, excelLoading }: any) => {
  const [filterObject, setFilterObject] = useState<any>({ checkNumber: null, checkDateFrom: null, checkDateTo: null });

  const onChangeFilter = (e: any) => {
    e.preventDefault();
    setFilterObject((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onChangeDate = (name: string, date: Date) => {
    setFilterObject((prev: any) => ({ ...prev, [name]: moment(date).format('YYYY-MM-DD') }));
  };

  useEffect(() => {
    setFilterObject({ checkNumber: null, checkDateFrom: null, checkDateTo: null });
  }, [clearFilters]);

  // console.log('Filter obj', filterObject);

  return (
    <div className="table-content__adv-search">
      <form>
        <div className="table-content__adv-search-content">
          <div className="grid md:grid-cols-4 gap-4 items-end">
            <DatePickerField
              label="Date From"
              selectedDate={formatDatePickerDate(filterObject?.checkDateFrom)}
              onChange={(date: Date | null) => {
                if (date) {
                  onChangeDate('checkDateFrom', date);
                }
              }}
            />
            <DatePickerField
              label="Date To"
              selectedDate={formatDatePickerDate(filterObject?.checkDateTo)}
              onChange={(date: Date | null) => {
                if (date) {
                  onChangeDate('checkDateTo', date);
                }
              }}
            />
            <InputField
              name="checkNumber"
              label="Check Number"
              onChange={onChangeFilter}
              value={filterObject?.checkNumber ?? ''}
            />
            <div className="flex gap-2 justify-end pt-2">
              <PrimaryButton
                type="button"
                label="Submit"
                style={{ height: '40px', maxWidth: '120px' }}
                onClick={() => onSubmit(filterObject)}
              />
              <CommonButton
                label="Reset"
                buttonType="secondary"
                style={{ height: '40px', maxWidth: '100px' }}
                onClick={() => {
                  setFilterObject({ checkNumber: null, checkDateFrom: null, checkDateTo: null });
                  onSubmit({ checkNumber: null, checkDateFrom: null, checkDateTo: null });
                }}
              />
              <CommonButton
                label=""
                icon={<FaFileExcel style={{ fontSize: '18px' }} />}
                style={{ height: '40px', maxWidth: '100px' }}
                onClick={() => onExport(filterObject)}
                loading={excelLoading}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
