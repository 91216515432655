export const addBankReconciliationMiscPaymentValidation = (formData: any) => {
  let isValid = true;
  const newErrors: any = {};

  if (!formData?.category) {
    newErrors.category = 'Category is required';
    isValid = false;
  }

  if (!formData?.amount) {
    newErrors.amount = 'Amount is required';
    isValid = false;
  }

  return { newErrors, isValid };
};
