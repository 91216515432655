import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { FetchClaimNotesHistoryByIdInitialInterface } from '../../../interfaces/billingInterface';
import { getClaimNotesHistoryByIdAPIRequest } from '../../../api/billing/claims';

const initialState: FetchClaimNotesHistoryByIdInitialInterface = {
  fetchClaimNotesHistoryByIdLoading: false,
  fetchClaimNotesHistoryByIdData: [],
  fetchClaimNotesHistoryByIdError: null,
  fetchClaimNotesHistoryByIdStatus: 'IDLE',
};

export const getClaimNotesHistoryByIdRequest: any = createAsyncThunk(
  'claim/claim-notes-history/get/id',
  async (claimId: number, thunkAPI: any) => {
    try {
      const response: any = await getClaimNotesHistoryByIdAPIRequest(claimId);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getClaimNotesHistoryByIdSlice = createSlice({
  name: 'fetchClaimNotesHistoryById',
  initialState,
  reducers: {
    clearFetchClaimNotesHistoryByIdResponse: (state) => {
      state.fetchClaimNotesHistoryByIdLoading = false;
      state.fetchClaimNotesHistoryByIdStatus = 'IDLE';
      state.fetchClaimNotesHistoryByIdData = [];
      state.fetchClaimNotesHistoryByIdError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClaimNotesHistoryByIdRequest.pending, (state, action) => {
        state.fetchClaimNotesHistoryByIdLoading = true;
        state.fetchClaimNotesHistoryByIdStatus = 'PENDING';
      })
      .addCase(getClaimNotesHistoryByIdRequest.fulfilled, (state, action) => {
        state.fetchClaimNotesHistoryByIdLoading = false;
        state.fetchClaimNotesHistoryByIdStatus = 'SUCCESS';
        state.fetchClaimNotesHistoryByIdData = action.payload;
      })
      .addCase(getClaimNotesHistoryByIdRequest.rejected, (state, action) => {
        state.fetchClaimNotesHistoryByIdLoading = false;
        state.fetchClaimNotesHistoryByIdStatus = 'FAILED';
        state.fetchClaimNotesHistoryByIdError = action.payload;
      });
  },
});

export const { clearFetchClaimNotesHistoryByIdResponse } = getClaimNotesHistoryByIdSlice.actions;

export const getClaimNotesHistoryByIdSelector = (state: RootState) => state.GetClaimNotesHistoryById;

export default getClaimNotesHistoryByIdSlice.reducer;
