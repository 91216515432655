import { authenticatedRequest } from '../../utils/commonAxios';
import { FetchBillingSnapshotReportInterface } from '../../interfaces/reportsInterfaces';

import { queryParamGenerator } from '../../utils/commonFunctions';

export const getBillingSnapshotReportAPIRequest = async (reportData: FetchBillingSnapshotReportInterface) => {
  const queryParams = queryParamGenerator(reportData?.filters);

  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/report/billing-summery${queryParams}`,
    'get',
    {}
  );
};

export const getBillingSnapshotSummaryAPIRequest = async (reportData: FetchBillingSnapshotReportInterface) => {
  const queryParams = queryParamGenerator(reportData?.filters);

  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/report/billing-outstanding${queryParams}`,
    'get',
    {}
  );
};
