import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  FetchPaymentByPatientInterface,
  FetchPaymentByPatientInitialInterface,
} from '../../../interfaces/reportsInterfaces';
import { getPaymentByPatientAPIRequest } from '../../../api/reports/payment';

const initialState: FetchPaymentByPatientInitialInterface = {
  fetchPaymentByPatientLoading: false,
  fetchPaymentByPatientData: {
    currentPage: 0,
    items: [],
    totalItems: 0,
    totalPages: 0,
  },
  fetchPaymentByPatientError: null,
  fetchPaymentByPatientStatus: 'IDLE',
};

export const getPaymentByPatientRequest: any = createAsyncThunk(
  'reports/bank-reconcilation-report/get',
  async (reportData: FetchPaymentByPatientInterface, thunkAPI: any) => {
    try {
      const response: any = await getPaymentByPatientAPIRequest(reportData);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getPaymentByPatientSlice = createSlice({
  name: 'fetchPaymentByPatient',
  initialState,
  reducers: {
    clearFetchPaymentByPatientResponse: (state) => {
      state.fetchPaymentByPatientLoading = false;
      state.fetchPaymentByPatientData = { currentPage: 0, items: [], totalItems: 0, totalPages: 0 };
      state.fetchPaymentByPatientStatus = 'IDLE';
      state.fetchPaymentByPatientError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentByPatientRequest.pending, (state, action) => {
        state.fetchPaymentByPatientLoading = true;
        state.fetchPaymentByPatientStatus = 'PENDING';
      })
      .addCase(getPaymentByPatientRequest.fulfilled, (state, action) => {
        state.fetchPaymentByPatientData = action.payload;
        state.fetchPaymentByPatientLoading = false;
        state.fetchPaymentByPatientStatus = 'SUCCESS';
      })
      .addCase(getPaymentByPatientRequest.rejected, (state, action) => {
        state.fetchPaymentByPatientLoading = false;
        state.fetchPaymentByPatientStatus = 'FAILED';
        state.fetchPaymentByPatientError = action.payload;
      });
  },
});

export const { clearFetchPaymentByPatientResponse } = getPaymentByPatientSlice.actions;

export const getPaymentByPatientSelector = (state: RootState) => state.GetPaymentByPatient;

export default getPaymentByPatientSlice.reducer;
