import { CodeFormPropsInterface } from '../../../interfaces/userInterface';
import { Checkbox } from '../../atoms/Checkbox';
import { InputField } from '../../atoms/InputField';
import { TextArea } from '../../atoms/TextArea';
import { SelectInput } from '../../atoms/SelectInput';
import { useAppSelector } from '../../../hooks/storeHooks/hooks';
import { getModifierListSelector } from '../../../redux/slices/codes/modifierListSlices';
import { convertingToDropdownArr } from '../../../utils/commonFunctions';

export const RightSheetForm: React.FC<CodeFormPropsInterface> = ({
  onChange,
  codeData,
  mode,
  selectedId,
  errorObject,
}) => {
  const { modifierListData } = useAppSelector(getModifierListSelector);

  const getModifierList = () => {
    return convertingToDropdownArr(modifierListData);
  };

  return (
    <>
      <>
        <div className="grid md:grid-cols-4 gap-4 mt-6">
          <InputField
            label="CPT Code"
            name="code"
            onChange={onChange}
            defaultValue={codeData?.code ?? undefined}
            disabled={mode === 'VIEW' ? true : false}
            required={true}
            error={errorObject && errorObject?.code}
            errorMessage={errorObject && errorObject?.code}
          />
          <SelectInput
            label="Modifier"
            options={getModifierList()}
            enableDefaultPlaceholder={true}
            name="modifierCodeId"
            onChange={onChange}
            defaultValue={codeData?.modifierCodeId ?? undefined}
            disabled={mode === 'VIEW' ? true : false}
          />
          <Checkbox
            label="Never Bill"
            onChange={onChange}
            name="isNeverBill"
            disabled={mode === 'VIEW' ? true : false}
            defaultChecked={codeData?.isNeverBill ?? undefined}
          />

          <Checkbox
            label="Disable CPT in APP"
            onChange={onChange}
            name="isDisableCptInApp"
            disabled={mode === 'VIEW' ? true : false}
            defaultChecked={codeData?.isDisableCptInApp ?? undefined}
          />
        </div>
        <div className="grid md:grid-cols-3 gap-4 mt-6">
          <InputField
            label="Amount"
            name="amount"
            onChange={onChange}
            defaultValue={codeData?.amount ?? undefined}
            disabled={mode === 'VIEW' ? true : false}
            required={true}
            error={errorObject && errorObject?.amount}
            errorMessage={errorObject && errorObject?.amount}
          />
          <InputField
            label="Work RVU"
            name="rvuWeight"
            onChange={onChange}
            defaultValue={codeData?.rvuWeight ?? undefined}
            disabled={mode === 'VIEW' ? true : false}
            // error={errorObject && errorObject?.firstName}
            // errorMessage={errorObject && errorObject?.firstName}
          />
          <InputField
            label="POS"
            name="pos"
            onChange={onChange}
            disabled={mode === 'VIEW' ? true : false}
            defaultValue={codeData?.pos ?? undefined}
            error={errorObject && errorObject?.pos}
            errorMessage={errorObject && errorObject?.pos}
            tooltip="Character length must be 2"
          />
        </div>
        <div className="grid md:grid-cols-3 gap-4 mt-6">
          <InputField
            label="TOS"
            name="tos"
            onChange={onChange}
            defaultValue={codeData?.tos ?? undefined}
            disabled={mode === 'VIEW' ? true : false}
            // required={true}
            error={errorObject && errorObject?.tos}
            errorMessage={errorObject && errorObject?.tos}
            tooltip="Character length must be 2"
          />
          <InputField
            label="Short Description"
            name="shortDesc"
            onChange={onChange}
            defaultValue={codeData?.shortDesc ?? undefined}
            disabled={mode === 'VIEW' ? true : false}
            // required={true}
            // error={errorObject && errorObject?.firstName}
            // errorMessage={errorObject && errorObject?.firstName}
          />
        </div>

        <div className="grid md:grid-cols-1 gap-4 mt-6">
          <TextArea
            name="longDesc"
            label="Long Description"
            defaultValue={codeData?.longDesc ?? undefined}
            onChange={onChange}
            disabled={mode === 'VIEW' ? true : false}
            // required={true}
          />
        </div>
      </>
    </>
  );
};
