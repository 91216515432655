import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  UpdateBankToBeReconciledPaymentInterface,
  UpdateBankToBeReconciledPaymentInitialInterface,
} from '../../../interfaces/reportsInterfaces';
import { updateBankToBeReconciledPaymentAPIRequest } from '../../../api/reports/payment';

const initialState: UpdateBankToBeReconciledPaymentInitialInterface = {
  editBankToBeReconciledPaymentLoading: false,
  editBankToBeReconciledPaymentData: {},
  editBankToBeReconciledPaymentStatus: 'IDLE',
  editBankToBeReconciledPaymentError: null,
};

export const updateBankToBeReconciledPaymentRequest: any = createAsyncThunk(
  'reports/bank-to-be-reconciled/payment/update',
  async (payment: UpdateBankToBeReconciledPaymentInterface, thunkAPI: any) => {
    try {
      const response: any = await updateBankToBeReconciledPaymentAPIRequest(payment);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const updateBankToBeReconciledPaymentSlice = createSlice({
  name: 'updateBankToBeReconciledPayment',
  initialState,
  reducers: {
    clearUpdateBankToBeReconciledPaymentResponse: (state) => {
      state.editBankToBeReconciledPaymentLoading = false;
      state.editBankToBeReconciledPaymentStatus = 'IDLE';
      state.editBankToBeReconciledPaymentData = {};
      state.editBankToBeReconciledPaymentError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateBankToBeReconciledPaymentRequest.pending, (state, action) => {
        state.editBankToBeReconciledPaymentLoading = true;
        state.editBankToBeReconciledPaymentStatus = 'PENDING';
      })
      .addCase(updateBankToBeReconciledPaymentRequest.fulfilled, (state, action) => {
        state.editBankToBeReconciledPaymentData = action.payload;
        state.editBankToBeReconciledPaymentLoading = false;
        state.editBankToBeReconciledPaymentStatus = 'SUCCESS';
      })
      .addCase(updateBankToBeReconciledPaymentRequest.rejected, (state, action) => {
        state.editBankToBeReconciledPaymentLoading = false;
        state.editBankToBeReconciledPaymentStatus = 'FAILED';
        state.editBankToBeReconciledPaymentError = action.payload;
      });
  },
});

export const { clearUpdateBankToBeReconciledPaymentResponse } = updateBankToBeReconciledPaymentSlice.actions;

export const updateBankToBeReconciledPaymentSelector = (state: RootState) => state.UpdateBankToBeReconciledPayment;

export default updateBankToBeReconciledPaymentSlice.reducer;
