import { useState } from 'react';

import moment from 'moment';

import { SelectInput } from '../atoms/SelectInput';
import { PrimaryButton } from '../atoms/PrimaryButton';
import { CommonButton } from '../atoms/CommonButton';
import { FaFileExcel } from 'react-icons/fa';

export const DashboardBankReconciliationReportHeader = ({ onSubmit }: any) => {
  const currentYear = moment().year();
  const currentMonth = moment().format('MMMM');

  const [filterObject, setFilterObject] = useState<any>({
    year: currentYear.toString(),
    month: null,
  });

  const yearOptions = Array.from({ length: currentYear - 2018 + 1 }, (_, i) => {
    const year = 2018 + i;
    return { id: year.toString(), name: year.toString() };
  });

  const monthOptions = moment.months().map((month) => ({
    id: moment(month, 'MMMM').format('MMM'),
    name: month,
  }));

  const onChangeFilter = (e: any) => {
    setFilterObject((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  return (
    <div className="table-content__adv-search">
      <form>
        <div className="table-content__adv-search-content">
          <div className="grid md:grid-cols-4 gap-4 items-end">
            <SelectInput
              options={yearOptions}
              enableDefaultPlaceholder={false}
              name="year"
              onChange={onChangeFilter}
              value={filterObject?.year ?? ''}
            />
            <SelectInput
              options={monthOptions}
              defaultPlaceholder="Month"
              name="month"
              onChange={onChangeFilter}
              value={filterObject?.month ?? ''}
            />

            <div className="flex gap-2">
              <PrimaryButton
                type="button"
                label="Submit"
                style={{ height: '40px', maxWidth: '100px' }}
                onClick={() => onSubmit(filterObject)}
              />
              <CommonButton
                label="Reset"
                buttonType="secondary"
                style={{ height: '40px', maxWidth: '100px' }}
                onClick={() => {
                  setFilterObject({ year: currentYear.toString(), month: null });
                  onSubmit({ year: currentYear.toString(), month: null });
                }}
              />

              <PrimaryButton
                type="button"
                label="Export to Excel"
                icon={<FaFileExcel style={{ fontSize: '18px' }} />}
                style={{ height: '40px', maxWidth: '100px' }}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
