import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { RightSheetState } from '../../../interfaces/appInterfaces';

const resetSheetIds = ['Patients-RightSheet', 'Provider-RightSheet'];

const initialState: RightSheetState = {
  sheetId: '',
  isRightSheetOpen: false,
};

const rightSheetSlice = createSlice({
  name: 'rightSheet',
  initialState,
  reducers: {
    openRightSheet: (state, action: PayloadAction<string>): void => {
      state.sheetId = action.payload;
      state.isRightSheetOpen = true;
    },
    closeRightSheet: (state): void => {
      state.sheetId = '';
      state.isRightSheetOpen = false;
    },
    restoreRightSheetState: (state) => {
      const storedSheetId = sessionStorage.getItem('sheetId') || '';
      const storedIsRightSheetOpen = sessionStorage.getItem('isRightSheetOpen') === 'true';

      if (resetSheetIds.includes(storedSheetId)) {
        state.sheetId = '';
        state.isRightSheetOpen = false;
      } else {
        state.sheetId = storedSheetId;
        state.isRightSheetOpen = storedIsRightSheetOpen;
      }
    },
  },
});

export const { openRightSheet, closeRightSheet, restoreRightSheetState } = rightSheetSlice.actions;

export const rightSheetSelector = (state: RootState) => state.RightSheet;

export default rightSheetSlice.reducer;
