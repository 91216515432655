import axios from 'axios';

import { GetToken } from './commonAxios';

export const handleExcelDownload = async ({ url, fileName, isBlob = false, onSuccess, onError }: any) => {
  try {
    const ipAddress = localStorage.getItem('ipAddress');

    const headers = {
      Authorization: `Bearer ${GetToken().token}`,
      email: btoa(GetToken().user.email),
      'client-ip': ipAddress || '',
    };

    const response = await axios.get(url, { headers, responseType: isBlob ? 'blob' : 'json' });

    if (isBlob) {
      const blob = new Blob([response.data], { type: response.headers['content-type'] });

      const href = window.URL.createObjectURL(blob);
      const link = document.createElement('a');

      link.href = href;
      link.setAttribute('download', fileName);

      document.body.appendChild(link);
      link.click();
      link.remove();

      window.URL.revokeObjectURL(href);
    } else {
      const fileUrl = response.data;

      const link = document.createElement('a');
      link.href = fileUrl;

      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }

    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    if (onError) {
      onError();
    }
  }
};
