import { authenticatedRequest } from '../../utils/commonAxios';
import {
  ChargeDetailVisitReportFetchInterface,
  ChargeDetailEncounterReportFetchInterface,
} from '../../interfaces/managementInterfaces';

import { queryParamGenerator } from '../../utils/commonFunctions';

export const getChargeDetailVisitReportAPIRequest = async (reportData: ChargeDetailVisitReportFetchInterface) => {
  const queryParams = queryParamGenerator(reportData?.filters);

  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/report/visit-charge-details${queryParams}`,
    'get',
    {}
  );
};

export const getClientsListAPIRequest = async () => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/system/clients`, 'get', {
    data: {},
  });
};

export const getChargeDetailEncounterReportAPIRequest = async (
  reportData: ChargeDetailEncounterReportFetchInterface
) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/management/charge-detail-encounter-report/search?page=${reportData?.page}&size=${reportData?.size}`,
    'post',
    { data: reportData?.filters ?? {} }
  );
};
