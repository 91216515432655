import { useState } from 'react';

import { useAppSelector } from '../../hooks/storeHooks/hooks';

import { SelectInput } from '../atoms/SelectInput';
import { CommonButton } from '../atoms/CommonButton';
import { PrimaryButton } from '../atoms/PrimaryButton';

import { getSystemInsuranceSelector } from '../../redux/slices/system/getSystemInsuranceSlice';

import { CrossoverCarriersReportFoundOptions } from '../../constants/reportsConstants';

export const CrossoverCarrierReportHeader = ({ onSubmit }: any) => {
  const { systemInsuranceInsuranceData } = useAppSelector(getSystemInsuranceSelector);

  const [filterObject, setFilterObject] = useState<any>({
    primaryPayerId: null,
    foundOption: null,
  });

  const onChangeFilter = (e: any) => {
    setFilterObject((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  return (
    <>
      <>
        <div className="grid md:grid-cols-5 gap-4 mt-6">
          <SelectInput
            label=""
            name="primaryPayerId"
            options={systemInsuranceInsuranceData}
            defaultPlaceholder="Select Primary Payer"
            value={filterObject?.primaryPayerId ?? ''}
            onChange={onChangeFilter}
          />

          <SelectInput
            label=""
            name="foundOption"
            options={CrossoverCarriersReportFoundOptions}
            defaultPlaceholder="ALL"
            value={filterObject?.foundOption ?? ''}
            onChange={onChangeFilter}
          />
          <div className="grid md:grid-cols-2 gap-4 items-end">
            <PrimaryButton
              type="button"
              label="Submit"
              style={{ height: '40px', maxWidth: '120px' }}
              onClick={() => onSubmit(filterObject)}
            />
            <CommonButton
              label="Reset"
              buttonType="secondary"
              style={{ height: '40px', maxWidth: '100px' }}
              onClick={() => {
                setFilterObject({ primaryPayerId: null, foundOption: null });
                onSubmit({ primaryPayerId: null, foundOption: null });
              }}
            />
          </div>
        </div>
      </>
    </>
  );
};
