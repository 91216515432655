import { DBReconciliationSnapshotData } from '../../interfaces/reportsInterfaces';
import { formatCurrency } from '../../utils/commonFunctions';

import './tableContent.css';

export const DBReconciliationSnapshotCustomTable = ({ dataSource }: { dataSource: DBReconciliationSnapshotData }) => {
  const {
    postedPayment,
    postedInsurancePayment,
    postedPatientPayment,
    postedPreviousMonthPayment,
    postedTotal,
    bankReceivedAmount,
    bankPostedInsuranceAmount,
    bankPostedPatientAmount,
    bankTotalReconciliation,
    bonus,
    unrelatedPayments,
    missing,
    payerInterest,
    payerTestDeposit,
    efttransFee,
    miscellaneousTotal,
  } = dataSource;

  const SectionHeader = ({ title }: { title: string }) => (
    <tr>
      <td colSpan={2} className="font-bold text-center">
        {title}
      </td>
    </tr>
  );

  return (
    <div className="table-content mb-8">
      <div className="table-content__table-view">
        <table className="table-content__table">
          <thead className="table-content__table-header">
            <tr>
              <th>Category</th>
              <th className="text-right">Amount</th>
            </tr>
          </thead>
          <tbody className="table-content__table-body">
            <SectionHeader title="Dashboard Payments Posted" />
            <tr>
              <td className="font-bold">Payment Posted this month :</td>
              <td className="text-right">{formatCurrency(postedPayment ?? 0)}</td>
            </tr>
            <tr>
              <td>Insurance Payment per bank reconciliation this month</td>
              <td className="text-right">{formatCurrency(postedInsurancePayment ?? 0)}</td>
            </tr>
            <tr>
              <td>Patient Payment per bank reconciliation this month</td>
              <td className="text-right">{formatCurrency(postedPatientPayment ?? 0)}</td>
            </tr>
            <tr>
              <td>Previous Month unposted amount posted this month</td>
              <td className="text-right">{formatCurrency(postedPreviousMonthPayment ?? 0)}</td>
            </tr>
            <tr>
              <td className="font-bold">Total :</td>
              <td className="text-right">{formatCurrency(postedTotal ?? 0)}</td>
            </tr>

            <SectionHeader title="Bank Reconciliation" />
            <tr>
              <td>
                {' '}
                <strong>Received :</strong> Bank reconciliation for the month
              </td>
              <td className="text-right">{formatCurrency(bankReceivedAmount ?? 0)}</td>
            </tr>
            <tr>
              <td>
                <strong>Posted :</strong> Insurance Payment per bank reconciliation for the month
              </td>
              <td className="text-right">{formatCurrency(bankPostedInsuranceAmount ?? 0)}</td>
            </tr>
            <tr>
              <td>
                <strong>Posted :</strong> Patient Payment per bank reconciliation for the month
              </td>
              <td className="text-right">{formatCurrency(bankPostedPatientAmount ?? 0)}</td>
            </tr>
            <tr>
              <td>
                <strong>Total :</strong> Payment per bank reconciliation for the month
              </td>
              <td className="text-right">{formatCurrency(bankTotalReconciliation ?? 0)}</td>
            </tr>

            <SectionHeader title="Other Highlights" />
            <tr>
              <td>Bonus</td>
              <td className="text-right">{formatCurrency(bonus ?? 0)}</td>
            </tr>
            <tr>
              <td>Payment not related to our billing</td>
              <td className="text-right">{formatCurrency(unrelatedPayments ?? 0)}</td>
            </tr>
            <tr>
              <td>Missing EOB/ERA</td>
              <td className="text-right">{formatCurrency(missing ?? 0)}</td>
            </tr>
            <tr>
              <td>Payer Interest Payment</td>
              <td className="text-right">{formatCurrency(payerInterest ?? 0)}</td>
            </tr>
            <tr>
              <td>Payer Test Deposit</td>
              <td className="text-right">{formatCurrency(payerTestDeposit ?? 0)}</td>
            </tr>
            <tr>
              <td>EFT Trans Fee</td>
              <td className="text-right">{formatCurrency(efttransFee ?? 0)}</td>
            </tr>
            <tr className="font-bold">
              <td>Total Miscellaneous</td>
              <td className="text-right">{formatCurrency(miscellaneousTotal ?? 0)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
