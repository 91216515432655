import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  AddReconciliationMiscPaymentInterface,
  AddBankToBeReconciledMiscPaymentInitialInterface,
} from '../../../interfaces/reportsInterfaces';
import { addBankToBeReconciledMiscPaymentAPIRequest } from '../../../api/reports/payment';

const initialState: AddBankToBeReconciledMiscPaymentInitialInterface = {
  addBankToBeReconciledMiscPaymentLoading: false,
  addBankToBeReconciledMiscPaymentData: {},
  addBankToBeReconciledMiscPaymentError: null,
  addBankToBeReconciledMiscPaymentStatus: 'IDLE',
};

export const addBankToBeReconciledMiscPaymentRequest: any = createAsyncThunk(
  'reports/bank-to-be-reconciled/misc-payment/add',
  async (payment: AddReconciliationMiscPaymentInterface, thunkAPI: any) => {
    try {
      const response: any = await addBankToBeReconciledMiscPaymentAPIRequest(payment);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const addBankToBeReconciledMiscPaymentSlice = createSlice({
  name: 'addBankToBeReconciledMiscPayment',
  initialState,
  reducers: {
    clearAddBankToBeReconciledMiscPaymentResponse: (state) => {
      state.addBankToBeReconciledMiscPaymentLoading = false;
      state.addBankToBeReconciledMiscPaymentStatus = 'IDLE';
      state.addBankToBeReconciledMiscPaymentData = {};
      state.addBankToBeReconciledMiscPaymentError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addBankToBeReconciledMiscPaymentRequest.pending, (state, action) => {
        state.addBankToBeReconciledMiscPaymentLoading = true;
        state.addBankToBeReconciledMiscPaymentStatus = 'PENDING';
      })
      .addCase(addBankToBeReconciledMiscPaymentRequest.fulfilled, (state, action) => {
        state.addBankToBeReconciledMiscPaymentData = action.payload;
        state.addBankToBeReconciledMiscPaymentStatus = 'SUCCESS';
        state.addBankToBeReconciledMiscPaymentLoading = false;
      })
      .addCase(addBankToBeReconciledMiscPaymentRequest.rejected, (state, action) => {
        state.addBankToBeReconciledMiscPaymentLoading = false;
        state.addBankToBeReconciledMiscPaymentError = action.payload;
        state.addBankToBeReconciledMiscPaymentStatus = 'FAILED';
      });
  },
});

export const { clearAddBankToBeReconciledMiscPaymentResponse } = addBankToBeReconciledMiscPaymentSlice.actions;

export const addBankToBeReconciledMiscPaymentSelector = (state: RootState) => state.AddBankToBeReconciledMiscPayment;

export default addBankToBeReconciledMiscPaymentSlice.reducer;
