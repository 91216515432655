import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { AuthPropType, useAuth } from '../providers/AuthProvider';

import { Sidebar } from '../sidebar/Sidebar';
import { SidebarNew } from '../sidebar/SidebarNew';
import { SideNavBar } from '../sidebar/SideNavBar';

const PrivateRoutes = () => {
  const { user }: AuthPropType = useAuth();

  return user ? (
    <>
      <SideNavBar />
      <Outlet />
    </>
  ) : (
    <>
      <SideNavBar />
      <Outlet />
    </>
  );
};

export default PrivateRoutes;
