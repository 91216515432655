import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  FetchClaimEditStatusByIdInterface,
  FetchClaimEditStatusByIdInitialInterface,
} from '../../../interfaces/billingInterface';
import { getClaimEditStatusByIdAPIRequest } from '../../../api/billing/claims';

const initialState: FetchClaimEditStatusByIdInitialInterface = {
  fetchClaimEditStatusByIdLoading: false,
  fetchClaimEditStatusByIdData: {},
  fetchClaimEditStatusByIdError: null,
  fetchClaimEditStatusByIdStatus: 'IDLE',
};

export const getClaimEditStatusByIdRequest: any = createAsyncThunk(
  'claim/claim-edit-status/get/id',
  async (searchParams: FetchClaimEditStatusByIdInterface, thunkAPI: any) => {
    try {
      const response: any = await getClaimEditStatusByIdAPIRequest(searchParams);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getClaimEditStatusByIdSlice = createSlice({
  name: 'fetchClaimEditStatusById',
  initialState,
  reducers: {
    clearFetchClaimEditStatusByIdResponse: (state) => {
      state.fetchClaimEditStatusByIdLoading = false;
      state.fetchClaimEditStatusByIdData = {};
      state.fetchClaimEditStatusByIdStatus = 'IDLE';
      state.fetchClaimEditStatusByIdError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClaimEditStatusByIdRequest.pending, (state, action) => {
        state.fetchClaimEditStatusByIdLoading = true;
        state.fetchClaimEditStatusByIdStatus = 'PENDING';
      })
      .addCase(getClaimEditStatusByIdRequest.fulfilled, (state, action) => {
        state.fetchClaimEditStatusByIdLoading = false;
        state.fetchClaimEditStatusByIdStatus = 'SUCCESS';
        state.fetchClaimEditStatusByIdData = action.payload;
      })
      .addCase(getClaimEditStatusByIdRequest.rejected, (state, action) => {
        state.fetchClaimEditStatusByIdLoading = false;
        state.fetchClaimEditStatusByIdStatus = 'FAILED';
        state.fetchClaimEditStatusByIdError = action.payload;
      });
  },
});

export const { clearFetchClaimEditStatusByIdResponse } = getClaimEditStatusByIdSlice.actions;

export const getClaimEditStatusByIdSelector = (state: RootState) => state.GetClaimEditStatusById;

export default getClaimEditStatusByIdSlice.reducer;
