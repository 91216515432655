import { authenticatedRequest } from '../../utils/commonAxios';
import { FetchFinancialDashboardReportInterface } from '../../interfaces/reportsInterfaces';

import { queryParamGenerator } from '../../utils/commonFunctions';

export const getFinancialDashboardReportAPIRequest = async (reportData: FetchFinancialDashboardReportInterface) => {
  const queryParams = queryParamGenerator(reportData?.filters);

  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/report/financial-dashboard${queryParams}`,
    'get',
    {}
  );
};
