import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { FetchBulkPaymentWhoPaidOptionsInitialInterface } from '../../../../interfaces/paymentInterfaces';
import { getBulkPaymentWhoPaidOptionsAPIRequest } from '../../../../api/payments/batchPayments';

const initialState: FetchBulkPaymentWhoPaidOptionsInitialInterface = {
  fetchBulkPaymentWhoPaidOptionsLoading: false,
  fetchBulkPaymentWhoPaidOptionsData: [],
  fetchBulkPaymentWhoPaidOptionsError: null,
  fetchBulkPaymentWhoPaidOptionsStatus: 'IDLE',
};

export const getBulkPaymentWhoPaidOptionsRequest: any = createAsyncThunk(
  'bulk-payment/who-paid-options/get',
  async (patientId: number, thunkAPI: any) => {
    try {
      const response: any = await getBulkPaymentWhoPaidOptionsAPIRequest(patientId);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getBulkPaymentWhoPaidOptionsSlice = createSlice({
  name: 'fetchBulkPaymentWhoPaidOptions',
  initialState,
  reducers: {
    clearFetchBulkPaymentWhoPaidOptionsResponse: (state) => {
      state.fetchBulkPaymentWhoPaidOptionsLoading = false;
      state.fetchBulkPaymentWhoPaidOptionsData = [];
      state.fetchBulkPaymentWhoPaidOptionsStatus = 'IDLE';
      state.fetchBulkPaymentWhoPaidOptionsError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBulkPaymentWhoPaidOptionsRequest.pending, (state, action) => {
        state.fetchBulkPaymentWhoPaidOptionsLoading = true;
        state.fetchBulkPaymentWhoPaidOptionsStatus = 'PENDING';
      })
      .addCase(getBulkPaymentWhoPaidOptionsRequest.fulfilled, (state, action) => {
        state.fetchBulkPaymentWhoPaidOptionsLoading = false;
        state.fetchBulkPaymentWhoPaidOptionsStatus = 'SUCCESS';
        state.fetchBulkPaymentWhoPaidOptionsData = action.payload;
      })
      .addCase(getBulkPaymentWhoPaidOptionsRequest.rejected, (state, action) => {
        state.fetchBulkPaymentWhoPaidOptionsLoading = false;
        state.fetchBulkPaymentWhoPaidOptionsStatus = 'FAILED';
        state.fetchBulkPaymentWhoPaidOptionsError = action.payload;
      });
  },
});

export const { clearFetchBulkPaymentWhoPaidOptionsResponse } = getBulkPaymentWhoPaidOptionsSlice.actions;

export const getBulkPaymentWhoPaidOptionsSelector = (state: RootState) => state.GetBulkPaymentWhoPaidOptions;

export default getBulkPaymentWhoPaidOptionsSlice.reducer;
