import { authenticatedRequest } from '../../utils/commonAxios';
import { FetchBilledAndUnbilledReportInterface } from '../../interfaces/reportsInterfaces';

import { queryParamGenerator } from '../../utils/commonFunctions';

export const getBilledAndUnbilledReportAPIRequest = async (reportData: FetchBilledAndUnbilledReportInterface) => {
  const queryParams = queryParamGenerator(reportData?.filters);

  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/report/billed-status${queryParams}`,
    'get',
    {}
  );
};
