import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks/hooks';
import moment from 'moment';

import { MainHeader } from '../../../components/mainHeader/MainHeader';
import { PageTopic } from '../../../components/pageTopic/PageTopic';
import { CommonAlert } from '../../../components/atoms/Alert';
import { Spinner } from '../../../components/atoms/Spinner';
import { EmptyContent } from '../../../components/emptyContent/EmptyContent';
import { DashboardBankReconciliationReportHeader } from '../../../components/reports/DashboardBankReconciliationReportHeader';
import { DBReconciliationSnapshotCustomTable } from '../../../components/table/DBReconciliationSnapshotCustomTable';

import {
  getDBRSnapshotRequest,
  getDBRSnapshotSelector,
  clearFetchDBRSnapshotResponse,
} from '../../../redux/slices/reports/getDBRSnapshotSlice';

const dummyDataSource = {
  paymentPostedMonthly: 340.0,
  insurancePaymentReconciliation: 943.0,
  patientPaymentReconciliation: 1230.0,
  previousMonthUnposted: 430.0,
  dashboardPaymentsPostedTotal: 12.32,
  bankReceived: 40.0,
  bankInsurancePosted: 30.0,
  bankPatientPosted: 0.0,
  bankReconciliationTotal: 32.23,
  bonus: 0.0,
  unrelatedPayments: 0.0,
  missingEOBERA: 43.0,
  payerInterest: 34132.0,
  payerTestDeposit: 211.0,
  eftTransFee: 76.0,
  totalMiscellaneous: 0.0,
};

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  {
    id: 'dashboard-bank-reconciliation-snapshot',
    label: 'Dashboard & Bank Reconciliation Snapshot',
    status: 'active',
    link: 'dashboard-bank-reconciliation-snapshot',
  },
];

const initialAlertState = {
  color: '',
  message: '',
  error: false,
};

export const DashboardBankReconciliationSnapshotPage = () => {
  const dispatch = useAppDispatch();

  const { fetchDBRSnapshotStatus, fetchDBRSnapshotData, fetchDBRSnapshotLoading } =
    useAppSelector(getDBRSnapshotSelector);

  const currentYear = moment().year();
  const currentMonth = moment().format('MMM');

  const [dataSource, setDataSource] = useState<any>({});
  const [filterObject, setFilterObject] = useState<any>({
    year: currentYear.toString(),
    month: null,
  });

  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>(initialAlertState);

  useEffect(() => {
    setVisibleAlert(false);
    setAlertObj(initialAlertState);
  }, []);

  // useEffect(() => {
  //   if (filterObject) {
  //     fetchDBReconciliationSnapshotReport();
  //   }
  // }, [filterObject]);

  useEffect(() => {
    if (fetchDBRSnapshotStatus === 'SUCCESS') {
      setDataSource(fetchDBRSnapshotData);
      dispatch(clearFetchDBRSnapshotResponse());
    } else if (fetchDBRSnapshotStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj(initialAlertState);
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearFetchDBRSnapshotResponse());
    }
  }, [fetchDBRSnapshotStatus]);

  const fetchDBReconciliationSnapshotReport = (filterValues = filterObject) => {
    dispatch(getDBRSnapshotRequest({ filters: filterValues }));
  };

  const handleSearch = (data: any) => {
    const updatedData = { ...data };

    if (!updatedData.month) {
      setAlertObj({
        color: 'failure',
        message: 'Please select a month!',
        error: true,
      });
      setVisibleAlert(true);
      setDataSource({});
      setTimeout(() => {
        setAlertObj(initialAlertState);
        setVisibleAlert(false);
      }, 3000);
      return;
    }

    setFilterObject(updatedData);
    fetchDBReconciliationSnapshotReport(updatedData);
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
    setAlertObj(initialAlertState);
  };

  console.log('DATA SOURCE', dataSource);

  return (
    <div className="main-content">
      <MainHeader />
      <PageTopic
        mainTitle="Dashboard & Bank Reconciliation Snapshot"
        enablePrimaryButton={false}
        breadCrumbArr={breadCrumbArr}
      />
      <DashboardBankReconciliationReportHeader onSubmit={handleSearch} />

      {visibleAlert && alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}

      {fetchDBRSnapshotLoading ? (
        <Spinner />
      ) : (
        <>
          {dataSource && Object.keys(dataSource).length > 0 ? (
            <div className="overflow-x-auto">
              <DBReconciliationSnapshotCustomTable dataSource={dataSource} />
            </div>
          ) : (
            <EmptyContent mode="VIEW" enableCreateButton={false} />
          )}
        </>
      )}
    </div>
  );
};
