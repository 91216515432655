import { useState } from 'react';

import { useAppSelector } from '../../hooks/storeHooks/hooks';
import moment from 'moment';

import { PrimaryButton } from '../atoms/PrimaryButton';
import { CommonButton } from '../atoms/CommonButton';
import { SelectInput } from '../atoms/SelectInput';
import { ExtendedDateRangePicker } from '../atoms/ExtendedDateRangePicker';
import { FaFileExcel } from 'react-icons/fa';

import { getSystemProvidersSelector } from '../../redux/slices/system/getSystemProvidersSlice';
import { getSystemFacilitiesSelector } from '../../redux/slices/system/getSystemFacilitiesSlice';

import { usStates } from '../../constants/systemConstants';
import { chargeDetailVisitReportFilterBy, visitBilledProviderType } from '../../constants/managementConstants';

export const FinancialDashboardReportAdvanceSearch = ({ onSubmit }: any) => {
  const { systemProvidersProviderData } = useAppSelector(getSystemProvidersSelector);
  const { systemFacilityData } = useAppSelector(getSystemFacilitiesSelector);

  const currentYear = moment().year();

  const [filterObject, setFilterObject] = useState<any>({
    year: currentYear.toString(),
    type: 'DATE_OF_SERVICE',
    dateFrom: null,
    dateTo: null,
    state: null,
    providerType: null,
    providerId: null,
    facilityId: null,
  });
  const [resetDate, setResetDate] = useState<boolean>(false);

  const yearOptions = Array.from({ length: currentYear - 2021 + 2 }, (_, i) => {
    const year = 2021 + i;
    return { id: year.toString(), name: year.toString() };
  });

  const onChangeFilter = (e: any) => {
    setFilterObject((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onChangeDateRange = (dateRange: { startDate: Date; endDate: Date }) => {
    setFilterObject((prev: any) => ({
      ...prev,
      dateFrom: moment(dateRange.startDate).format('YYYY-MM-DD'),
      dateTo: moment(dateRange.endDate).format('YYYY-MM-DD'),
    }));
  };

  return (
    <div className="table-content__adv-search">
      <form>
        <div className="table-content__adv-search-content">
          <div className="grid md:grid-cols-4 gap-4 items-end">
            <SelectInput
              enableDefaultPlaceholder={false}
              name="type"
              options={chargeDetailVisitReportFilterBy}
              onChange={onChangeFilter}
              value={filterObject?.type ?? ''}
            />
            <SelectInput
              enableDefaultPlaceholder={false}
              name="year"
              options={yearOptions}
              onChange={onChangeFilter}
              value={filterObject?.year ?? ''}
            />
            <ExtendedDateRangePicker
              initialDateRange={[
                {
                  startDate: new Date(filterObject?.dateFrom ?? new Date()),
                  endDate: new Date(filterObject?.dateTo ?? new Date()),
                  key: 'selection',
                },
              ]}
              onChange={onChangeDateRange}
              resetDate={resetDate}
            />
            <SelectInput
              name="state"
              options={usStates}
              defaultPlaceholder="All States"
              onChange={onChangeFilter}
              value={filterObject?.state ?? ''}
            />
          </div>
          <div className="grid md:grid-cols-4 gap-4 mb-4 mt-4 items-center">
            <SelectInput
              defaultPlaceholder="Select Provider Type"
              name="providerType"
              options={visitBilledProviderType}
              onChange={onChangeFilter}
              value={filterObject?.providerType ?? ''}
            />
            <SelectInput
              defaultPlaceholder="Select Provider / PA"
              name="providerId"
              options={systemProvidersProviderData}
              onChange={onChangeFilter}
              value={filterObject?.providerId ?? ''}
            />
            <SelectInput
              defaultPlaceholder="All Facility"
              name="facilityId"
              options={systemFacilityData}
              onChange={onChangeFilter}
              value={filterObject?.facilityId ?? ''}
            />
            <div className="flex gap-2 justify-end">
              <PrimaryButton
                type="button"
                label="Submit"
                style={{ height: '40px', maxWidth: '100px' }}
                onClick={() => onSubmit(filterObject)}
              />
              <CommonButton
                label="Reset"
                buttonType="secondary"
                style={{ height: '40px', maxWidth: '100px' }}
                onClick={() => {
                  setFilterObject({
                    year: currentYear.toString(),
                    type: 'DATE_OF_SERVICE',
                    dateFrom: null,
                    dateTo: null,
                  });
                  setResetDate((prev) => !prev);
                  onSubmit({ year: currentYear.toString(), type: 'DATE_OF_SERVICE', dateFrom: null, dateTo: null });
                }}
              />
              <PrimaryButton
                type="button"
                label="Export to Excel"
                icon={<FaFileExcel style={{ fontSize: '18px' }} />}
                style={{ height: '40px', maxWidth: '100px' }}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
