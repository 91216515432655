import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../hooks/storeHooks/hooks';

import { Sidebar } from 'flowbite-react';

import SimplibillLogo from '../../assets/images/simplibill-logo-color.svg';
import { DashboardIcon } from '../customIcons/sidebarIcons/DashboardIcon';
import { ListIcon } from '../customIcons/sidebarIcons/ListIcon';
import { BillingIcon } from '../customIcons/sidebarIcons/BillingIcon';
import { AccountsIcon } from '../customIcons/sidebarIcons/AccountsIcon';
import { ReportsIcon } from '../customIcons/sidebarIcons/ReportsIcon';

import { closeRightSheet } from '../../redux/slices/app/rightSheetSlice';

import './sidenavbar.css';

const menuItems = [
  {
    label: 'List',
    icon: ListIcon,
    isDisabled: false,
    children: [
      { label: 'Patients/Guarantors', path: '/patients', isDisabled: false },
      { label: 'Providers', path: '/providers', isDisabled: false },
      { label: 'Facilities', path: '/facilities', isDisabled: false },
      { label: 'Insurance', path: '/insurance', isDisabled: false },
    ],
  },
  {
    label: 'Billing',
    icon: BillingIcon,
    isDisabled: false,
    children: [
      { label: 'Visits', path: '/billing', isDisabled: false },
      {
        label: 'Claims',
        isDisabled: false,
        children: [
          { label: 'Primary Claims', path: '/primary-claims', isDisabled: false },
          { label: 'Secondary Claims', path: '/secondary-claims', isDisabled: true },
          { label: 'Create Secondary Claims', path: '/create-secondary-claims', isDisabled: true },
        ],
      },
      {
        label: 'Payments',
        isDisabled: false,
        children: [
          { label: 'Batch Payment', path: '/billing-batch-payment', isDisabled: false },
          { label: 'Claim MD ERA Auto Post', path: '/claim-auto-post', isDisabled: false },
          { label: 'Denial', path: '/denial-reports', isDisabled: false },
          { label: 'Claim ERA Files', path: '/claim-era-files', isDisabled: false },
          { label: 'ERA files by Received Date', path: '/claim-era-files-by-recieved-date', isDisabled: false },
          { label: 'ClaimMD ERA Payments', path: '/claimmd-era', isDisabled: false },
        ],
      },
      { label: 'Patient Statements', path: '/patient-statements', isDisabled: true },
      { label: 'Patient Eligibility', path: '/patient-eligibility', isDisabled: true },
    ],
  },
  {
    label: 'Account',
    icon: AccountsIcon,
    isDisabled: false,
    children: [
      { label: 'Practices', path: '/practices', isDisabled: true },
      { label: 'Users', path: '/users', isDisabled: false },
      {
        label: 'Codes',
        isDisabled: false,
        children: [
          { label: 'CPT Codes', path: '/cpt-codes', isDisabled: false },
          { label: 'ICD Codes', path: '/icd-codes', isDisabled: false },
          { label: 'Payment/Adjustment Codes', path: '/payment-codes', isDisabled: false },
          { label: 'Modifier Codes', path: '/modifier-codes', isDisabled: false },
        ],
      },
    ],
  },
  {
    label: 'Management',
    icon: ListIcon,
    isDisabled: false,
    children: [
      { label: 'Visits Billed Count', path: '/visit-billed-count', isDisabled: true },
      { label: 'Procedure Productivity', path: '/procedure-productivity', isDisabled: true },
      { label: 'Charge Detail Report', path: '/charge-detail', isDisabled: true },
      { label: 'Charge Detail Report - Visit', path: '/charge-detail-visit', isDisabled: false },
      { label: 'Charge Detail Report - Encounter', path: '/charge-detail-encounter', isDisabled: true },
    ],
  },
  {
    label: 'Reports',
    icon: ReportsIcon,
    isDisabled: true,
    children: [
      {
        label: 'Account Receivable',
        isDisabled: true,
        children: [
          { label: 'Primary Insurance Aging', path: '/primary-insurance-report', isDisabled: false },
          { label: 'Secondary Insurance Aging', path: '/secondary-insurance-report', isDisabled: false },
          { label: 'Crossover Carrier Aging', path: '/crossover-report', isDisabled: false },
          { label: 'AR Analysis', path: '/ar-analysis-report', isDisabled: false },
          { label: 'Financial Dashboard Report AR', path: '/financial-dashboard-ar-report', isDisabled: false },
        ],
      },
      {
        label: 'Payment',
        isDisabled: false,
        children: [
          { label: 'Bank Reconciliation', path: '/bank-reconciliation-to-be-reconciled-report', isDisabled: false },
          {
            label: 'Bank Reconciliation - Reconciled',
            path: '/bank-reconciliation-reconciled-report',
            isDisabled: false,
          },
          { label: 'Bank Reconciliation Report', path: '/bank-reconciliation-report', isDisabled: false },
          {
            label: 'Dashboard & Bank Reconciliation Snapshot',
            path: '/dashboard-bank-reconciliation-snapshot',
            isDisabled: true,
          },
          { label: 'Crossover Carrier', path: '/crossover-carrier-report', isDisabled: true },
          { label: 'Payment by Patient', path: '/patient-payment', isDisabled: false },
        ],
      },
      {
        label: 'Payroll',
        isDisabled: true,
        children: [{ label: 'RVU Report', path: '/rvu-report', isDisabled: false }],
      },
      { label: 'Financial Dashboard', path: '/financial-dashboard', isDisabled: false },
      { label: 'Financial Dashboard Report by Payer Mix', path: '/financial-dashboard-payer-mix', isDisabled: true },
      { label: 'Doctor Financial', path: '/doctor-financial', isDisabled: true },
      { label: 'Billed and Unbilled', path: '/billed-unbilled-report', isDisabled: true },
      { label: 'Billing Summary', path: '/billing-snapshot', isDisabled: true },
      { label: 'Visits Report', path: '/visit-report', isDisabled: true },
      { label: 'Charge Detail Report - No Action in 35 days', path: '/charge-detail-no-action', isDisabled: true },
      { label: 'Visit Count', path: '/visit-count-report', isDisabled: true },
      { label: 'Provider Visit Lag Report', path: '/provider-lag-report', isDisabled: true },
      { label: 'Weekly Analysis', path: '/weekly-analysis', isDisabled: true },
      { label: 'Reimbursement Report', path: '/reimbursement-report', isDisabled: true },
      { label: 'Expected vs Collections - Detailed Report', path: '/expected-vs-collection-report', isDisabled: true },
    ],
  },
].filter((menu) => !menu.isDisabled);

const ArrowIcon = () => (
  <svg
    className="w-4 h-4 ml-2"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M9 5l7 7-7 7" />
  </svg>
);

const customTheme = {
  root: {
    inner: 'h-full overflow-visible bg-gray-50 px-3 py-4 dark:bg-gray-800',
  },
};

export const SideNavBar = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [openMenus, setOpenMenus] = useState<Record<string, boolean>>({});

  const toggleMenu = (menuKey: string, isOpen: boolean) => {
    setOpenMenus((prev) => ({ ...prev, [menuKey]: isOpen }));
  };

  const handleNavigation = (path: string) => {
    navigate(path);
    dispatch(closeRightSheet());
  };

  return (
    <Sidebar className="sidebar-nav" theme={customTheme}>
      <div className="sidebar">
        <div className="sidebar__logo">
          <a onClick={() => handleNavigation('/dashboard')} className="cursor-pointer">
            <img src={SimplibillLogo} alt="Simplibill logo" className="h-10" />
          </a>
        </div>

        <Sidebar.Items>
          <Sidebar.ItemGroup className="sidebar__menu">
            <Sidebar.Item
              onClick={(e: any) => {
                e.preventDefault();
                handleNavigation('/dashboard');
              }}
              icon={DashboardIcon}
              className="sidebar__menu-item-link cursor-pointer"
            >
              <span className="sidebar__menu-item-link--text">Dashboard</span>
            </Sidebar.Item>
            {menuItems.map((menu) => (
              <div
                key={menu.label}
                className={`relative ${openMenus[menu.label] ? 'bg-gray-100' : ''}`}
                onMouseEnter={() => toggleMenu(menu.label, true)}
                onMouseLeave={() => toggleMenu(menu.label, false)}
              >
                <Sidebar.Item icon={menu.icon}>{menu.label}</Sidebar.Item>
                {menu.children && openMenus[menu.label] && (
                  <div
                    className={`z-[60] absolute left-full ${
                      menu.label === 'Reports' ? 'top-0' : 'top-0'
                    } bg-white shadow-sm drop-shadow-[0_0_10px_rgba(0,0,0,0.15)] min-w-[220px] w-max p-2 rounded-md`}
                  >
                    {menu.children
                      .filter((submenu) => !submenu.isDisabled)
                      .map((submenu) =>
                        submenu.children ? (
                          <div
                            key={submenu.label}
                            className={`relative group ${openMenus[submenu.label] ? 'bg-gray-100' : ''}`}
                            onMouseEnter={() => toggleMenu(submenu.label, true)}
                            onMouseLeave={() => toggleMenu(submenu.label, false)}
                          >
                            <div className="p-2 hover:bg-gray-100 cursor-pointer flex justify-between items-center text-base font-normal">
                              {submenu.label} <ArrowIcon />
                            </div>
                            {openMenus[submenu.label] && (
                              <div className="z-[60] absolute left-full top-0 bg-white shadow-sm drop-shadow-[0_0_10px_rgba(0,0,0,0.15)] min-w-[220px] w-max p-2 rounded-md">
                                {submenu.children
                                  .filter((item) => !item.isDisabled)
                                  .map((item) => (
                                    <div
                                      key={item.label}
                                      className="px-2 py-[6px] hover:bg-gray-100 cursor-pointer text-base font-normal"
                                      onClick={() => handleNavigation(item.path)}
                                    >
                                      {item.label}
                                    </div>
                                  ))}
                              </div>
                            )}
                          </div>
                        ) : (
                          <div
                            key={submenu.label}
                            className="px-2 py-[6px] hover:bg-gray-100 cursor-pointer text-base font-normal"
                            onClick={() => handleNavigation(submenu.path)}
                          >
                            {submenu.label}
                          </div>
                        )
                      )}
                  </div>
                )}
              </div>
            ))}
          </Sidebar.ItemGroup>
        </Sidebar.Items>
      </div>
    </Sidebar>
  );
};
