import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  FetchDoctorFinancialReportInterface,
  FetchDoctorFinancialReportByPayerMixInitialInterface,
} from '../../../interfaces/reportsInterfaces';
import { getDoctorFinancialReportByPayerMixAPIRequest } from '../../../api/reports/doctorFinancialReport';

const initialState: FetchDoctorFinancialReportByPayerMixInitialInterface = {
  fetchDoctorFinancialReportByPayerMixLoading: false,
  fetchDoctorFinancialReportByPayerMixData: {
    startingBalanceAR: {},
    monthlyData: [],
    totalData: {
      totals: {},
      payerMix: [],
    },
  },
  fetchDoctorFinancialReportByPayerMixError: null,
  fetchDoctorFinancialReportByPayerMixStatus: 'IDLE',
};

export const getDoctorFinancialReportByPayerMixRequest: any = createAsyncThunk(
  'reports/doctor-financial-payer-mix/get',
  async (reportData: FetchDoctorFinancialReportInterface, thunkAPI: any) => {
    try {
      const response: any = await getDoctorFinancialReportByPayerMixAPIRequest(reportData);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getDoctorFinancialReportByPayerMixSlice = createSlice({
  name: 'fetchDoctorFinancialByPayerMixReport',
  initialState,
  reducers: {
    clearDoctorFinancialByPayerMixReportResponse: (state) => {
      state.fetchDoctorFinancialReportByPayerMixLoading = false;
      state.fetchDoctorFinancialReportByPayerMixData = {
        startingBalanceAR: {},
        monthlyData: [],
        totalData: {
          totals: {},
          payerMix: [],
        },
      };
      state.fetchDoctorFinancialReportByPayerMixStatus = 'IDLE';
      state.fetchDoctorFinancialReportByPayerMixError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDoctorFinancialReportByPayerMixRequest.pending, (state, action) => {
        state.fetchDoctorFinancialReportByPayerMixLoading = true;
        state.fetchDoctorFinancialReportByPayerMixStatus = 'PENDING';
      })
      .addCase(getDoctorFinancialReportByPayerMixRequest.fulfilled, (state, action) => {
        state.fetchDoctorFinancialReportByPayerMixLoading = false;
        state.fetchDoctorFinancialReportByPayerMixStatus = 'SUCCESS';
        state.fetchDoctorFinancialReportByPayerMixData = action.payload;
      })
      .addCase(getDoctorFinancialReportByPayerMixRequest.rejected, (state, action) => {
        state.fetchDoctorFinancialReportByPayerMixLoading = false;
        state.fetchDoctorFinancialReportByPayerMixStatus = 'FAILED';
        state.fetchDoctorFinancialReportByPayerMixError = action.payload;
      });
  },
});

export const { clearDoctorFinancialByPayerMixReportResponse } = getDoctorFinancialReportByPayerMixSlice.actions;

export const getDoctorFinancialReportByPayerMixSelector = (state: RootState) =>
  state.GetDoctorFinancialByPayerMixReport;

export default getDoctorFinancialReportByPayerMixSlice.reducer;
