import { useState } from 'react';

import { useAppSelector } from '../../hooks/storeHooks/hooks';
import moment from 'moment';

import { PrimaryButton } from '../atoms/PrimaryButton';
import { CommonButton } from '../atoms/CommonButton';
import { DatePickerField } from '../atoms/DatePickerField';
import { SelectInput } from '../atoms/SelectInput';
import { FaFileExcel } from 'react-icons/fa';

import { getSystemProvidersSelector } from '../../redux/slices/system/getSystemProvidersSlice';
import { getSystemFacilitiesSelector } from '../../redux/slices/system/getSystemFacilitiesSlice';
import { getSystemVisitStatusSelector } from '../../redux/slices/system/getSystemVisitStatusSlice';

import { usStates } from '../../constants/systemConstants';

import { formatDatePickerDate } from '../../utils/commonFunctions';
import { convertingToDropdownArr } from '../../utils/commonFunctions';

export const VisitReportAdvanceSearch = ({ onSubmit }: any) => {
  const { systemProvidersProviderData } = useAppSelector(getSystemProvidersSelector);
  const { systemFacilityData } = useAppSelector(getSystemFacilitiesSelector);
  const { systemVisitData } = useAppSelector(getSystemVisitStatusSelector);

  const initialFromDate = moment().startOf('month').format('YYYY-MM-DD');
  const initialToDate = moment().endOf('month').format('YYYY-MM-DD');

  const [filterObject, setFilterObject] = useState<any>({
    dateFrom: initialFromDate,
    dateTo: initialToDate,
    providerId: null,
    facilityId: null,
    state: null,
    visitStatusId: null,
  });

  const onChangeFilter = (e: any) => {
    setFilterObject((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onChangeDate = (name: string, date: any) => {
    setFilterObject((prev: any) => ({ ...prev, [name]: moment(date).format('YYYY-MM-DD') }));
  };

  return (
    <div className="table-content__adv-search">
      <form>
        <div className="table-content__adv-search-content">
          <div className="grid md:grid-cols-3 gap-4 items-end">
            <SelectInput
              defaultPlaceholder="Select Provider / PA"
              name="providerId"
              options={systemProvidersProviderData}
              onChange={onChangeFilter}
              value={filterObject?.providerId ?? ''}
            />
            <SelectInput
              defaultPlaceholder="All Facility"
              name="facilityId"
              options={systemFacilityData}
              onChange={onChangeFilter}
              value={filterObject?.facilityId ?? ''}
            />
            <SelectInput
              defaultPlaceholder="All States"
              name="state"
              options={usStates}
              onChange={onChangeFilter}
              value={filterObject?.state ?? ''}
            />
          </div>
          <div className="grid md:grid-cols-4 gap-4 mb-4 mt-4 items-center">
            <SelectInput
              options={convertingToDropdownArr(systemVisitData)}
              defaultPlaceholder="Select Visit Status"
              name="visitStatusId"
              onChange={onChangeFilter}
              value={filterObject?.visitStatusId ?? ''}
            />
            <DatePickerField
              selectedDate={formatDatePickerDate(filterObject?.dateFrom)}
              onChange={(date: Date | null) => {
                if (date) {
                  onChangeDate('dateFrom', date);
                }
              }}
            />
            <DatePickerField
              selectedDate={formatDatePickerDate(filterObject?.dateTo)}
              onChange={(date: Date | null) => {
                if (date) {
                  onChangeDate('dateTo', date);
                }
              }}
            />
            <div className="flex gap-2 justify-end">
              <PrimaryButton
                type="button"
                label="Submit"
                style={{ height: '40px', maxWidth: '100px' }}
                onClick={() => onSubmit(filterObject)}
              />
              <CommonButton
                label="Reset"
                buttonType="secondary"
                style={{ height: '40px', maxWidth: '100px' }}
                onClick={() => {
                  setFilterObject({
                    dateFrom: initialFromDate,
                    dateTo: initialToDate,
                    providerId: null,
                    facilityId: null,
                    state: null,
                    visitStatusId: null,
                  });
                  onSubmit({
                    dateFrom: initialFromDate,
                    dateTo: initialToDate,
                    providerId: null,
                    facilityId: null,
                    state: null,
                    visitStatusId: null,
                  });
                }}
              />
              <PrimaryButton
                type="button"
                label="Export to Excel"
                icon={<FaFileExcel style={{ fontSize: '18px' }} />}
                style={{ height: '40px', maxWidth: '100px' }}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
