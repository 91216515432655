import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  FetchFinancialDashboardReportInterface,
  FetchFinancialDashboardReportInitialInterface,
} from '../../../interfaces/reportsInterfaces';
import { getFinancialDashboardReportAPIRequest } from '../../../api/reports/financialDashboardReport';

const initialState: FetchFinancialDashboardReportInitialInterface = {
  fetchFinancialDashboardReportLoading: false,
  fetchFinancialDashboardReportData: {
    totals: {},
    items: [],
  },
  fetchFinancialDashboardReportError: null,
  fetchFinancialDashboardReportStatus: 'IDLE',
};

export const getFinancialDashboardReportRequest: any = createAsyncThunk(
  'reports/financial-dashboard/get',
  async (reportData: FetchFinancialDashboardReportInterface, thunkAPI: any) => {
    try {
      const response: any = await getFinancialDashboardReportAPIRequest(reportData);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getFinancialDashboardReportSlice = createSlice({
  name: 'fetchFinancialDashboardReport',
  initialState,
  reducers: {
    clearFinancialDashboardReportResponse: (state) => {
      state.fetchFinancialDashboardReportLoading = false;
      state.fetchFinancialDashboardReportData = { totals: {}, items: [] };
      state.fetchFinancialDashboardReportStatus = 'IDLE';
      state.fetchFinancialDashboardReportError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFinancialDashboardReportRequest.pending, (state, action) => {
        state.fetchFinancialDashboardReportLoading = true;
        state.fetchFinancialDashboardReportStatus = 'PENDING';
      })
      .addCase(getFinancialDashboardReportRequest.fulfilled, (state, action) => {
        state.fetchFinancialDashboardReportLoading = false;
        state.fetchFinancialDashboardReportStatus = 'SUCCESS';
        state.fetchFinancialDashboardReportData = action.payload;
      })
      .addCase(getFinancialDashboardReportRequest.rejected, (state, action) => {
        state.fetchFinancialDashboardReportLoading = false;
        state.fetchFinancialDashboardReportStatus = 'FAILED';
        state.fetchFinancialDashboardReportError = action.payload;
      });
  },
});

export const { clearFinancialDashboardReportResponse } = getFinancialDashboardReportSlice.actions;

export const getFinancialDashboardReportSelector = (state: RootState) => state.GetFinancialDashboardReport;

export default getFinancialDashboardReportSlice.reducer;
