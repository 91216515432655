import React, { useState } from 'react';
import moment from 'moment';
import { BankReconciliationReportTableProps } from '../../interfaces/commonInterfaces';
import { formatCurrency } from '../../utils/commonFunctions';
import './tableContent.css';

export const BankReconciliationReportCustomTable = ({
  columns = [],
  dataSource = {
    items: [],
    totals: {},
  },
}: BankReconciliationReportTableProps) => {
  const [rowsExpanded, setRowsExpanded] = useState<boolean>(false);

  const toggleRowExpand = () => {
    setRowsExpanded((prev: any) => !prev);
  };

  return (
    <div className="table-content mb-8">
      <div className="table-content__table-view">
        <table className="table-content__table">
          <thead className="table-content__table-header">
            <tr>
              {columns?.map((column) => (
                <th key={column.key} style={{ width: column.width || 'auto' }}>
                  {column.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="table-content__table-body">
            {dataSource?.items?.map((record, recordIndex) => (
              <React.Fragment key={recordIndex}>
                <tr onClick={toggleRowExpand} className="reconciliation-report-summary-row">
                  <td className="flex flex-col items-start font-bold">
                    <button className="mr-2 text-2xl cursor-pointer">{rowsExpanded ? '-' : '+'}</button>
                    {moment(record?.date).isValid() ? moment(record?.date).format('MM/DD/YYYY') : ''}
                  </td>
                  {columns?.slice(1).map((column) => (
                    <td key={column.key}>
                      {' '}
                      {/* {column.render
                        ? column.render(record?.totals?.[column.dataIndex], record?.totals)
                        : record?.totals?.[column.dataIndex]} */}
                      {column.key === 'miscellaneousAmount'
                        ? formatCurrency(record?.totals?.miscellaneousAmount ?? 0)
                        : column.render
                        ? column.render(record?.totals?.[column.dataIndex], record?.totals)
                        : record?.totals?.[column.dataIndex]}
                    </td>
                  ))}
                </tr>

                {!rowsExpanded && (
                  <tr>
                    <td colSpan={columns.length} className="bg-gray-100"></td>
                  </tr>
                )}

                {rowsExpanded &&
                  record?.detailedData?.map((detail, detailIndex) => (
                    <tr key={detailIndex} className="reconciliation-report-detail-row">
                      {columns.map((column) => (
                        <td>
                          {column.render ? column.render(detail[column.dataIndex], detail) : detail[column.dataIndex]}
                        </td>
                      ))}
                    </tr>
                  ))}
              </React.Fragment>
            ))}

            <tr>
              <td className="font-bold">Total</td>
              {columns.slice(1).map((column) => (
                <td key={column.key}>
                  {column.key === 'miscellaneousAmount'
                    ? formatCurrency(dataSource.totals.miscellaneousAmount ?? 0)
                    : column.render
                    ? column.render(dataSource.totals[column.dataIndex], dataSource.totals)
                    : dataSource.totals[column.dataIndex]}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
