import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks/hooks';

import { MainHeader } from '../../components/mainHeader/MainHeader';
import { PageTopic } from '../../components/pageTopic/PageTopic';
import { TableContent } from '../../components/table/TableContent';
import { EmptyContent } from '../../components/emptyContent/EmptyContent';
import { CommonAlert } from '../../components/atoms/Alert';
import { Pagination } from '../../components/pagination/Pagination';
import { Spinner } from '../../components/atoms/Spinner';
import { ReimbursementReportAdvanceSearch } from '../../components/tableSearch/ReimbursementReportAdvanceSearch';

import {
  getReimbursementReportRequest,
  getReimbursementReportSelector,
  clearFetchReimbursementReportResponse,
} from '../../redux/slices/reports/getReimbursementReportSlice';
import {
  getSystemProvidersRequest,
  getSystemProvidersSelector,
} from '../../redux/slices/system/getSystemProvidersSlice';

import { formatCurrency } from '../../utils/commonFunctions';

const PAGE_SIZE = 10;

const columns = [
  {
    title: 'Provider',
    dataIndex: 'provider',
    key: 'provider',
  },
  {
    title: 'Provider Type',
    dataIndex: 'providerType',
    key: 'providerType',
  },
  {
    title: 'CPT Code',
    dataIndex: 'cptCode',
    key: 'cptCode',
  },
  {
    title: 'Reimbursement',
    dataIndex: 'reimbursement',
    key: 'reimbursement',
    render: (text: number) => formatCurrency(text ?? 0),
  },
];

const dummyDataSource = [
  {
    provider: 'William James',
    providerType: 'MO',
    cpt: '99782',
    reimbursement: 65232.1,
  },
  {
    provider: 'John Kyle',
    providerType: 'CMP',
    cpt: '99781',
    reimbursement: 3232,
  },
  {
    provider: 'Serene Kate',
    providerType: 'KO',
    cpt: '99784',
    reimbursement: 12232333,
  },
];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  {
    id: 'reimbursementReport',
    label: 'Reimbursement Report',
    status: 'active',
    link: 'reimbursement-report',
  },
];

const initialAlertState = {
  color: '',
  message: '',
  error: false,
};

export const ReimbursementReportPage = () => {
  const dispatch = useAppDispatch();

  const { fetchReimbursementReportData, fetchReimbursementReportStatus, fetchReimbursementReportLoading } =
    useAppSelector(getReimbursementReportSelector);
  const { systemProvidersProviderData } = useAppSelector(getSystemProvidersSelector);

  const [dataSource, setDataSource] = useState<any[]>([]);
  const [filterObject, setFilterObject] = useState<any>({
    dateFrom: null,
    dateTo: null,
    state: null,
    providerId: null,
  });
  const [searchTerm, setSearchTerm] = useState<string>('');

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>({
    color: '',
    message: '',
    error: false,
  });

  useEffect(() => {
    fetchSystemProviders();
    setVisibleAlert(false);
    setAlertObj(initialAlertState);
  }, []);

  useEffect(() => {
    if (filterObject) {
      fetchReimbursementReport(0, searchTerm);
    }
  }, [filterObject, searchTerm]);

  useEffect(() => {
    if (fetchReimbursementReportStatus === 'SUCCESS') {
      setDataSource(fetchReimbursementReportData?.items);
      setCurrentPage(fetchReimbursementReportData?.currentPage);
      setTotalPages(fetchReimbursementReportData?.totalPages);
      dispatch(clearFetchReimbursementReportResponse());
    } else if (fetchReimbursementReportStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj(initialAlertState);
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearFetchReimbursementReportResponse());
    }
  }, [fetchReimbursementReportStatus]);

  const onPageChange = (page: any) => {
    setCurrentPage(page - 1);
    fetchReimbursementReport(page - 1);
  };

  const fetchReimbursementReport = async (pageNumber = currentPage, searchValue = searchTerm) => {
    const updatedFilterObject = { page: pageNumber, size: PAGE_SIZE, search: searchValue, ...filterObject };
    dispatch(getReimbursementReportRequest({ filters: updatedFilterObject }));
  };

  const fetchSystemProviders = async () => {
    dispatch(getSystemProvidersRequest());
  };

  const handleSearch = (data: any) => {
    const updatedData = { ...data };

    updatedData.providerId = updatedData.providerId ? parseInt(updatedData.providerId, 10) : null;

    updatedData.state = updatedData.state || null;

    setCurrentPage(0);
    setFilterObject(updatedData);
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
    setAlertObj(initialAlertState);
  };

  return (
    <div className="main-content">
      <MainHeader />
      <PageTopic mainTitle="Reimbursement Report" enablePrimaryButton={false} breadCrumbArr={breadCrumbArr} />
      <ReimbursementReportAdvanceSearch onSubmit={handleSearch} />

      {visibleAlert && alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}

      <div className="flex justify-end mb-4">
        <div className="flex items-center">
          <label htmlFor="search" className="mr-2 text-gray-600">
            Search:
          </label>
          <input
            type="text"
            className="border border-gray-300 rounded px-2 py-1 focus:outline-0 focus:ring-primaryDefault focus:border-primaryDefault"
            name="search"
            title="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      {fetchReimbursementReportLoading ? (
        <Spinner />
      ) : (
        <>
          {dataSource?.length > 0 ? (
            <>
              <TableContent columns={columns} dataSource={dataSource} enableActions={false} />
              {totalPages > 1 && (
                <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
              )}
            </>
          ) : (
            <EmptyContent mode="VIEW" enableCreateButton={false} />
          )}
        </>
      )}
    </div>
  );
};
