import { useState } from 'react';

import moment from 'moment';

import { SelectInput } from '../atoms/SelectInput';
import { InputField } from '../atoms/InputField';
import { PrimaryButton } from '../atoms/PrimaryButton';
import { CommonButton } from '../atoms/CommonButton';
import { FaFileExcel } from 'react-icons/fa';

import { BankReconciliationReportTypes } from '../../constants/reportsConstants';

export const BankReconciliationReportHeader = ({ onSubmit, showInputField = false }: any) => {
  const currentYear = moment().year();
  const currentMonth = moment().format('MMM');

  const [filterObject, setFilterObject] = useState<any>({
    year: currentYear.toString(),
    month: currentMonth.toString(),
    batchType: null,
  });

  const yearOptions = Array.from({ length: currentYear - 2022 + 2 }, (_, i) => {
    const year = 2022 + i;
    return { id: year.toString(), name: year.toString() };
  });

  const monthOptions = moment.months().map((month) => ({
    id: moment(month, 'MMMM').format('MMM'),
    name: month,
  }));

  const onChangeFilter = (e: any) => {
    setFilterObject((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  return (
    <div className="table-content__adv-search">
      <form>
        <div className="table-content__adv-search-content">
          <div className="grid md:grid-cols-5 gap-4 items-end">
            <SelectInput
              options={yearOptions}
              enableDefaultPlaceholder={false}
              name="year"
              onChange={onChangeFilter}
              value={filterObject?.year ?? ''}
            />
            <SelectInput
              options={monthOptions}
              enableDefaultPlaceholder={true}
              defaultPlaceholder="All"
              name="month"
              onChange={onChangeFilter}
              value={filterObject?.month ?? ''}
            />

            <SelectInput
              options={BankReconciliationReportTypes}
              defaultPlaceholder="All"
              name="batchType"
              onChange={onChangeFilter}
              value={filterObject?.batchType ?? ''}
            />
            {showInputField && (
              <InputField
                name="date"
                placeholder="Search by Date"
                onChange={onChangeFilter}
                value={filterObject?.date ?? ''}
              />
            )}

            <div className="flex gap-2">
              <PrimaryButton
                type="button"
                label="Submit"
                style={{ height: '40px', maxWidth: '100px' }}
                onClick={() => onSubmit(filterObject)}
              />
              <CommonButton
                label="Reset"
                buttonType="secondary"
                style={{ height: '40px', maxWidth: '100px' }}
                onClick={() => {
                  setFilterObject({ year: currentYear.toString(), month: currentMonth.toString() });
                  onSubmit({ year: currentYear.toString(), month: currentMonth.toString() });
                }}
              />
              {/* <PrimaryButton
                type="button"
                label="Export to Excel"
                icon={<FaFileExcel style={{ fontSize: '18px' }} />}
                style={{ height: '40px', maxWidth: '100px' }}
              /> */}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
