import { authenticatedRequest } from '../../utils/commonAxios';
import { FetchReimbursementReportInterface } from '../../interfaces/reportsInterfaces';

import { queryParamGenerator } from '../../utils/commonFunctions';

export const getReimbursementReportAPIRequest = async (reportData: FetchReimbursementReportInterface) => {
  const queryParams = queryParamGenerator(reportData?.filters);

  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/report/reimbursement${queryParams}`,
    'get',
    {}
  );
};
