import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { FetchVisitReportInterface, FetchVisitReportInitialInterface } from '../../../interfaces/reportsInterfaces';
import { getVisitReportAPIRequest } from '../../../api/reports/visitReport';

const initialState: FetchVisitReportInitialInterface = {
  fetchVisitReportLoading: false,
  fetchVisitReportData: {
    currentPage: 0,
    items: [],
    totalItems: 0,
    totalPages: 0,
  },
  fetchVisitReportError: null,
  fetchVisitReportStatus: 'IDLE',
};

export const getVisitReportRequest: any = createAsyncThunk(
  'reports/visit/get',
  async (reportData: FetchVisitReportInterface, thunkAPI: any) => {
    try {
      const response: any = await getVisitReportAPIRequest(reportData);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getVisitReportSlice = createSlice({
  name: 'fetchVisitReport',
  initialState,
  reducers: {
    clearVisitReportResponse: (state) => {
      state.fetchVisitReportLoading = false;
      state.fetchVisitReportData = { currentPage: 0, items: [], totalItems: 0, totalPages: 0 };
      state.fetchVisitReportStatus = 'IDLE';
      state.fetchVisitReportError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVisitReportRequest.pending, (state, action) => {
        state.fetchVisitReportLoading = true;
        state.fetchVisitReportStatus = 'PENDING';
      })
      .addCase(getVisitReportRequest.fulfilled, (state, action) => {
        state.fetchVisitReportLoading = false;
        state.fetchVisitReportStatus = 'SUCCESS';
        state.fetchVisitReportData = action.payload;
      })
      .addCase(getVisitReportRequest.rejected, (state, action) => {
        state.fetchVisitReportLoading = false;
        state.fetchVisitReportStatus = 'FAILED';
        state.fetchVisitReportError = action.payload;
      });
  },
});

export const { clearVisitReportResponse } = getVisitReportSlice.actions;

export const getVisitReportSelector = (state: RootState) => state.GetVisitReport;

export default getVisitReportSlice.reducer;
