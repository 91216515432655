import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  AddReconciliationMiscPaymentInterface,
  AddReconciledMiscPaymentInitialInterface,
} from '../../../interfaces/reportsInterfaces';

const initialState: AddReconciledMiscPaymentInitialInterface = {
  addReconciledMiscPaymentLoading: false,
  addReconciledMiscPaymentData: {},
  addReconciledMiscPaymentError: null,
  addReconciledMiscPaymentStatus: 'IDLE',
};

export const addReconciledMiscPaymentRequest: any = createAsyncThunk(
  'reports/bank-reconciled/payment/add',
  async (payment: AddReconciliationMiscPaymentInterface, thunkAPI: any) => {
    try {
      //   const response: any = await addReconciledMiscPaymentAPIRequest(payment);
      //   return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const addReconciledMiscPaymentSlice = createSlice({
  name: 'addReconciledMiscPayment',
  initialState,
  reducers: {
    clearAddReconciledMiscPaymentResponse: (state) => {
      state.addReconciledMiscPaymentLoading = false;
      state.addReconciledMiscPaymentStatus = 'IDLE';
      state.addReconciledMiscPaymentData = {};
      state.addReconciledMiscPaymentError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addReconciledMiscPaymentRequest.pending, (state, action) => {
        state.addReconciledMiscPaymentLoading = true;
        state.addReconciledMiscPaymentStatus = 'PENDING';
      })
      .addCase(addReconciledMiscPaymentRequest.fulfilled, (state, action) => {
        state.addReconciledMiscPaymentData = action.payload;
        state.addReconciledMiscPaymentStatus = 'SUCCESS';
        state.addReconciledMiscPaymentLoading = false;
      })
      .addCase(addReconciledMiscPaymentRequest.rejected, (state, action) => {
        state.addReconciledMiscPaymentLoading = false;
        state.addReconciledMiscPaymentError = action.payload;
        state.addReconciledMiscPaymentStatus = 'FAILED';
      });
  },
});

export const { clearAddReconciledMiscPaymentResponse } = addReconciledMiscPaymentSlice.actions;

export const addReconciledMiscPaymentSelector = (state: RootState) => state.AddReconciledMiscPayment;

export default addReconciledMiscPaymentSlice.reducer;
