import { authenticatedRequest } from '../../utils/commonAxios';
import { FetchVisitCountReportInterface } from '../../interfaces/reportsInterfaces';

import { queryParamGenerator } from '../../utils/commonFunctions';

export const getVisitCountReportAPIRequest = async (reportData: FetchVisitCountReportInterface) => {
  const queryParams = queryParamGenerator(reportData?.filters);

  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/report/visit-count${queryParams}`, 'get', {});
};
