import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  FetchBankReconciliationInterface,
  FetchBankReconciliationInitialInterface,
} from '../../../interfaces/reportsInterfaces';
import { getBankReconciliationAPIRequest } from '../../../api/reports/payment';

const initialState: FetchBankReconciliationInitialInterface = {
  fetchBankReconciliationLoading: false,
  fetchBankReconciliationData: {
    currentPage: 0,
    items: [],
    totalItems: 0,
    totalPages: 0,
  },
  fetchBankReconciliationError: null,
  fetchBankReconciliationStatus: 'IDLE',
};

export const getBankReconciliationRequest: any = createAsyncThunk(
  'reports/bank-reconcilation/get',
  async (reportData: FetchBankReconciliationInterface, thunkAPI: any) => {
    try {
      const response: any = await getBankReconciliationAPIRequest(reportData);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getBankReconciliationSlice = createSlice({
  name: 'fetchBankReconciliation',
  initialState,
  reducers: {
    clearFetchBankReconciliationResponse: (state) => {
      state.fetchBankReconciliationLoading = false;
      state.fetchBankReconciliationData = { currentPage: 0, items: [], totalItems: 0, totalPages: 0 };
      state.fetchBankReconciliationStatus = 'IDLE';
      state.fetchBankReconciliationError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBankReconciliationRequest.pending, (state, action) => {
        state.fetchBankReconciliationLoading = true;
        state.fetchBankReconciliationStatus = 'PENDING';
      })
      .addCase(getBankReconciliationRequest.fulfilled, (state, action) => {
        state.fetchBankReconciliationLoading = false;
        state.fetchBankReconciliationStatus = 'SUCCESS';
        state.fetchBankReconciliationData = action.payload;
      })
      .addCase(getBankReconciliationRequest.rejected, (state, action) => {
        state.fetchBankReconciliationLoading = false;
        state.fetchBankReconciliationStatus = 'FAILED';
        state.fetchBankReconciliationError = action.payload;
      });
  },
});

export const { clearFetchBankReconciliationResponse } = getBankReconciliationSlice.actions;

export const getBankReconciliationSelector = (state: RootState) => state.GetBankReconciliation;

export default getBankReconciliationSlice.reducer;
