import {
  FetchBankReconciliationInterface,
  FetchBankReconciliationReportInterface,
  FetchBankReconcilationReconciledInterface,
  FetchCrossoverCarriersInterface,
  FetchDBRSnapshotInterface,
  FetchPaymentByPatientInterface,
  FetchReconciledMiscPaymentListInterface,
  AddReconciliationMiscPaymentInterface,
  UpdateBankReconciledBatchInterface,
  DeleteReconciledMiscPaymentInterface,
  UpdateBankToBeReconciledPaymentInterface,
  FetchToBeReconciledMiscPaymentListInterface,
  DeleteToBeReconciledMiscPaymentInterface,
  UpdateBankReconciledPaymentInterface,
} from '../../interfaces/reportsInterfaces';
import { authenticatedRequest } from '../../utils/commonAxios';
import { queryParamGenerator } from '../../utils/commonFunctions';

export const getBankReconciliationAPIRequest = async (reportData: FetchBankReconciliationInterface) => {
  const queryParams = queryParamGenerator(reportData?.filters);

  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/report/payment/to-be-reconciled${queryParams}`,
    'get',
    {}
  );
};

export const getBankReconciliationReconciledAPIRequest = async (
  reportData: FetchBankReconcilationReconciledInterface
) => {
  const queryParams = queryParamGenerator(reportData?.filters);

  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/report/payment/reconciled${queryParams}`,
    'get',
    {}
  );
};

export const getBankReconciliationReportAPIRequest = async (reportData: FetchBankReconciliationReportInterface) => {
  const queryParams = queryParamGenerator(reportData?.filters);

  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/report/payment/reconciliation${queryParams}`,
    'get',
    {}
  );
};

export const getDBRSnapshotAPIRequest = async (reportData: FetchDBRSnapshotInterface) => {
  const queryParams = queryParamGenerator(reportData?.filters);

  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/report/payment/reconciliation-snapshot${queryParams}`,
    'get',
    {}
  );
};

export const getCrossoverCarriersAPIRequest = async (reportData: FetchCrossoverCarriersInterface) => {
  const queryParams = queryParamGenerator(reportData?.filters);

  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/report/payment/crossover${queryParams}`,
    'get',
    {}
  );
};

export const getPaymentByPatientAPIRequest = async (reportData: FetchPaymentByPatientInterface) => {
  const queryParams = queryParamGenerator(reportData?.filters);

  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/report/patient-payment${queryParams}`,
    'get',
    {}
  );
};

export const getReconciledMiscPaymentsAPIRequest = async (payment: FetchReconciledMiscPaymentListInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/report/reconciled/batch/${payment?.batchId}/view-payments`,
    'get',
    {}
  );
};

export const addReconciledMiscPaymentAPIRequest = async (payment: AddReconciliationMiscPaymentInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/report/reconciled/batch/${payment?.id}/payment`,
    'post',
    {
      data: { ...payment?.paymentData },
    }
  );
};

export const updateBankReconciledBatchAPIRequest = async (data: UpdateBankReconciledBatchInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/report/reconciled/batch/${data?.batchId}`,
    'put',
    {
      data: { ...data?.batchData },
    }
  );
};

export const deleteReconciledMiscPaymentAPIRequest = async (payment: DeleteReconciledMiscPaymentInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/report/reconciled/batch/payment/${payment?.paymentId}`,
    'delete',
    {}
  );
};

export const updateBankToBeReconciledPaymentAPIRequest = async (payment: UpdateBankToBeReconciledPaymentInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/report/payment/${payment?.id}/to-be-reconciled`,
    'put',
    {
      data: { ...payment?.paymentData },
    }
  );
};

export const addBankToBeReconciledMiscPaymentAPIRequest = async (payment: AddReconciliationMiscPaymentInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/report/payment/${payment?.id}/miscellaneous-payment`,
    'post',
    {
      data: { ...payment?.paymentData },
    }
  );
};

export const getToBeReconciledMiscPaymentsAPIRequest = async (payment: FetchToBeReconciledMiscPaymentListInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/report/payment/${payment?.id}/miscellaneous-payment?batchPaymentType=${payment?.batchPaymentType}`,
    'get',
    {}
  );
};

export const deleteToBeReconciledMiscPaymentAPIRequest = async (payment: DeleteToBeReconciledMiscPaymentInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/report/payment/miscellaneous-payment/${payment?.id}`,
    'delete',
    {}
  );
};

export const updateBankReconciledPaymentAPIRequest = async (payment: UpdateBankReconciledPaymentInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/report/payment/${payment?.id}/reconciled`,
    'put',
    {
      data: { ...payment?.paymentData },
    }
  );
};
