import React from 'react';
import { BulkPaymentsTableProps } from '../../interfaces/commonInterfaces';

import './tableContent.css';

export const BulkPaymentsCustomTable: React.FC<BulkPaymentsTableProps> = ({
  columns = [],
  cases = [],
  insurances = [],
  enableActions = true,
  enableAdd = true,
  enableNote = true,
  onHandleAdd,
  onHandleNote,
}) => {
  return (
    <div className="table-content">
      <div className="table-content__table-view">
        <table className="table-content__table">
          <thead className="table-content__table-header">
            <tr>
              {columns?.map((column) => (
                <th
                  key={column.key}
                  // className={column.key === 'billingCode' ? 'w-64' : ''}
                  style={{ width: column.width, minWidth: column.width }}
                >
                  {column.title}
                </th>
              ))}
              {enableActions && (
                <th scope="col" className="text-right">
                  <span>Actions</span>
                </th>
              )}
            </tr>
          </thead>
          <tbody className="table-content__table-body">
            {cases?.map((caseItem, caseIndex) => (
              <React.Fragment key={caseIndex}>
                <tr>
                  <td colSpan={columns.length + 1}>
                    <div className="font-bold py-3">
                      <span className="text-customRed">Case ID:</span>{' '}
                      <span className="text-black mr-5">{caseItem?.caseId}</span>
                      {insurances.map((info: any, index: any) => (
                        <span key={index} className="text-customRed mr-5">
                          {info}
                        </span>
                      ))}
                    </div>
                  </td>
                </tr>

                {caseItem?.visitCptList?.length === 0 ? (
                  <tr>
                    <td colSpan={columns.length + 1} className="text-center">
                      No Visits / Visits with CPTs Available
                    </td>
                  </tr>
                ) : (
                  caseItem?.visitCptList?.map((row: any, rowIndex: number) => (
                    <tr key={rowIndex}>
                      {columns.map((column) => (
                        <td
                          key={column.key}
                          className={`${
                            column.render && String(column.render(row[column.dataIndex], row)).trim().startsWith('$')
                              ? 'text-right'
                              : ''
                          }`}
                        >
                          {column.render ? column.render(row[column.dataIndex], row) : row[column.dataIndex]}
                        </td>
                      ))}
                      {enableActions &&
                        (!row?.newRow ? (
                          <td className="table-content__table-action">
                            {enableAdd && (
                              <button
                                className="table-content__table-action-btn"
                                onClick={(event) => {
                                  onHandleAdd && onHandleAdd(event, rowIndex, caseIndex);
                                }}
                                disabled={row?.claim === 0}
                              >
                                <svg
                                  width="252px"
                                  height="252px"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  stroke="#6b7280"
                                  className="w-4 h-4"
                                >
                                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                  <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                  <g id="SVGRepo_iconCarrier">
                                    {' '}
                                    <circle
                                      opacity="0.5"
                                      cx="12"
                                      cy="12"
                                      r="10"
                                      stroke="#6b7280"
                                      stroke-width="1.5"
                                    ></circle>{' '}
                                    <path
                                      d="M15 12L12 12M12 12L9 12M12 12L12 9M12 12L12 15"
                                      stroke="#6b7280"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                    ></path>{' '}
                                  </g>
                                </svg>
                              </button>
                            )}
                            {enableNote && (
                              <button
                                className="table-content__table-action-btn"
                                onClick={(event) => {
                                  onHandleNote && onHandleNote(event, rowIndex, caseIndex);
                                }}
                                disabled={row?.claim === 0}
                              >
                                <svg
                                  width="64px"
                                  height="64px"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-4 h-4"
                                >
                                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                  <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                  <g id="SVGRepo_iconCarrier">
                                    <path
                                      d="M19 4H5C3.895 4 3 4.895 3 6V18C3 19.105 3.895 20 5 20H14C14.553 20 15.053 19.789 15.414 19.414L20 14.828C20.375 14.461 20.605 13.961 20.605 13.414V6C20.605 4.895 19.705 4 19 4Z"
                                      stroke="#6b7280"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M13 20V13H20"
                                      stroke="#6b7280"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </g>
                                </svg>
                              </button>
                            )}
                          </td>
                        ) : (
                          <td></td>
                        ))}
                    </tr>
                  ))
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
