import moment from 'moment';

import { Modal } from 'flowbite-react';
import { TableContent } from '../../table/TableContent';
import { CommonButton } from '../../atoms/CommonButton';
import { CommonAlert } from '../../atoms/Alert';

import { formatCurrency } from '../../../utils/commonFunctions';

const columns = [
  {
    title: 'Batch ID',
    dataIndex: 'batchId',
    key: 'batchId',
  },
  {
    title: 'Batch Type',
    dataIndex: 'batchType',
    key: 'batchType',
  },
  {
    title: 'Check Number',
    dataIndex: 'checkNumber',
    key: 'checkNumber',
  },
  {
    title: 'Miscellaneous Type',
    dataIndex: 'miscellaneousType',
    key: 'miscellaneousType',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    render: (text: number) => (text ? formatCurrency(text ?? 0) : ''),
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (text: string) => (moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : ''),
  },
];

const dummyDataSource = {
  items: [
    {
      batchId: 1232,
      batchType: 'LOCKBOX',
      checkNumber: 'Missing Eobs/ ERA',
      miscType: 'Missing Eobs/ ERA',
      amount: 12.23,
      createdAt: '01/23/2025',
    },
    {
      batchId: 1232,
      batchType: 'LOCKBOX',
      checkNumber: 'Missing Eobs/ ERA',
      miscType: 'Missing Eobs/ ERA',
      amount: 12.23,
      createdAt: '01/23/2025',
    },
    {
      batchId: 1232,
      batchType: 'LOCKBOX',
      checkNumber: 'Missing Eobs/ ERA',
      miscType: 'Missing Eobs/ ERA',
      amount: 12.23,
      createdAt: '01/23/2025',
    },
  ],
  total: 266.86,
};

export const ViewMiscPaymentsListModal = ({
  visibleViewMiscPaymentListModal,
  handleOnClose,
  paymentListData,
  deletePayment,
  visibleAlert,
  alertObj,
  handleAlertClose,
}: any) => {
  return (
    <Modal show={visibleViewMiscPaymentListModal} onClose={handleOnClose} size="4xl">
      <Modal.Header>View Miscellaneous Payment</Modal.Header>
      <Modal.Body>
        {visibleAlert && alertObj?.error && (
          <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
        )}

        {paymentListData && paymentListData?.items?.length > 0 ? (
          <>
            <TableContent
              columns={columns}
              dataSource={paymentListData?.items}
              enableActions={true}
              enableDelete={true}
              onDelete={deletePayment}
            />
            <div className="flex justify-center mt-4">
              <span className="font-semibold">Total: {formatCurrency(paymentListData?.total)}</span>
            </div>
          </>
        ) : (
          <div className="text-center my-2 text-gray6 font-semibold">No Payments Found!</div>
        )}
      </Modal.Body>
      {/* <Modal.Footer className="flex justify-end">
        <CommonButton
          type="button"
          label={'Close'}
          buttonType="secondary"
          style={{ width: '80px' }}
          onClick={handleOnClose}
        />
      </Modal.Footer> */}
    </Modal>
  );
};
