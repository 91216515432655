import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks/hooks';
import moment from 'moment';
import { debounce } from 'lodash';

import { MainHeader } from '../../../components/mainHeader/MainHeader';
import { PageTopic } from '../../../components/pageTopic/PageTopic';
import { TableContent } from '../../../components/table/TableContent';
import { Pagination } from '../../../components/pagination/Pagination';
import { EmptyContent } from '../../../components/emptyContent/EmptyContent';
import { CommonAlert } from '../../../components/atoms/Alert';
import { Spinner } from '../../../components/atoms/Spinner';
import { PaymentByPatientAdvanceSearch } from '../../../components/tableSearch/PaymentByPatientAdvanceSearch';

import {
  getPaymentByPatientRequest,
  getPaymentByPatientSelector,
  clearFetchPaymentByPatientResponse,
} from '../../../redux/slices/reports/getPaymentByPatientSlice';
import { getSystemProvidersRequest } from '../../../redux/slices/system/getSystemProvidersSlice';
import { getSystemFacilityRequest } from '../../../redux/slices/system/getSystemFacilitiesSlice';

import { formatCurrency, queryParamGenerator } from '../../../utils/commonFunctions';
import { handleExcelDownload } from '../../../utils/excelDownload';

const PAGE_SIZE = 25;

const columns = [
  {
    title: 'Patient ID',
    dataIndex: 'patientId',
    key: 'patientId',
  },
  {
    title: 'Patient Name',
    dataIndex: 'patientName',
    key: 'patientName',
    render: (text: any, record: any) => `${record?.pfirstName || ''} ${record?.plastName || ''}`.trim(),
  },
  {
    title: 'Insurance Name',
    dataIndex: 'insuranceName',
    key: 'insuranceName',
  },
  {
    title: 'Visit Date',
    dataIndex: 'visitDate',
    key: 'visitDate',
    render: (text: string) => (text ? (moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : '') : ''),
  },
  {
    title: 'CPT Code',
    dataIndex: 'cpt',
    key: 'cpt',
  },
  {
    title: 'Units',
    dataIndex: 'unit',
    key: 'unit',
    render: (text: any) => <span className="text-center block">{text}</span>,
  },
  {
    title: 'Charge',
    dataIndex: 'charge',
    key: 'charge',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Payment',
    dataIndex: 'payment',
    key: 'payment',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Adjustment',
    dataIndex: 'adjustment',
    key: 'adjustment',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    key: 'balance',
    render: (text: number) => formatCurrency(text ?? 0),
  },
];

const dummyDataSource = [
  {
    key: '1',
    patientId: '152',
    patientName: 'Anthony Cancel',
    insuranceName: 'NY Medicare Part B Downstate',
    visitDate: '07/08/2024',
    cpt: '99490',
    units: 1,
    charge: 102.0,
    payment: 55.47,
    adjustment: 32.38,
    balance: 14.15,
  },
  {
    key: '2',
    patientId: '1215',
    patientName: 'Frances Smith',
    insuranceName: 'NY Medicare Part B Downstate',
    visitDate: '07/31/2024',
    cpt: '99490',
    units: 1,
    charge: 102.0,
    payment: 69.62,
    adjustment: 32.38,
    balance: 0.0,
  },
  {
    key: '3',
    patientId: '5543',
    patientName: 'Henry Beukelaer',
    insuranceName: 'NY Medicare Part B Downstate',
    visitDate: '07/12/2024',
    cpt: '99490',
    units: 1,
    charge: 102.0,
    payment: 55.47,
    adjustment: 32.38,
    balance: 14.15,
  },
  {
    key: '4',
    patientId: '3218',
    patientName: 'Robert Aitken',
    insuranceName: 'NY Medicare Part B Downstate',
    visitDate: '07/31/2024',
    cpt: '99490',
    units: 1,
    charge: 102.0,
    payment: 69.62,
    adjustment: 32.38,
    balance: 0.0,
  },
];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  { id: 'patient-payment', label: 'Payment by Patient', status: 'active', link: 'patient-payment' },
];

const initialAlertState = {
  color: '',
  message: '',
  error: false,
};

export const PaymentByPatientReportPage = () => {
  const dispatch = useAppDispatch();

  const { fetchPaymentByPatientStatus, fetchPaymentByPatientData, fetchPaymentByPatientLoading } =
    useAppSelector(getPaymentByPatientSelector);

  const initialFromDate = moment().startOf('month').format('YYYY-MM-DD');
  const initialToDate = moment().endOf('month').format('YYYY-MM-DD');

  const [dataSource, setDataSource] = useState<any[]>([]);
  const [filterObject, setFilterObject] = useState<any>({
    dateFrom: initialFromDate,
    dateTo: initialToDate,
    filterType: 'DATE_OF_SERVICE',
    providerId: null,
    facilityId: null,
    state: null,
  });
  const [searchTerm, setSearchTerm] = useState<string>('');

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>(initialAlertState);
  const [excelLoading, setExcelLoading] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchSystemProviders();
    fetchSystemFacilities();
    setVisibleAlert(false);
    setAlertObj(initialAlertState);
  }, []);

  useEffect(() => {
    // if (filterObject) {
    //   fetchPaymentByPatientReport(0, searchTerm);
    // }

    const debouncedFetch = debounce((searchTerm) => {
      if (filterObject) {
        fetchPaymentByPatientReport(0, searchTerm);
      }
    }, 550);

    setFetchLoading(true);
    debouncedFetch(searchTerm);

    return () => {
      debouncedFetch.cancel();
    };
  }, [filterObject, searchTerm]);

  useEffect(() => {
    if (fetchPaymentByPatientStatus === 'SUCCESS') {
      setDataSource(fetchPaymentByPatientData?.items);
      setCurrentPage(fetchPaymentByPatientData?.currentPage);
      setTotalPages(fetchPaymentByPatientData?.totalPages);
      setFetchLoading(false);
      dispatch(clearFetchPaymentByPatientResponse());
    } else if (fetchPaymentByPatientStatus === 'FAILED') {
      setFetchLoading(false);
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj(initialAlertState);
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearFetchPaymentByPatientResponse());
    }
  }, [fetchPaymentByPatientStatus]);

  const onPageChange = (page: any) => {
    setCurrentPage(page - 1);
    setFetchLoading(true);
    fetchPaymentByPatientReport(page - 1);
  };

  const fetchPaymentByPatientReport = (pageNumber = currentPage, searchValue = searchTerm) => {
    const updatedFilterObject = { page: pageNumber, size: PAGE_SIZE, search: searchValue, ...filterObject };
    dispatch(getPaymentByPatientRequest({ filters: updatedFilterObject }));
  };

  const fetchSystemProviders = () => {
    dispatch(getSystemProvidersRequest());
  };

  const fetchSystemFacilities = () => {
    dispatch(getSystemFacilityRequest());
  };

  const handleDownloadPaymentByPatientExcel = (filters: any) => {
    setExcelLoading(true);

    const updatedFilters = {
      ...filters,
      search: searchTerm,
    };

    const queryParams = queryParamGenerator(updatedFilters);
    const apiUrl = `${process.env.REACT_APP_API_URL}/v1/report/patient-payment-excel-download${queryParams}`;

    handleExcelDownload({
      url: apiUrl,
      fileName: `Payment by Patient as of ${moment().format('MMMM DD YYYY, hh-mm A')}.xlsx`,
      isBlob: true,
      onSuccess: () => {
        setExcelLoading(false);
      },
      onError: () => {
        setExcelLoading(false);

        setAlertObj({
          color: 'failure',
          message: 'Something went wrong when fetching report!',
          error: true,
        });
        setVisibleAlert(true);
        setTimeout(() => {
          setVisibleAlert(false);
          setAlertObj({
            color: '',
            message: '',
            error: false,
          });
        }, 3000);
      },
    });
  };

  const handleSearch = (data: any) => {
    const updatedData = { ...data };

    updatedData.providerId = updatedData.providerId ? parseInt(updatedData.providerId, 10) : null;

    updatedData.facilityId = updatedData.facilityId ? parseInt(updatedData.facilityId, 10) : null;

    updatedData.state = updatedData.state || null;

    setCurrentPage(0);
    setFilterObject(updatedData);
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
    setAlertObj(initialAlertState);
  };

  return (
    <div className="main-content">
      <MainHeader />
      <PageTopic mainTitle="Payment by Patient" enablePrimaryButton={false} breadCrumbArr={breadCrumbArr} />

      <PaymentByPatientAdvanceSearch
        onSubmit={handleSearch}
        onExport={handleDownloadPaymentByPatientExcel}
        excelLoading={excelLoading}
      />

      {visibleAlert && alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}

      <div className="flex justify-end mb-4">
        <div className="flex items-center">
          <label htmlFor="search" className="mr-2 text-gray-600">
            Search:
          </label>
          <input
            type="text"
            className="border border-gray-300 rounded px-2 py-1 focus:outline-0 focus:ring-primaryDefault focus:border-primaryDefault"
            name="search"
            title="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      {fetchLoading ? (
        <Spinner />
      ) : (
        <>
          {dataSource && dataSource?.length > 0 ? (
            <>
              <div className={`overflow-x-auto ${totalPages > 1 ? '' : 'pb-7'}`}>
                <TableContent enableActions={false} columns={columns} dataSource={dataSource} isReport />
              </div>
              {totalPages > 1 && (
                <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
              )}
            </>
          ) : (
            <EmptyContent enableCreateButton={false} mode="VIEW" />
          )}
        </>
      )}
    </div>
  );
};
