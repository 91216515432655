import { useEffect, useState, useRef } from 'react';

import { Modal } from 'flowbite-react';
import { InputField } from '../../../atoms/InputField';
import { CommonButton } from '../../../atoms/CommonButton';
import { CommonAlert } from '../../../atoms/Alert';

export const AddDescriptionModal = ({
  addDescriptionModalOpen,
  onCloseModal,
  onSubmit,
  visibleAlert,
  alertObj,
  handleAlertClose,
  value,
}: any) => {
  const [description, setDescription] = useState<string>(value || '');
  const [isSubmitEnabled, setIsSubmitEnabled] = useState<boolean>(true);

  const mode = useRef<string>(value ? 'Update' : 'Add');

  useEffect(() => {
    setDescription(value || '');
    setIsSubmitEnabled(mode.current === 'Update' || value?.trim() !== '');
  }, [value]);

  const handleSearchInputChange = (e: any) => {
    const value = e.target.value;

    setDescription(value);
    setIsSubmitEnabled(mode.current === 'Update' || value.trim() !== '');
  };

  return (
    <Modal show={addDescriptionModalOpen} onClose={onCloseModal}>
      <Modal.Header>{mode.current} Description</Modal.Header>
      <Modal.Body>
        {visibleAlert && !alertObj?.error && (
          <CommonAlert
            color={alertObj?.color}
            message={alertObj?.message}
            onClose={handleAlertClose}
            alertClassName="w-1/2"
          />
        )}
        <InputField
          enableLabel={false}
          placeholder="Enter Description"
          name="description"
          onChange={handleSearchInputChange}
          value={description}
        />
      </Modal.Body>
      <Modal.Footer className="grid md:grid-cols-2">
        <div className="col-span-1"></div>
        <div className="col-span-1 grid md:grid-cols-2 gap-2">
          <CommonButton
            type="button"
            label={'Close'}
            buttonType="secondary"
            onClick={() => {
              setDescription('');
              onCloseModal();
            }}
          />
          <CommonButton
            type="button"
            label={mode.current}
            buttonType="primary"
            onClick={() => onSubmit(description)}
            disabled={!isSubmitEnabled}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};
