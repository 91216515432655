import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  FetchBankReconcilationReconciledInterface,
  FetchBankReconcilationReconciledInitialInterface,
} from '../../../interfaces/reportsInterfaces';
import { getBankReconciliationReconciledAPIRequest } from '../../../api/reports/payment';

const initialState: FetchBankReconcilationReconciledInitialInterface = {
  fetchBankReconcilationReconciledLoading: false,
  fetchBankReconcilationReconciledData: {
    currentPage: 0,
    items: [],
    totalItems: 0,
    totalPages: 0,
  },
  fetchBankReconcilationReconciledError: null,
  fetchBankReconcilationReconciledStatus: 'IDLE',
};

export const getBankReconciliationReconciledRequest: any = createAsyncThunk(
  'reports/bank-reconcilation-reconciled/get',
  async (reportData: FetchBankReconcilationReconciledInterface, thunkAPI: any) => {
    try {
      const response: any = await getBankReconciliationReconciledAPIRequest(reportData);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getBankReconcilationReconciledSlice = createSlice({
  name: 'fetchBankReconcilationReconciled',
  initialState,
  reducers: {
    clearFetchBankReconcilationReconciledResponse: (state) => {
      state.fetchBankReconcilationReconciledLoading = false;
      state.fetchBankReconcilationReconciledData = { currentPage: 0, items: [], totalItems: 0, totalPages: 0 };
      state.fetchBankReconcilationReconciledStatus = 'IDLE';
      state.fetchBankReconcilationReconciledError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBankReconciliationReconciledRequest.pending, (state, action) => {
        state.fetchBankReconcilationReconciledLoading = true;
        state.fetchBankReconcilationReconciledStatus = 'PENDING';
      })
      .addCase(getBankReconciliationReconciledRequest.fulfilled, (state, action) => {
        state.fetchBankReconcilationReconciledLoading = false;
        state.fetchBankReconcilationReconciledStatus = 'SUCCESS';
        state.fetchBankReconcilationReconciledData = action.payload;
      })
      .addCase(getBankReconciliationReconciledRequest.rejected, (state, action) => {
        state.fetchBankReconcilationReconciledLoading = false;
        state.fetchBankReconcilationReconciledStatus = 'FAILED';
        state.fetchBankReconcilationReconciledError = action.payload;
      });
  },
});

export const { clearFetchBankReconcilationReconciledResponse } = getBankReconcilationReconciledSlice.actions;

export const getBankReconcilationReconciledSelector = (state: RootState) => state.GetBankReconciliationReconciled;

export default getBankReconcilationReconciledSlice.reducer;
