import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks/hooks';

import { MainHeader } from '../../components/mainHeader/MainHeader';
import { PageTopic } from '../../components/pageTopic/PageTopic';
import { TableContent } from '../../components/table/TableContent';
import { EmptyContent } from '../../components/emptyContent/EmptyContent';
import { CommonAlert } from '../../components/atoms/Alert';
import { Pagination } from '../../components/pagination/Pagination';
import { Spinner } from '../../components/atoms/Spinner';
import { BilledAndUnbilledAdvanceSearch } from '../../components/tableSearch/BilledAndUnbilledAdvanceSearch';

import {
  getBilledAndUnbilledReportRequest,
  getBilledAndUnbilledReportSelector,
  clearBilledAndUnbilledReportResponse,
} from '../../redux/slices/reports/getBilledAndUnbilledReportSlice';
import { getSystemProvidersRequest } from '../../redux/slices/system/getSystemProvidersSlice';
import { getSystemFacilityRequest } from '../../redux/slices/system/getSystemFacilitiesSlice';

import { formatCurrency } from '../../utils/commonFunctions';

const columns = [
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: '0 - 30 Days',
    dataIndex: 'past1To30',
    key: 'past1To30',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: '31 - 60 Days',
    dataIndex: 'past31To60',
    key: 'past31To60',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: '61 - 90 Days',
    dataIndex: 'past61To90',
    key: 'past61To90',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: '91 - 120 Days',
    dataIndex: 'past91To120',
    key: 'past91To120',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: '>120 Days',
    dataIndex: 'pastAbove120',
    key: 'pastAbove120',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    key: 'balance',
    render: (text: number) => formatCurrency(text ?? 0),
  },
];

const dummyDataSource = [
  {
    status: 'Primary',
    past1To30: 110262.0,
    past31To60: 68170.86,
    past61To90: 8588.73,
    past91To120: 5643.45,
    pastAbove120: 6113.1,
    balance: 198778.14,
  },
  {
    status: 'Secondary',
    past1To30: 1522.23,
    past31To60: 0.0,
    past61To90: 0.0,
    past91To120: 0.0,
    pastAbove120: 0.0,
    balance: 1522.23,
  },
  {
    status: 'Crossover Carrier',
    past1To30: 4254.48,
    past31To60: 405.69,
    past61To90: 528.97,
    past91To120: 473.49,
    pastAbove120: 177.79,
    balance: 5840.42,
  },
  {
    status: 'Patient',
    past1To30: 23898.9,
    past31To60: 23597.59,
    past61To90: 18599.24,
    past91To120: 18510.44,
    pastAbove120: 26090.13,
    balance: 110696.3,
  },
  {
    status: 'Visit - Pending Touched',
    past1To30: 884.0,
    past31To60: 510.0,
    past61To90: 102.0,
    past91To120: 0.0,
    pastAbove120: 0.0,
    balance: 1496.0,
  },
  {
    status: 'Visit - Cancelled',
    past1To30: 272.0,
    past31To60: 442.0,
    past61To90: 1020.0,
    past91To120: 170.0,
    pastAbove120: 408.0,
    balance: 2312.0,
  },
  {
    status: 'Visit - On Hold',
    past1To30: 12410.0,
    past31To60: 8194.0,
    past61To90: 11560.0,
    past91To120: 13124.0,
    pastAbove120: 8466.0,
    balance: 53754.0,
  },
  {
    status: 'Visit - Reviewed',
    past1To30: 52734.0,
    past31To60: 0.0,
    past61To90: 0.0,
    past91To120: 0.0,
    pastAbove120: 0.0,
    balance: 52734.0,
  },
  {
    status: 'Visit - Issues Pending',
    past1To30: 0.0,
    past31To60: 0.0,
    past61To90: 170.0,
    past91To120: 238.0,
    pastAbove120: 340.0,
    balance: 748.0,
  },
  {
    status: 'Visit - Alert',
    past1To30: 748.0,
    past31To60: 0.0,
    past61To90: 0.0,
    past91To120: 0.0,
    pastAbove120: 0.0,
    balance: 748.0,
  },
  {
    status: 'Total',
    past1To30: 206985.61,
    past31To60: 101320.14,
    past61To90: 40568.94,
    past91To120: 38159.38,
    pastAbove120: 41595.02,
    balance: 428629.09,
  },
];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  {
    id: 'billedAndUnbilledReport',
    label: 'Billed and Unbilled',
    status: 'active',
    link: 'billed-unbilled-report',
  },
];

const initialAlertState = {
  color: '',
  message: '',
  error: false,
};

export const BilledAndUnbilledReportPage = () => {
  const dispatch = useAppDispatch();

  const { fetchBilledAndUnbilledReportData, fetchBilledAndUnbilledReportStatus, fetchBilledAndUnbilledReportLoading } =
    useAppSelector(getBilledAndUnbilledReportSelector);

  const [dataSource, setDataSource] = useState<any[]>([]);
  const [filterObject, setFilterObject] = useState<any>({
    providerId: null,
    facilityId: null,
    state: null,
  });

  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>(initialAlertState);

  useEffect(() => {
    fetchSystemProviders();
    fetchSystemFacilities();
    setVisibleAlert(false);
    setAlertObj(initialAlertState);
  }, []);

  useEffect(() => {
    if (filterObject) {
      fetchBilledAndUnbilledReport();
    }
  }, [filterObject]);

  useEffect(() => {
    if (fetchBilledAndUnbilledReportStatus === 'SUCCESS') {
      const updatedDataSource = [
        ...(fetchBilledAndUnbilledReportData?.items || []),
        { ...(fetchBilledAndUnbilledReportData?.totals || {}), status: 'Total' },
      ];
      setDataSource(updatedDataSource);
      dispatch(clearBilledAndUnbilledReportResponse());
    } else if (fetchBilledAndUnbilledReportStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj(initialAlertState);
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearBilledAndUnbilledReportResponse());
    }
  }, [fetchBilledAndUnbilledReportStatus]);

  const fetchBilledAndUnbilledReport = () => {
    dispatch(getBilledAndUnbilledReportRequest({ filters: filterObject }));
  };

  const fetchSystemProviders = () => {
    dispatch(getSystemProvidersRequest());
  };

  const fetchSystemFacilities = () => {
    dispatch(getSystemFacilityRequest());
  };

  const handleSearch = (data: any) => {
    const updatedData = { ...data };

    updatedData.providerId = updatedData.providerId ? parseInt(updatedData.providerId, 10) : null;

    updatedData.facilityId = updatedData.facilityId ? parseInt(updatedData.facilityId, 10) : null;

    updatedData.state = updatedData.state || null;

    setFilterObject(updatedData);
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
    setAlertObj(initialAlertState);
  };

  return (
    <div className="main-content">
      <MainHeader />
      <PageTopic mainTitle="Billed and Unbilled" enablePrimaryButton={false} breadCrumbArr={breadCrumbArr} />

      <BilledAndUnbilledAdvanceSearch onSubmit={handleSearch} />

      {visibleAlert && alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}

      {fetchBilledAndUnbilledReportLoading ? (
        <Spinner />
      ) : (
        <>
          {dataSource && dataSource?.length > 0 ? (
            <div className="pb-7">
              <TableContent
                columns={columns}
                dataSource={dataSource}
                enableActions={false}
                enableLastRowStyles={true}
                lastRowStyles="font-bold"
              />
            </div>
          ) : (
            <EmptyContent mode="VIEW" enableCreateButton={false} />
          )}
        </>
      )}
    </div>
  );
};
