import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks/hooks';

import { MainHeader } from '../../components/mainHeader/MainHeader';
import { PageTopic } from '../../components/pageTopic/PageTopic';
import { Pagination } from '../../components/pagination/Pagination';
import { TableContent } from '../../components/table/TableContent';
import { PrimaryButton } from '../../components/atoms/PrimaryButton';
import { EmptyContent } from '../../components/emptyContent/EmptyContent';
import { CommonAlert } from '../../components/atoms/Alert';
import { Spinner } from '../../components/atoms/Spinner';

import { FaFileExcel } from 'react-icons/fa';

import {
  clearFetchCrossoverAgingReportResponse,
  getCrossoverAgingReportRequest,
  getCrossoverAgingReportSelector,
} from '../../redux/slices/reports/getCrossoverAgingSlice';
import {
  getCrossoverAgingSummaryRequest,
  getCrossoverAgingSummarySelector,
  clearFetchCrossoverAgingSummaryResponse,
} from '../../redux/slices/reports/getCrossoverAgingSummarySlice';

import { formatCurrency } from '../../utils/commonFunctions';

const PAGE_SIZE = 20;

const columns = (navigateToDetailPage: any) => [
  {
    title: 'Crossover Carrier',
    dataIndex: 'crossOverCarrier',
    key: 'crossOverCarrier',
  },
  {
    title: 'Primary Payer',
    dataIndex: 'primaryPayer',
    key: 'primaryPayer',
  },
  {
    title: 'Current',
    dataIndex: 'current',
    key: 'current',
    render: (text: any, record: any) => (
      <a
        className="no-underline text-linkBlue cursor-pointer"
        onClick={() => navigateToDetailPage(1, 1, record?.crossOverCarrier, record?.primaryPayer)}
      >
        {formatCurrency(text ?? 0)}
      </a>
    ),
  },
  {
    title: 'Past 1 - 30',
    dataIndex: 'past1To30',
    key: 'past1To30',
    render: (text: any, record: any) => (
      <a
        className="no-underline text-linkBlue cursor-pointer"
        onClick={() => navigateToDetailPage(1, 2, record?.crossOverCarrier, record?.primaryPayer)}
      >
        {formatCurrency(text ?? 0)}
      </a>
    ),
  },
  {
    title: 'Past 31 - 60',
    dataIndex: 'past31To60',
    key: 'past31To60',
    render: (text: any, record: any) => (
      <a
        className="no-underline text-linkBlue cursor-pointer"
        onClick={() => navigateToDetailPage(1, 3, record?.crossOverCarrier, record?.primaryPayer)}
      >
        {formatCurrency(text ?? 0)}
      </a>
    ),
  },
  {
    title: 'Past 61 - 90',
    dataIndex: 'past61To90',
    key: 'past61To90',
    render: (text: any, record: any) => (
      <a
        className="no-underline text-linkBlue cursor-pointer"
        onClick={() => navigateToDetailPage(1, 4, record?.crossOverCarrier, record?.primaryPayer)}
      >
        {formatCurrency(text ?? 0)}
      </a>
    ),
  },
  {
    title: 'Past 91 - 120',
    dataIndex: 'past91To120',
    key: 'past91To120',
    render: (text: any, record: any) => (
      <a
        className="no-underline text-linkBlue cursor-pointer"
        onClick={() => navigateToDetailPage(1, 5, record?.crossOverCarrier, record?.primaryPayer)}
      >
        {formatCurrency(text ?? 0)}
      </a>
    ),
  },
  {
    title: 'Past  > 120',
    dataIndex: 'pastAbove120',
    key: 'pastAbove120',
    render: (text: any, record: any) => (
      <a
        className="no-underline text-linkBlue cursor-pointer"
        onClick={() => navigateToDetailPage(1, 6, record?.crossOverCarrier, record?.primaryPayer)}
      >
        {formatCurrency(text ?? 0)}
      </a>
    ),
  },
  {
    title: 'Total Balance',
    dataIndex: 'totalBalance',
    key: 'totalBalance',
    render: (text: any, record: any) => (
      <a
        className="no-underline text-linkBlue cursor-pointer"
        onClick={() => navigateToDetailPage(1, 7, record?.crossOverCarrier, record?.primaryPayer)}
      >
        {formatCurrency(text ?? 0)}
      </a>
    ),
  },
];

const summaryColumns = [
  {
    title: 'Current',
    dataIndex: 'current',
    key: 'current',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Past 1 - 30',
    dataIndex: 'past1To30',
    key: 'past1To30',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Past 31 - 60',
    dataIndex: 'past31To60',
    key: 'past31To60',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Past 61 - 90',
    dataIndex: 'past61To90',
    key: 'past61To90',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Past 91 - 120',
    dataIndex: 'past91To120',
    key: 'past91To120',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Past  > 120',
    dataIndex: 'pastAbove120',
    key: 'pastAbove120',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Total Balance',
    dataIndex: 'totalBalance',
    key: 'totalBalance',
    render: (text: number) => formatCurrency(text ?? 0),
  },
];

const dataSource = [
  {
    crossOverCarrier: 'ADC 1 WELFARE FUND',
    primaryPayer: 'IL Medicare Part B',
    current: '$109.00',
    past1: '$0.00	',
    past2: '$0.00',
    past3: '$0.00	',
    past4: '$109.00',
    past5: '$0.00',
    totalBalance: '$0.00',
  },
];

const summaryDataSource = [
  {
    current: '$109.00',
    past1: '$0.00	',
    past2: '$0.00',
    past3: '$0.00	',
    past4: '$109.00',
    past5: '$0.00',
    totalBalance: '$0.00',
  },
];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  { id: 'crossoverReport', label: 'Crossover Carrier Aging', status: 'active', link: 'crossover-report' },
];

const initialAlertState = {
  color: '',
  message: '',
  error: false,
};

export const CrossoverCarrierAgingReportPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { fetchCrossoverAgingReportData, fetchCrossoverAgingReportStatus, fetchCrossoverAgingReportLoading } =
    useAppSelector(getCrossoverAgingReportSelector);
  const { fetchCrossoverAgingSummaryStatus, fetchCrossoverAgingSummaryData } = useAppSelector(
    getCrossoverAgingSummarySelector
  );

  const [dataSource, setDataSource] = useState<any>([]);
  const [summaryDataSource, setSummaryDataSource] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>(initialAlertState);

  useEffect(() => {
    fetchCrossoverAgingSummary();
    setVisibleAlert(false);
    setAlertObj(initialAlertState);
  }, []);

  useEffect(() => {
    fetchCrossoverAgingData(0, searchTerm);
  }, [searchTerm]);

  useEffect(() => {
    if (fetchCrossoverAgingReportStatus === 'SUCCESS') {
      setDataSource(fetchCrossoverAgingReportData?.items);
      setCurrentPage(fetchCrossoverAgingReportData?.currentPage);
      setTotalPages(fetchCrossoverAgingReportData?.totalPages);
      dispatch(clearFetchCrossoverAgingReportResponse());
    } else if (fetchCrossoverAgingReportStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj(initialAlertState);
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearFetchCrossoverAgingReportResponse());
    }
  }, [fetchCrossoverAgingReportStatus]);

  useEffect(() => {
    if (fetchCrossoverAgingSummaryStatus === 'SUCCESS') {
      setSummaryDataSource([fetchCrossoverAgingSummaryData]);
      dispatch(clearFetchCrossoverAgingSummaryResponse());
    } else if (fetchCrossoverAgingSummaryStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong when fetching report summary!',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj(initialAlertState);
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearFetchCrossoverAgingSummaryResponse());
    }
  }, [fetchCrossoverAgingSummaryStatus]);

  const onPageChange = (page: any) => {
    setCurrentPage(page - 1);
    fetchCrossoverAgingData(page - 1);
  };

  const fetchCrossoverAgingData = (pageNumber = currentPage, searchValue = searchTerm) => {
    dispatch(
      getCrossoverAgingReportRequest({ size: PAGE_SIZE, page: pageNumber, search: encodeURIComponent(searchValue) })
    );
  };

  const fetchCrossoverAgingSummary = () => {
    dispatch(getCrossoverAgingSummaryRequest());
  };

  const navigateToDetailPage = (
    insuranceId: number,
    reportId: number,
    crossoverCarrier: string,
    primaryPayer: string
  ) => {
    navigate(`/crossover-carrier-report-sub/${insuranceId}/${reportId}`, {
      state: { crossoverCarrier, primaryPayer },
    });
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
    setAlertObj(initialAlertState);
  };

  return (
    <div className="main-content">
      <MainHeader />
      <PageTopic mainTitle="Crossover Carrier Aging" enablePrimaryButton={false} breadCrumbArr={breadCrumbArr} />

      {visibleAlert && alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}

      <div className="flex justify-end mb-4">
        <div className="flex items-center">
          <label htmlFor="search" className="mr-2 text-gray-600">
            Search:
          </label>
          <input
            type="text"
            className="border border-gray-300 rounded px-2 py-1 mr-2 focus:outline-0 focus:ring-primaryDefault focus:border-primaryDefault"
            name="search"
            title="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <PrimaryButton
            type="button"
            label="Export to Excel"
            icon={<FaFileExcel style={{ fontSize: '18px' }} />}
            style={{ height: '38px', maxWidth: '100px' }}
          />
        </div>
      </div>

      {fetchCrossoverAgingReportLoading ? (
        <Spinner />
      ) : (
        <>
          {dataSource && dataSource?.length > 0 ? (
            <>
              <TableContent enableActions={false} columns={columns(navigateToDetailPage)} dataSource={dataSource} />

              {totalPages > 1 && (
                <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
              )}

              <TableContent
                enableActions={false}
                columns={summaryColumns}
                dataSource={summaryDataSource}
                lastRowStyles="font-bold"
                enableLastRowStyles={true}
              />
            </>
          ) : (
            <EmptyContent enableCreateButton={false} mode="VIEW" />
          )}
        </>
      )}
    </div>
  );
};
