import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks/hooks';
import moment from 'moment';

import { MainHeader } from '../../components/mainHeader/MainHeader';
import { PageTopic } from '../../components/pageTopic/PageTopic';
import { TableContent } from '../../components/table/TableContent';
import { EmptyContent } from '../../components/emptyContent/EmptyContent';
import { CommonAlert } from '../../components/atoms/Alert';
import { Pagination } from '../../components/pagination/Pagination';
import { Spinner } from '../../components/atoms/Spinner';
import { VisitReportAdvanceSearch } from '../../components/tableSearch/VisitReportAdvanceSearch';

import {
  getVisitReportRequest,
  getVisitReportSelector,
  clearVisitReportResponse,
} from '../../redux/slices/reports/getVisitReportSlice';
import { getSystemProvidersRequest } from '../../redux/slices/system/getSystemProvidersSlice';
import { getSystemFacilityRequest } from '../../redux/slices/system/getSystemFacilitiesSlice';
import {
  getSystemVisitStatusRequest,
  getSystemVisitStatusSelector,
} from '../../redux/slices/system/getSystemVisitStatusSlice';

import { formatCurrency } from '../../utils/commonFunctions';

const PAGE_SIZE = 10;

const columns = [
  {
    title: 'Visit ID',
    dataIndex: 'visitId',
    key: 'visitId',
  },
  {
    title: 'Visit Date',
    dataIndex: 'visitDate',
    key: 'visitDate',
    render: (text: string) => {
      return moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : '';
    },
  },
  {
    title: 'Patient Name',
    dataIndex: 'patient',
    key: 'patient',
  },
  {
    title: 'Provider',
    dataIndex: 'provider',
    key: 'provider',
  },
  {
    title: 'Facility',
    dataIndex: 'facility',
    key: 'facility',
  },
  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'ICDs',
    dataIndex: 'icds',
    key: 'icds',
    render: (text: any, record: any) => {
      return record.icds ? record.icds.split(',').join(', ') : '';
    },
  },
  {
    title: 'CPTs',
    dataIndex: 'cpts',
    key: 'cpts',
    render: (text: any, record: any) => {
      return record.cpts ? record.cpts.split(',').join(', ') : '';
    },
  },
  {
    title: 'Charge',
    dataIndex: 'charge',
    key: 'charge',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Expected',
    dataIndex: 'expected',
    key: 'expected',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (text: string) => (moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : ''),
  },
];

const dummyDataSource = [
  {
    visitId: 241,
    visitDate: '2024-10-10',
    patientName: 'Williams Jane',
    provider: 'Medicare Co',
    facility: 'Healthcare Inc',
    state: 'JK',
    icds: '12000,23232',
    cpts: '99439,99490',
    charge: 1202.32,
    expected: 1322,
    createdAt: '2023-10-21T03:00:00Z',
  },
  {
    visitId: 213,
    visitDate: '2024-10-11',
    patientName: 'Brian James',
    provider: 'HealthFirst',
    facility: 'CareCo',
    state: 'KI',
    icds: '901,327',
    cpts: '8972,1223',
    charge: 891,
    expected: 1200,
    createdAt: '2023-11-09T05:05:00Z',
  },
];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  {
    id: 'visitReport',
    label: 'Visit Report',
    status: 'active',
    link: 'visit-report',
  },
];

const initialAlertState = {
  color: '',
  message: '',
  error: false,
};

export const VisitReportPage = () => {
  const dispatch = useAppDispatch();

  const { fetchVisitReportStatus, fetchVisitReportData, fetchVisitReportLoading } =
    useAppSelector(getVisitReportSelector);
  const { systemVisitData } = useAppSelector(getSystemVisitStatusSelector);

  const initialFromDate = moment().startOf('month').format('YYYY-MM-DD');
  const initialToDate = moment().endOf('month').format('YYYY-MM-DD');

  const [dataSource, setDataSource] = useState<any[]>([]);
  const [filterObject, setFilterObject] = useState<any>({
    dateFrom: initialFromDate,
    dateTo: initialToDate,
    providerId: null,
    facilityId: null,
    state: null,
    visitStatusId: null,
  });
  const [searchTerm, setSearchTerm] = useState<string>('');

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>(initialAlertState);

  useEffect(() => {
    if (systemVisitData?.length === 0) {
      getVisitStatus();
    }

    fetchSystemProviders();
    fetchSystemFacilities();
    setVisibleAlert(false);
    setAlertObj(initialAlertState);
  }, []);

  useEffect(() => {
    if (filterObject) {
      fetchVisitReport(0, searchTerm);
    }
  }, [filterObject, searchTerm]);

  useEffect(() => {
    if (fetchVisitReportStatus === 'SUCCESS') {
      setDataSource(fetchVisitReportData?.items);
      setCurrentPage(fetchVisitReportData?.currentPage);
      setTotalPages(fetchVisitReportData?.totalPages);
      dispatch(clearVisitReportResponse());
    } else if (fetchVisitReportStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj(initialAlertState);
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearVisitReportResponse());
    }
  }, [fetchVisitReportStatus]);

  const onPageChange = (page: any) => {
    setCurrentPage(page - 1);
    fetchVisitReport(page - 1);
  };

  const fetchVisitReport = (pageNumber = currentPage, searchValue = searchTerm) => {
    const updatedFilterObject = { page: pageNumber, size: PAGE_SIZE, search: searchValue, ...filterObject };
    dispatch(getVisitReportRequest({ filters: updatedFilterObject }));
  };

  const getVisitStatus = () => {
    dispatch(getSystemVisitStatusRequest());
  };

  const fetchSystemProviders = () => {
    dispatch(getSystemProvidersRequest());
  };

  const fetchSystemFacilities = () => {
    dispatch(getSystemFacilityRequest());
  };

  const handleSearch = (data: any) => {
    const updatedData = { ...data };

    updatedData.providerId = updatedData.providerId ? parseInt(updatedData.providerId, 10) : null;

    updatedData.facilityId = updatedData.facilityId ? parseInt(updatedData.facilityId, 10) : null;

    updatedData.visitStatusId = updatedData.visitStatusId ? parseInt(updatedData.visitStatusId, 10) : null;

    updatedData.state = updatedData.state || null;

    setCurrentPage(0);
    setFilterObject(updatedData);
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
    setAlertObj(initialAlertState);
  };

  return (
    <div className="main-content">
      <MainHeader />
      <PageTopic mainTitle="Visit Report" enablePrimaryButton={false} breadCrumbArr={breadCrumbArr} />

      <VisitReportAdvanceSearch onSubmit={handleSearch} />

      {visibleAlert && alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}

      <div className="flex justify-end mb-4">
        <div className="flex items-center">
          <label htmlFor="search" className="mr-2 text-gray-600">
            Search:
          </label>
          <input
            type="text"
            className="border border-gray-300 rounded px-2 py-1 focus:outline-0 focus:ring-primaryDefault focus:border-primaryDefault"
            name="search"
            title="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      {fetchVisitReportLoading ? (
        <Spinner />
      ) : (
        <>
          {dataSource?.length > 0 ? (
            <>
              <div className={`overflow-x-auto ${totalPages > 1 ? '' : 'pb-7'}`}>
                <TableContent columns={columns} dataSource={dataSource} enableActions={false} />
              </div>
              {totalPages > 1 && (
                <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
              )}
            </>
          ) : (
            <EmptyContent mode="VIEW" enableCreateButton={false} />
          )}
        </>
      )}
    </div>
  );
};
