import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import {
  ChargeDetailVisitReportFetchInterface,
  ChargeDetailVisitReportFetchPropsInterface,
} from '../../../../interfaces/managementInterfaces';
import { getChargeDetailVisitReportAPIRequest } from '../../../../api/management/chargeDetailVisitReport';

const initialState: ChargeDetailVisitReportFetchPropsInterface = {
  fetchChargeDetailVisitReportLoading: false,
  fetchChargeDetailVisitReportData: {
    currentPage: 0,
    items: [],
    totalItems: 0,
    totalPages: 0,
  },
  fetchChargeDetailVisitReportError: null,
  fetchChargeDetailVisitReportStatus: 'IDLE',
};

export const getChargeDetailVisitReportRequest: any = createAsyncThunk(
  'management/charge-detail-visit/get',
  async (reportData: ChargeDetailVisitReportFetchInterface, thunkAPI: any) => {
    try {
      const response: any = await getChargeDetailVisitReportAPIRequest(reportData);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getChargeDetailVisitReportSlice = createSlice({
  name: 'fetchChargeDetailVisitReport',
  initialState,
  reducers: {
    clearFetchChargeDetailVisitReportResponse: (state) => {
      state.fetchChargeDetailVisitReportLoading = false;
      state.fetchChargeDetailVisitReportData = { currentPage: 0, items: [], totalItems: 0, totalPages: 0 };
      state.fetchChargeDetailVisitReportStatus = 'IDLE';
      state.fetchChargeDetailVisitReportError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getChargeDetailVisitReportRequest.pending, (state, action) => {
        state.fetchChargeDetailVisitReportLoading = true;
        state.fetchChargeDetailVisitReportStatus = 'PENDING';
      })
      .addCase(getChargeDetailVisitReportRequest.fulfilled, (state, action) => {
        state.fetchChargeDetailVisitReportData = action.payload;
        state.fetchChargeDetailVisitReportLoading = false;
        state.fetchChargeDetailVisitReportStatus = 'SUCCESS';
      })
      .addCase(getChargeDetailVisitReportRequest.rejected, (state, action) => {
        state.fetchChargeDetailVisitReportLoading = false;
        state.fetchChargeDetailVisitReportStatus = 'FAILED';
        state.fetchChargeDetailVisitReportError = action.payload;
      });
  },
});

export const { clearFetchChargeDetailVisitReportResponse } = getChargeDetailVisitReportSlice.actions;

export const getChargeDetailVisitReportSelector = (state: RootState) => state.GetChargeDetailVisitReport;

export default getChargeDetailVisitReportSlice.reducer;
