import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks/hooks';

import { MainHeader } from '../../components/mainHeader/MainHeader';
import { PageTopic } from '../../components/pageTopic/PageTopic';
import { TableContent } from '../../components/table/TableContent';
import { EmptyContent } from '../../components/emptyContent/EmptyContent';
import { PrimaryButton } from '../../components/atoms/PrimaryButton';
import { CommonAlert } from '../../components/atoms/Alert';
import { Pagination } from '../../components/pagination/Pagination';
import { Spinner } from '../../components/atoms/Spinner';

import { FaFileExcel } from 'react-icons/fa';

import {
  getWeeklyAnalysisReportRequest,
  getWeeklyAnalysisReportSelector,
  clearWeeklyAnalysisReportResponse,
} from '../../redux/slices/reports/getWeeklyAnalysisReportSlice';

import { formatCurrency } from '../../utils/commonFunctions';

const PAGE_SIZE = 10;

const columns = [
  {
    title: 'Weekly',
    dataIndex: 'weekly',
    key: 'weekly',
  },
  {
    title: 'Billing File Count',
    dataIndex: 'billingFileCount',
    key: 'billingFileCount',
  },
  {
    title: 'Visits Count',
    dataIndex: 'visitCount',
    key: 'visitCount',
  },
  {
    title: 'Approved',
    dataIndex: 'approved',
    key: 'approved',
  },
  {
    title: 'Alert',
    dataIndex: 'alert',
    key: 'alert',
  },
  {
    title: 'Eligibility Failed',
    dataIndex: 'eligibilityFailed',
    key: 'eligibilityFailed',
  },
  {
    title: 'Issue Pending',
    dataIndex: 'issuePending',
    key: 'issuePending',
  },
  {
    title: 'Missing Facesheet',
    dataIndex: 'missingFaceSheet',
    key: 'missingFaceSheet',
  },
  {
    title: 'On Hold',
    dataIndex: 'onHold',
    key: 'onHold',
  },
  {
    title: 'Pending Touched',
    dataIndex: 'pendingTouched',
    key: 'pendingTouched',
  },
  {
    title: 'Quality Check',
    dataIndex: 'qualityCheck',
    key: 'qualityCheck',
  },
  {
    title: 'Pending Auth',
    dataIndex: 'pendingAuth',
    key: 'pendingAuth',
  },
  {
    title: 'Billed Count',
    dataIndex: 'billedCount',
    key: 'billedCount',
  },
  {
    title: 'Billed Charges',
    dataIndex: 'billedCharges',
    key: 'billedCharges',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Denial',
    dataIndex: 'denial',
    key: 'denial',
  },
  {
    title: 'Rejected',
    dataIndex: 'rejected',
    key: 'rejected',
  },
  {
    title: 'Alert',
    dataIndex: 'alertNew',
    key: 'alertNew',
  },
  {
    title: 'Hold',
    dataIndex: 'hold',
    key: 'hold',
  },
  {
    title: 'Pending',
    dataIndex: 'pending',
    key: 'pending',
  },
  {
    title: 'Appeal Required',
    dataIndex: 'appealRequired',
    key: 'appealRequired',
  },
  {
    title: 'Appeal Submitted',
    dataIndex: 'appealSubmitted',
    key: 'appealSubmitted',
  },
  {
    title: 'AR Worked',
    dataIndex: 'arWorked',
    key: 'arWorked',
  },
  {
    title: 'Patient Statement Sent Count',
    dataIndex: 'patientStatementSentCount',
    key: 'patientStatementSentCount',
  },
  {
    title: 'Patient Payment Post',
    dataIndex: 'patientPaymentPost',
    key: 'patientPaymentPost',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Insurance Payment Post',
    dataIndex: 'insurancePaymentPost',
    key: 'insurancePaymentPost',
    render: (text: number) => formatCurrency(text ?? 0),
  },
];

const dummyDataSource = [
  {
    id: 1,
    weekly: '11/18/2024-11/24/2024',
    billingFileCount: 0,
    visitsCount: 0,
    approved: '0/0/0',
    alert: '0/0/0',
    eligibilityFailed: '0/0/0',
    issuePending: '0/0/4',
    missingFacesheet: '0/0/0',
    onHold: '0/0/246',
    pendingTouched: '0/0/8',
    qualityCheck: '0/0/0',
    pendingAuth: '0/0/0',
    billedCount: 0,
    billedCharges: 0,
    denial: '16/0/410',
    rejected: '0/0/4',
    billedCountAlert: '0/0/18',
    pending: '0/0/0',
    hold: '0/0/42',
    appealRequired: '0/0/0',
    appealSubmitted: '0/0/0',
    arWorked: 0,
    patientStatementSentCount: 12,
    patientPaymentPost: 0,
    insurancePaymentPost: 49594.29,
  },
  {
    id: 2,
    weekly: '11/25/2024-12/01/2024',
    billingFileCount: 0,
    visitsCount: 0,
    approved: '0/0/0',
    alert: '0/0/0',
    eligibilityFailed: '0/0/0',
    issuePending: '0/0/4',
    missingFacesheet: '0/0/0',
    onHold: '0/0/246',
    pendingTouched: '0/0/8',
    qualityCheck: '0/0/0',
    pendingAuth: '0/0/0',
    billedCount: 0,
    billedCharges: 0,
    denial: '16/0/410',
    rejected: '0/0/4',
    billedCountAlert: '0/0/18',
    pending: '0/0/0',
    hold: '0/0/42',
    appealRequired: '0/0/0',
    appealSubmitted: '0/0/0',
    arWorked: 0,
    patientStatementSentCount: 0,
    patientPaymentPost: 0,
    insurancePaymentPost: 49594.29,
  },
];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  { id: 'weekly-analysis', label: 'Weekly Analysis', status: 'active', link: 'weekly-analysis' },
];

const initialAlertState = {
  color: '',
  message: '',
  error: false,
};

export const WeeklyAnalysisReportPage = () => {
  const dispatch = useAppDispatch();

  const { fetchWeeklyAnalysisReportStatus, fetchWeeklyAnalysisReportData, fetchWeeklyAnalysisReportLoading } =
    useAppSelector(getWeeklyAnalysisReportSelector);

  const [dataSource, setDataSource] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>(initialAlertState);

  useEffect(() => {
    setVisibleAlert(false);
    setAlertObj(initialAlertState);
  }, []);

  useEffect(() => {
    getWeeklyAnalysisReport(0, searchTerm);
  }, [searchTerm]);

  useEffect(() => {
    if (fetchWeeklyAnalysisReportStatus === 'SUCCESS') {
      setDataSource(fetchWeeklyAnalysisReportData?.items);
      setCurrentPage(fetchWeeklyAnalysisReportData?.currentPage);
      setTotalPages(fetchWeeklyAnalysisReportData?.totalPages);
      dispatch(clearWeeklyAnalysisReportResponse());
    } else if (fetchWeeklyAnalysisReportStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj(initialAlertState);
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearWeeklyAnalysisReportResponse());
    }
  }, [fetchWeeklyAnalysisReportStatus]);

  const onPageChange = (page: any) => {
    setCurrentPage(page - 1);
    getWeeklyAnalysisReport(page - 1);
  };
  const getWeeklyAnalysisReport = (pageNumber = currentPage, searchValue = searchTerm) => {
    const updatedFilterObject = { page: pageNumber, size: PAGE_SIZE, search: searchValue };
    dispatch(getWeeklyAnalysisReportRequest({ filters: updatedFilterObject }));
  };

  const handleExport = () => {};

  const handleAlertClose = () => {
    setVisibleAlert(false);
    setAlertObj(initialAlertState);
  };

  return (
    <div className="main-content">
      <MainHeader />
      <PageTopic mainTitle="Weekly Analysis" enablePrimaryButton={false} breadCrumbArr={breadCrumbArr} />

      {visibleAlert && alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}

      <div className="flex justify-end mb-4">
        <div className="flex items-center">
          <label htmlFor="search" className="mr-2 text-gray-600">
            Search:
          </label>
          <input
            type="text"
            className="border border-gray-300 rounded px-2 py-1 mr-2 focus:outline-0 focus:ring-primaryDefault focus:border-primaryDefault"
            name="search"
            title="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <PrimaryButton
            type="button"
            label="Export to Excel"
            icon={<FaFileExcel style={{ fontSize: '18px' }} />}
            style={{ height: '38px', maxWidth: '100px' }}
          />
        </div>
      </div>

      {fetchWeeklyAnalysisReportLoading ? (
        <Spinner />
      ) : (
        <>
          {dataSource?.length > 0 ? (
            <>
              <div className={`overflow-x-auto ${totalPages > 1 ? '' : 'pb-7'}`}>
                <TableContent enableActions={false} columns={columns} dataSource={dataSource} />
              </div>

              {totalPages > 1 && (
                <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
              )}
            </>
          ) : (
            <EmptyContent mode="VIEW" enableCreateButton={false} />
          )}
        </>
      )}
    </div>
  );
};
