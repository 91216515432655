import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks/hooks';

import { MainHeader } from '../../components/mainHeader/MainHeader';
import { PageTopic } from '../../components/pageTopic/PageTopic';
import { Pagination } from '../../components/pagination/Pagination';
import { RightSheet } from '../../components/rightSheet/RightSheet';
import { TableContent } from '../../components/table/TableContent';
import { TableSearch } from '../../components/tableSearch/TableSearch';
import { RightSheetForm } from '../../components/account/users/RightSheetForm';
import { CommonAlert } from '../../components/atoms/Alert';
import { RightSheetLarge } from '../../components/rightSheet/RightSheetLarge';
import { Spinner } from '../../components/atoms/Spinner';
import { EmptyContent } from '../../components/emptyContent/EmptyContent';
import { Profile } from '../../components/list/providers/Profile';
import { AddressList } from '../../components/account/users/AddressList';

import { IoIosInformationCircleOutline } from 'react-icons/io';

import { addUserRequest, addUserSelectore, clearAddUserResponse } from '../../redux/slices/user/addUserSlice';
import { getUserSelector, getUsersRequest, clearFetchUserResponse } from '../../redux/slices/user/getUserSlice';
import { editUserSelector, updateUserRequest, clearEditUserResponse } from '../../redux/slices/user/updateUserSlice';
import {
  getUserByIdRequest,
  getUserByIdSelector,
  clearFetchUserByIdResponse,
} from '../../redux/slices/user/getUserByIdSlice';
import { rightSheetSelector, openRightSheet, closeRightSheet } from '../../redux/slices/app/rightSheetSlice';

import { userValidation } from '../../utils/userValidation';
import { editUserValidation } from '../../utils/userValidation';
import { userRoles } from '../../constants/userConstants';

const PAGE_SIZE = 20;

const stepperData = [
  { id: 1, label: 'Profile' },
  { id: 2, label: 'Address' },
];

const columns = [
  {
    title: 'First Name',
    dataIndex: 'firstName',
    key: 'firstName',
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    key: 'lastName',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Role',
    dataIndex: 'roleId',
    key: 'role',
    render: (roleId: number) => {
      const role = userRoles.find((role) => role.id === roleId);
      return role ? role.name : '';
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
];

// const dataSource = [
//   {
//     firstName: 'John',
//     lastName: 'Doe',
//     email: 'testuser@gmaail.com',
//     role: 'Super Admin',
//     status: 'Active',
//   },
//   {
//     firstName: 'John',
//     lastName: 'Doe',
//     email: 'testuser@gmaail.com',
//     role: 'Super Admin',
//     status: 'Active',
//   },
//   {
//     firstName: 'John',
//     lastName: 'Doe',
//     email: 'testuser@gmaail.com',
//     role: 'Super Admin',
//     status: 'Active',
//   },
//   {
//     firstName: 'John',
//     lastName: 'Doe',
//     email: 'testuser@gmaail.com',
//     role: 'Super Admin',
//     status: 'Active',
//   },
// ];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  { id: 'users', label: 'Users', status: 'active', link: 'users' },
];

export const UsersPage = () => {
  const dispatch = useAppDispatch();
  const { status, userData, loading } = useAppSelector(getUserSelector);
  const { addUserStatus, addUserLoading } = useAppSelector(addUserSelectore);
  const { editUserStatus, editUserLoading } = useAppSelector(editUserSelector);
  const { userByIdStatus, userByIdUserData } = useAppSelector(getUserByIdSelector);
  const { isRightSheetOpen, sheetId } = useAppSelector(rightSheetSelector);

  const [visibleModal, setVisibleModal] = useState(false);
  const [onFormChange, setOnFormChange] = useState<any>({});
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [dataSource, setDataSource] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>({
    color: '',
    message: '',
    error: false,
  });
  const [selectedToEdit, setSelectedToEdit] = useState<any>({});
  const [visibleEditForm, setVisibleEditForm] = useState(false);
  const [selectedChild, setSelectedChild] = useState(1);
  const [mode, setMode] = useState('CREATE');
  const [errorObj, setErrorObj] = useState<any>({});

  // console.log('=== mode ===', mode);

  useEffect(() => {
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  }, []);

  useEffect(() => {
    fetchUsers(0, searchTerm);
  }, [searchTerm]);

  useEffect(() => {
    if (status === 'SUCCESS') {
      setDataSource(userData?.items);
      setCurrentPage(userData?.currentPage);
      setTotalPages(userData?.totalPages);
      dispatch(clearFetchUserResponse());
    } else if (status === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong!',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearFetchUserResponse());
    }
  }, [status]);

  useEffect(() => {
    if (addUserStatus === 'SUCCESS' && !addUserLoading) {
      setAlertObj({
        color: 'success',
        message: 'Successfully created !',
        error: false,
      });
      setVisibleAlert(true);
      fetchUsers();
      // setVisibleModal(false);
      dispatch(closeRightSheet());
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearAddUserResponse());
    } else if (addUserStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearAddUserResponse());
    }
  }, [addUserStatus]);

  useEffect(() => {
    if (editUserStatus === 'SUCCESS' && !editUserLoading) {
      setAlertObj({
        color: 'success',
        message: 'Successfully updated !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearEditUserResponse());
    } else if (editUserStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      // setVisibleModal(false);
      dispatch(closeRightSheet());
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearEditUserResponse());
    }
  }, [editUserStatus]);

  useEffect(() => {
    if (userByIdStatus === 'SUCCESS') {
      setSelectedToEdit(userByIdUserData);
      // setVisibleEditForm(true);
      dispatch(openRightSheet('Users'));
      dispatch(clearFetchUserByIdResponse());
    } else if (userByIdStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearFetchUserByIdResponse());
    }
  }, [userByIdStatus]);

  const fetchUsers = async (pageNumber = currentPage, searchValue = searchTerm) => {
    dispatch(getUsersRequest({ page: pageNumber, size: PAGE_SIZE, search: encodeURIComponent(searchValue) }));
  };

  const fetchUserById = async (id: number) => {
    dispatch(getUserByIdRequest({ userId: id }));
  };

  const onPageChange = (page: any) => {
    setCurrentPage(page - 1);
    fetchUsers(page - 1);
  };

  const handleModalVisible = () => {
    setMode('CREATE');
    // setVisibleModal(true);
    dispatch(openRightSheet('Users-RightSheet'));
  };

  const handleOnClose = () => {
    // setVisibleModal(false);
    // setVisibleEditForm(false);
    dispatch(closeRightSheet());
    setOnFormChange({});
    setSelectedToEdit({});
    setErrorObj({});
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
    setMode('CREATE');
    fetchUsers();
  };

  const handleFormChanges = (e: any) => {
    if (errorObj?.hasOwnProperty(e.target.name)) {
      delete errorObj[e.target.name];
    }
    setOnFormChange((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  // console.log('=== onFormChange ===', onFormChange);

  const onSubmit = () => {
    if (mode === 'CREATE') {
      const validationDetails = userValidation(onFormChange);

      if (Object.keys(validationDetails?.newErrors)?.length > 0) {
        setErrorObj(validationDetails?.newErrors);
        const errorMessages = Object.values(validationDetails.newErrors);
        setVisibleAlert(true);
        setAlertObj({
          color: 'failure',
          message: (
            <div>
              {errorMessages.map((msg: any, index) => (
                <div key={index} className="flex items-center">
                  <IoIosInformationCircleOutline />
                  <span style={{ marginLeft: '8px' }}>{msg}</span>
                </div>
              ))}
            </div>
          ),
          error: true,
        });
      } else {
        dispatch(addUserRequest(onFormChange));
      }
    } else if (mode === 'EDIT' && selectedToEdit) {
      let editObj = {
        ...onFormChange,
        userId: selectedToEdit?.id,
      };

      delete editObj?.id;

      const validationDetails = editUserValidation(onFormChange);

      if (Object.keys(validationDetails?.newErrors)?.length > 0) {
        setErrorObj(validationDetails?.newErrors);
        const errorMessages = Object.values(validationDetails.newErrors);
        setVisibleAlert(true);
        setAlertObj({
          color: 'failure',
          message: (
            <div>
              {errorMessages.map((msg: any, index) => (
                <div key={index} className="flex items-center">
                  <IoIosInformationCircleOutline />
                  <span style={{ marginLeft: '8px' }}>{msg}</span>
                </div>
              ))}
            </div>
          ),
          error: true,
        });
      } else {
        // console.log('OBJ', editObj);
        dispatch(updateUserRequest(editObj));
      }
    }
  };

  const onEdit = (event: any, rowIndex: any) => {
    event.preventDefault();
    const dataSet = dataSource[rowIndex];
    // console.log('==== rowIndex - edit ====', rowIndex, dataSet);
    setMode('EDIT');
    fetchUserById(dataSet?.id);
    setOnFormChange(dataSet);
    setSelectedChild(1);
  };

  const onView = (event: any, rowIndex: any) => {
    event.preventDefault();
    setMode('VIEW');
    const dataSet = dataSource[rowIndex];
    // console.log('=== view user ===', dataSet);
    fetchUserById(dataSet?.id);
    setSelectedChild(1);
  };

  const onDelete = (event: any, rowIndex: any) => {
    event.preventDefault();
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
  };

  const handleClickStepper = (id: any) => {
    setSelectedChild(id);
  };

  const errorAlertOnClose = () => {
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  // console.log('=== selectedChild ===', selectedChild);

  const renderTab = () => {
    switch (selectedChild) {
      case 1:
        return (
          <RightSheetForm
            personalData={selectedToEdit}
            mode={mode}
            selectedId={selectedToEdit?.id}
            onChange={handleFormChanges}
            errorObject={errorObj}
          />
        );
      case 2:
        return <AddressList selectedId={selectedToEdit?.id} mode={mode} />;
      default:
        break;
    }
  };

  // console.log('=== addUserLoading ===', addUserLoading);

  return (
    <div className="main-content">
      <MainHeader />
      {visibleAlert && !alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}
      <PageTopic onClick={handleModalVisible} mainTitle="Users" breadCrumbArr={breadCrumbArr} />

      <div className="flex justify-end mb-4">
        <div className="flex items-center">
          <label htmlFor="search" className="mr-2 text-gray-600">
            Search:
          </label>
          <input
            type="text"
            className="border border-gray-300 rounded px-2 py-1 focus:outline-0 focus:ring-primaryDefault focus:border-primaryDefault"
            name="search"
            title="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      {loading ? (
        <Spinner />
      ) : (
        <>
          {dataSource?.length > 0 ? (
            <>
              <TableContent
                enableActions={true}
                columns={columns}
                dataSource={dataSource}
                enableDelete={true}
                enableEdit={true}
                enableView={true}
                onDelete={onDelete}
                onEdit={onEdit}
                onView={onView}
              />
              {totalPages > 1 && (
                <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={onPageChange} />
              )}
            </>
          ) : (
            <EmptyContent onClick={handleModalVisible} />
          )}
        </>
      )}

      {isRightSheetOpen && sheetId === 'Users-RightSheet' ? (
        <RightSheet
          onClose={handleOnClose}
          title="Add New User"
          submitButtonTitle="Create"
          cancelButtonTitle="Cancel"
          onSubmit={onSubmit}
          children={<RightSheetForm onChange={handleFormChanges} mode={mode} errorObject={errorObj} />}
          loading={addUserLoading}
          enableAlert={visibleAlert}
          alertDetails={alertObj}
          alertOnClose={errorAlertOnClose}
        />
      ) : (
        <></>
      )}
      {isRightSheetOpen && sheetId === 'Users' ? (
        <RightSheetLarge
          title={mode === 'EDIT' ? 'Update User' : 'View User'}
          onSubmit={onSubmit}
          onClose={handleOnClose}
          submitButtonTitle="Update"
          cancelButtonTitle="Cancel"
          stepperData={stepperData}
          clickStepper={handleClickStepper}
          enableFooterButtons={mode === 'VIEW' ? false : selectedChild == 1 ? true : false}
          children={<>{renderTab()}</>}
          loading={editUserLoading}
          enableAlert={visibleAlert}
          alertDetails={alertObj}
          alertOnClose={errorAlertOnClose}
        />
      ) : (
        <></>
      )}
    </div>
  );
};
