import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks/hooks';

import { MainHeader } from '../../../components/mainHeader/MainHeader';
import { PageTopic } from '../../../components/pageTopic/PageTopic';
import { Pagination } from '../../../components/pagination/Pagination';
import { TableContent } from '../../../components/table/TableContent';
import { EmptyContent } from '../../../components/emptyContent/EmptyContent';
import { CommonAlert } from '../../../components/atoms/Alert';
import { Spinner } from '../../../components/atoms/Spinner';
import { CrossoverCarrierReportHeader } from '../../../components/reports/CrossoverCarrierReportHeader';

import {
  getCrossoverCarriersRequest,
  getCrossoverCarriersSelector,
  clearFetchCrossoverCarriersResponse,
} from '../../../redux/slices/reports/getCrossoverCarriersSlice';
import { getSystemInsuranceRequest } from '../../../redux/slices/system/getSystemInsuranceSlice';

const PAGE_SIZE = 25;

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'ERA ID',
    dataIndex: 'eraId',
    key: 'eraId',
  },
  {
    title: 'Check Number',
    dataIndex: 'checkNumber',
    key: 'checkNumber',
  },
  {
    title: 'Crossover Carrier',
    dataIndex: 'crossOverCarrier',
    key: 'crossOverCarrier',
  },
  {
    title: 'Crossover Id',
    dataIndex: 'crossOverId',
    key: 'crossOverId',
  },
  {
    title: 'Patient',
    dataIndex: 'patientName',
    key: 'patientName',
    render: (text: any, row: any) => (
      <a className="no-underline text-linkBlue" href={`patients?id=${row?.patientId}`}>
        {row?.patientName}
      </a>
    ),
  },
  {
    title: 'Claim ID',
    dataIndex: 'claimId',
    key: 'claimId',
  },
  {
    title: 'Case ID',
    dataIndex: 'caseId',
    key: 'caseId',
  },
  {
    title: 'Sec. Insurance',
    dataIndex: 'secondaryInsurance',
    key: 'secondaryInsurance',
  },
  {
    title: 'Pri. Payer ID',
    dataIndex: 'primaryPayerId',
    key: 'primaryPayerId',
  },
  {
    title: 'Pri. Payer Name',
    dataIndex: 'primaryPayerName',
    key: 'primaryPayerName',
  },
];

const dummyDataSource = [
  {
    id: '1',
    eraId: 'ERA123',
    checkNumber: 'CHKSAS21',
    crossOverCarrier: 'Carrier A',
    crossOverId: 'Crossover123',
    patientName: 'John Doe',
    postedAmount: '$100.00',
    claimId: 'Claim123',
    caseId: 'Case123',
    secondaryInsurance: 'Insurance B',
    primaryPayerId: 'Payer123',
    primaryPayerName: 'Payer Name',
  },
];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  { id: 'crossover-carrier-report', label: 'Crossover Carrier', status: 'active', link: 'crossover-carrier-report' },
];

const initialAlertState = {
  color: '',
  message: '',
  error: false,
};

export const CrossoverCarrierReportPage = () => {
  const dispatch = useAppDispatch();

  const { fetchCrossoverCarriersStatus, fetchCrossoverCarriersData, fetchCrossoverCarriersLoading } =
    useAppSelector(getCrossoverCarriersSelector);

  const [dataSource, setDataSource] = useState<any[]>([]);
  const [filterObject, setFilterObject] = useState<any>({
    primaryPayerId: null,
    foundOption: null,
  });
  const [searchTerm, setSearchTerm] = useState<string>('');

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>(initialAlertState);

  useEffect(() => {
    fetchInsuranceList();
    setVisibleAlert(false);
    setAlertObj(initialAlertState);
  }, []);

  useEffect(() => {
    if (filterObject) {
      fetchCrossoverCarriersReport(0, searchTerm);
    }
  }, [filterObject, searchTerm]);

  useEffect(() => {
    if (fetchCrossoverCarriersStatus === 'SUCCESS') {
      setDataSource(fetchCrossoverCarriersData?.items);
      setCurrentPage(fetchCrossoverCarriersData?.currentPage);
      setTotalPages(fetchCrossoverCarriersData?.totalPages);
      dispatch(clearFetchCrossoverCarriersResponse());
    } else if (fetchCrossoverCarriersStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj(initialAlertState);
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearFetchCrossoverCarriersResponse());
    }
  }, [fetchCrossoverCarriersStatus]);

  const onPageChange = (page: any) => {
    setCurrentPage(page - 1);
    fetchCrossoverCarriersReport(page - 1);
  };

  const fetchCrossoverCarriersReport = (pageNumber = currentPage, searchValue = searchTerm) => {
    const updatedFilterObject = { page: pageNumber, size: PAGE_SIZE, search: searchValue, ...filterObject };
    dispatch(getCrossoverCarriersRequest({ filters: updatedFilterObject }));
  };

  const fetchInsuranceList = () => {
    dispatch(getSystemInsuranceRequest());
  };

  const handleSearch = (data: any) => {
    const updatedData = { ...data };

    updatedData.primaryPayerId = updatedData.primaryPayerId ? parseInt(updatedData.primaryPayerId, 10) : null;

    updatedData.foundOption = updatedData.foundOption || null;

    setCurrentPage(0);
    setFilterObject(updatedData);
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
    setAlertObj(initialAlertState);
  };

  return (
    <div className="main-content">
      <MainHeader />
      <PageTopic mainTitle="Crossover Carrier" enablePrimaryButton={false} breadCrumbArr={breadCrumbArr} />

      <CrossoverCarrierReportHeader onSubmit={handleSearch} />

      {visibleAlert && alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}

      <div className="flex justify-end mb-4">
        <div className="flex items-center">
          <label htmlFor="search" className="mr-2 text-gray-600">
            Search:
          </label>
          <input
            type="text"
            className="border border-gray-300 rounded px-2 py-1 focus:outline-0 focus:ring-primaryDefault focus:border-primaryDefault"
            name="search"
            title="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      {fetchCrossoverCarriersLoading ? (
        <Spinner />
      ) : (
        <>
          {dataSource?.length > 0 ? (
            <>
              <div className="overflow-x-auto">
                <TableContent enableActions={true} columns={columns} dataSource={dataSource} enableDownload />
              </div>

              {totalPages > 1 && (
                <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
              )}
            </>
          ) : (
            <EmptyContent enableCreateButton={false} />
          )}
        </>
      )}
    </div>
  );
};
