import React, { useEffect } from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from './hooks/storeHooks/hooks';

import { getIPDataRequest, getIpDataSelector } from './redux/slices/system/getIPDataSlice';

import { AccountsPage } from './pages/Accounts';
import { LoginPage } from './pages/login/Login';
import { BillingVisits } from './pages/BillingVisits';
import PrivateRoutes from './components/privateRoutes/PrivateRoutes';
import { PracticesPage } from './pages/account/PracticesPage';
import { UsersPage } from './pages/account/UsersPage';
import { PatientsPage } from './pages/list/PatientsPage';
import 'flowbite';
import { ProvidersPage } from './pages/list/ProvidersPage';
import { FacilitiesPage } from './pages/list/FacilitiesPage';
import { InsurancePage } from './pages/list/InsurancePage';
import { DoctorFinancialReportPage } from './pages/reports/DoctorFinancialReportPage';
import { VisitCountReportPage } from './pages/reports/VisitCountReportPage';
import { PrimaryInsuranceAgingReportPage } from './pages/reports/PrimaryInsuranceAging';
import { SecondaryInsuranceAgingReportPage } from './pages/reports/SecondaryInsuranceAging';
import { CrossoverCarrierAgingReportPage } from './pages/reports/CrossoverCarrierAging';
import { PrimaryInsuranceAgingSubReportPage } from './pages/reports/subTables/PrimaryInsuranceAgingSub';
import { BatchPaymentPage } from './pages/billing/BatchPaymentPage';
import { BulkPayments } from './pages/billing/bulkPayments/BulkPayments';
import { CPTCodesPage } from './pages/account/codes/CPTCodesPage';
import { ICDCodesPage } from './pages/account/codes/ICDCodesPage';
import { PaymentCodesPage } from './pages/account/codes/PaymentCodesPage';
import { ModifierCodesPage } from './pages/account/codes/ModifierCodesPage';
import { BankReconciliationReportPage } from './pages/reports/paymentTables/BankReconciliationReportPage';
import { BankReconciledReportPage } from './pages/reports/paymentTables/BankReconciledReportPage';
import { BankReconciliationToReconciledReportPage } from './pages/reports/paymentTables/BankReconciliationToReconciledReportPage';
import { DashboardBankReconciliationSnapshotPage } from './pages/reports/paymentTables/DashboardBankReconciliationSnapshotPage';
import { CrossoverCarrierReportPage } from './pages/reports/paymentTables/CrossoverCarrierReportPage';
import { PrimaryClaimsPage } from './pages/claims/PrimaryClaimsPage';
import { SecondaryClaimsPage } from './pages/claims/SecondaryClaimsPage';
import { FinancialDashboardPage } from './pages/reports/FinancialDashboardPage';
import { FinancialDashboardPayerMixReportPage } from './pages/reports/FinancialDashboardPayerMixReportPage';
import { ClaimERAAutoPostPage } from './pages/billing/payment/ClaimERAAutoPostPage';
import { DenialPage } from './pages/billing/payment/DenialPage';
import { ClaimERAFilesPage } from './pages/billing/payment/ClaimERAFilesPage';
import { ERAFilesByReceivedDatePage } from './pages/billing/payment/ERAFilesByReceivedDatePage';
import { ClaimMDERAPaymentsPage } from './pages/billing/payment/ClaimMDERAPaymentsPage';
import { PatientsStatements } from './pages/billing/PatientsStatements';
import { VisitBilledCountPage } from './pages/management/VisitBilledCountPage';
import { ProcedureProductivityReportPage } from './pages/management/ProcedureProductivityReportPage';
import { ChargeDetailVisitReportPage } from './pages/management/ChargeDetailVisitReportPage';
import { ChargeDetailEncounterReportPage } from './pages/management/ChargeDetailEncounterReportPage';
import { PatientEligibilityPage } from './pages/billing/PatientEligibilityPage';
import { ExpectedVsCollectionsReportPage } from './pages/reports/ExpectedVsCollectionsReportPage';
import { ReimbursementReportPage } from './pages/reports/ReimbursementReportPage';
import { ProviderVisitLagReportPage } from './pages/reports/ProviderVisitLagReportPage';
import { VisitReportPage } from './pages/reports/VisitReportPage';
import { BillingSnapshotReportPage } from './pages/reports/BillingSnapshotReportPage';
import { BilledAndUnbilledReportPage } from './pages/reports/BilledAndUnbilledReportPage';
import { RVUReportPage } from './pages/reports/RVUReportPage';
import { WeeklyAnalysisReportPage } from './pages/reports/WeeklyAnalysisReportPage';
import { ARAnalysisReportPage } from './pages/reports/ARAnalysisReportPage';
import { FinancialDashboardArReportPage } from './pages/reports/FinancialDashboardArReportPage';
import { PaymentByPatientReportPage } from './pages/reports/paymentTables/PaymentByPatientReportPage';
import { SecondaryInsuranceAgingSubPage } from './pages/reports/subTables/SecondaryInsuranceAgingSub';
import { CrossoverCarrierAgingSubPage } from './pages/reports/subTables/CrossoverCarrierAgingSub';
import { ChargeDetailReportPage } from './pages/management/ChargeDetailReportPage';
import { ChargeDetailNoActionReportPage } from './pages/reports/ChargeDetailNoActionReportPage';

const Dashboard = React.lazy(() => import('./pages/dashboard/Dashboard'));

function App() {
  const dispatch = useAppDispatch();

  const { ipDataStatus, ipDataPayload } = useAppSelector(getIpDataSelector);

  useEffect(() => {
    dispatch(getIPDataRequest());
  }, []);

  useEffect(() => {
    if (ipDataStatus === 'SUCCESS') {
      localStorage.setItem('ipAddress', ipDataPayload?.ip);
    }
  }, [ipDataStatus]);

  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route element={<PrivateRoutes />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/accounts" element={<AccountsPage />} />
        <Route path="/patients" element={<PatientsPage />} />
        <Route path="/billing" element={<BillingVisits />} />
        <Route path="/practices" element={<PracticesPage />} />
        <Route path="/users" element={<UsersPage />} />
        <Route path="/cpt-codes" element={<CPTCodesPage />} />
        <Route path="/icd-codes" element={<ICDCodesPage />} />
        <Route path="/payment-codes" element={<PaymentCodesPage />} />
        <Route path="/modifier-codes" element={<ModifierCodesPage />} />
        <Route path="/providers" element={<ProvidersPage />} />
        <Route path="/facilities" element={<FacilitiesPage />} />
        <Route path="/insurance" element={<InsurancePage />} />
        <Route path="/financial-dashboard" element={<FinancialDashboardPage />} />
        <Route path="/financial-dashboard-payer-mix" element={<FinancialDashboardPayerMixReportPage />} />
        <Route path="/doctor-financial" element={<DoctorFinancialReportPage />} />
        <Route path="/visit-count-report" element={<VisitCountReportPage />} />
        <Route path="/primary-insurance-report" element={<PrimaryInsuranceAgingReportPage />} />
        <Route path="/secondary-insurance-report" element={<SecondaryInsuranceAgingReportPage />} />
        <Route path="/crossover-report" element={<CrossoverCarrierAgingReportPage />} />
        <Route
          path="/primary-insurance-report-sub/:insuranceId/:reportId"
          element={<PrimaryInsuranceAgingSubReportPage />}
        />
        <Route
          path="/secondary-insurance-report-sub/:insuranceId/:reportId"
          element={<SecondaryInsuranceAgingSubPage />}
        />
        <Route path="/crossover-carrier-report-sub/:insuranceId/:reportId" element={<CrossoverCarrierAgingSubPage />} />
        <Route path="/billing-batch-payment" element={<BatchPaymentPage />} />
        <Route path="/bulk-payment/:id" element={<BulkPayments />} />

        <Route path="/claim-auto-post" element={<ClaimERAAutoPostPage />} />
        <Route path="/claim-era-files" element={<ClaimERAFilesPage />} />
        <Route path="/claim-era-files-by-recieved-date" element={<ERAFilesByReceivedDatePage />} />
        <Route path="/claimmd-era" element={<ClaimMDERAPaymentsPage />} />

        <Route path="/denial-reports" element={<DenialPage />} />

        <Route
          path="/bank-reconciliation-to-be-reconciled-report"
          element={<BankReconciliationToReconciledReportPage />}
        />

        <Route path="/bank-reconciliation-reconciled-report" element={<BankReconciledReportPage />} />

        <Route path="/bank-reconciliation-report" element={<BankReconciliationReportPage />} />

        <Route path="/dashboard-bank-reconciliation-snapshot" element={<DashboardBankReconciliationSnapshotPage />} />

        <Route path="/crossover-carrier-report" element={<CrossoverCarrierReportPage />} />

        <Route path="/primary-claims" element={<PrimaryClaimsPage />} />
        <Route path="/secondary-claims" element={<SecondaryClaimsPage />} />
        <Route path="/patient-statements" element={<PatientsStatements />} />
        <Route path="/visit-billed-count" element={<VisitBilledCountPage />} />
        <Route path="/procedure-productivity" element={<ProcedureProductivityReportPage />} />
        <Route path="/charge-detail-visit" element={<ChargeDetailVisitReportPage />} />
        <Route path="/charge-detail-encounter" element={<ChargeDetailEncounterReportPage />} />
        <Route path="/patient-eligibility" element={<PatientEligibilityPage />} />
        <Route path="/expected-vs-collection-report" element={<ExpectedVsCollectionsReportPage />} />
        <Route path="/reimbursement-report" element={<ReimbursementReportPage />} />
        <Route path="/provider-lag-report" element={<ProviderVisitLagReportPage />} />
        <Route path="/visit-report" element={<VisitReportPage />} />
        <Route path="/billing-snapshot" element={<BillingSnapshotReportPage />} />
        <Route path="/billed-unbilled-report" element={<BilledAndUnbilledReportPage />} />
        <Route path="/rvu-report" element={<RVUReportPage />} />
        <Route path="/weekly-analysis" element={<WeeklyAnalysisReportPage />} />
        <Route path="/ar-analysis-report" element={<ARAnalysisReportPage />} />
        <Route path="/financial-dashboard-ar-report" element={<FinancialDashboardArReportPage />} />
        <Route path="/patient-payment" element={<PaymentByPatientReportPage />} />
        <Route path="/charge-detail-no-action" element={<ChargeDetailNoActionReportPage />} />
        <Route path="/charge-detail" element={<ChargeDetailReportPage />} />
      </Route>
    </Routes>
  );
}

export default App;
