import { useState } from 'react';

import moment from 'moment';

import { CommonButton } from '../../atoms/CommonButton';
import { DateRangeCalendar } from '../../atoms/DateRangeCalendar';
import { InputField } from '../../atoms/InputField';
import { PrimaryButton } from '../../atoms/PrimaryButton';
import { FaFileExcel } from 'react-icons/fa';

import { ClaimERAAutoPostInterface } from '../../../interfaces/userInterface';

export const ClaimERAAutoPostHeader: React.FC<ClaimERAAutoPostInterface> = ({
  onSubmit,
  onExport,
  onDownload,
  excelLoading,
}) => {
  const [filterObject, setFilterObject] = useState<any>({
    checkNumber: null,
    claimEraDateFrom: null,
    claimEraDateTo: null,
  });

  const onChangeFilter = (e: any) => {
    e.preventDefault();
    setFilterObject((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onChangeDateRange = (dateRange: { startDate: Date; endDate: Date }) => {
    setFilterObject((prev: any) => ({
      ...prev,
      claimEraDateFrom: moment(dateRange.startDate).format('YYYY-MM-DD'),
      claimEraDateTo: moment(dateRange.endDate).format('YYYY-MM-DD'),
    }));
  };

  return (
    <>
      <>
        <div className="grid md:grid-cols-4 gap-3 mt-6 mb-4">
          <InputField
            label=""
            placeholder="Check Number"
            name="checkNumber"
            onChange={onChangeFilter}
            value={filterObject?.checkNumber ?? ''}
          />

          <DateRangeCalendar
            initialDateRange={[
              {
                startDate: new Date(filterObject?.claimEraDateFrom ?? new Date()),
                endDate: new Date(filterObject?.claimEraDateTo ?? new Date()),
                key: 'selection',
              },
            ]}
            onChange={onChangeDateRange}
          />
          <div className="flex gap-2 justify-end pt-2">
            <PrimaryButton
              type="button"
              label="Submit"
              style={{ height: '40px', maxWidth: '120px' }}
              onClick={() => onSubmit(filterObject)}
            />
            <CommonButton
              label=""
              icon={<FaFileExcel style={{ fontSize: '18px' }} />}
              style={{ height: '40px', maxWidth: '100px' }}
              onClick={() => onExport(filterObject)}
              loading={excelLoading}
            />
            <CommonButton
              label="Reset"
              buttonType="secondary"
              style={{ height: '40px', maxWidth: '100px' }}
              onClick={() => {
                setFilterObject({ checkNumber: null, claimEraDateFrom: null, claimEraDateTo: null });
                onSubmit({ checkNumber: null, claimEraDateFrom: null, claimEraDateTo: null });
              }}
            />
          </div>
          <div className="flex gap-2 justify-end pt-2 ml-20">
            <CommonButton label="Download ERA" buttonType="primary" style={{ height: '40px' }} onClick={onDownload} />
          </div>
          <div />
        </div>
      </>
    </>
  );
};
