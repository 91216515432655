import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks/hooks';

import { MainHeader } from '../../../components/mainHeader/MainHeader';
import { PageTopic } from '../../../components/pageTopic/PageTopic';
import { Pagination } from '../../../components/pagination/Pagination';
import { PrimaryButton } from '../../../components/atoms/PrimaryButton';
import { RightSheet } from '../../../components/rightSheet/RightSheet';
import { TableContent } from '../../../components/table/TableContent';
import { CommonAlert } from '../../../components/atoms/Alert';
import { Spinner } from '../../../components/atoms/Spinner';
import { EmptyContent } from '../../../components/emptyContent/EmptyContent';
import { PaymentRightSheetForm } from '../../../components/account/codes/PaymentRightSheetForm';

import { FaFileExcel } from 'react-icons/fa';
import { IoIosInformationCircleOutline } from 'react-icons/io';

import {
  getPaymentAdjustmentCodesRequest,
  getPaymentAdjustmentCodesSelector,
  clearFetchPaymentAdjustmentCodesResponse,
} from '../../../redux/slices/codes/getPaymentAdjustmentCodeSlice';
import {
  getSystemBillingCodeTypeListRequest,
  getSystemBillingCodeTypeListSelector,
} from '../../../redux/slices/system/getBillingCodeTypeListSlice';
import {
  addPaymentAdjustmentCodeRequest,
  addPaymentAdjustmentCodeSelector,
  clearAddPaymentAdjustmentCodeResponse,
} from '../../../redux/slices/codes/addPaymentAdjustmentCodeSlice';
import {
  getPaymentAdjustmentCodeByIdRequest,
  getPaymentAdjustmentCodeByIdSelector,
  clearFetchPaymentAdjustmentCodeByIdResponse,
} from '../../../redux/slices/codes/getPaymentAdjustmentCodeByIdSlice';
import {
  updatePaymentAdjustmentCodeRequest,
  updatePaymentAdjustmentCodeSelector,
  clearUpdatePaymentAdjustmentCodeResponse,
} from '../../../redux/slices/codes/editPaymentAdjustmentCodeSlice';
import { rightSheetSelector, openRightSheet, closeRightSheet } from '../../../redux/slices/app/rightSheetSlice';

import { paymentAdjustmentCodeValidation } from '../../../utils/codeValidation';

const PAGE_SIZE = 10;

const columns = (handleBillingCodeTypeMapping: any) => [
  {
    title: 'Code',
    dataIndex: 'code',
    key: 'code',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    render: (text: number) => handleBillingCodeTypeMapping(text),
  },
  {
    title: 'Description',
    dataIndex: 'name',
    key: 'name',
  },
];

const dataSourceDummy = [
  {
    code: '99304',
    type: 2,
    name: 'Zero Payment Entry',
    isActive: true,
    displayInStatement: true,
  },
];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  { id: 'paymentAdjustment', label: 'Payment / Adjustment Code', status: 'active', link: 'payment-codes' },
];

const initialAlertState = {
  color: '',
  message: '',
  error: false,
};

export const PaymentCodesPage = () => {
  const dispatch = useAppDispatch();

  const { fetchPaymentAdjustmentCodesStatus, fetchPaymentAdjustmentCodesData, fetchPaymentAdjustmentCodesLoading } =
    useAppSelector(getPaymentAdjustmentCodesSelector);
  const { fetchSystemBillingCodeTypeListStatus, fetchSystemBillingCodeTypeListData } = useAppSelector(
    getSystemBillingCodeTypeListSelector
  );
  const { addPaymentAdjustmentCodeStatus, addPaymentAdjustmentCodeError } = useAppSelector(
    addPaymentAdjustmentCodeSelector
  );
  const { fetchPaymentAdjustmentCodeByIdStatus, fetchPaymentAdjustmentCodeByIdData } = useAppSelector(
    getPaymentAdjustmentCodeByIdSelector
  );
  const { editPaymentAdjustmentCodeStatus, editPaymentAdjustmentCodeError } = useAppSelector(
    updatePaymentAdjustmentCodeSelector
  );
  const { isRightSheetOpen, sheetId } = useAppSelector(rightSheetSelector);

  const [dataSource, setDataSource] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [billingCodeTypeList, setBillingCodeTypeList] = useState<any[]>([]);
  const [createFormData, setCreateFormData] = useState<any>({});
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [visibleModal, setVisibleModal] = useState<boolean>(false);

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [mode, setMode] = useState<string>('CREATE');
  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>(initialAlertState);

  useEffect(() => {
    setVisibleAlert(false);
    setAlertObj(initialAlertState);
    fetchSystemBillingCodeTypes();
  }, []);

  useEffect(() => {
    fetchPaymentAdjustmentCodes(0, searchTerm);
  }, [searchTerm]);

  useEffect(() => {
    if (fetchSystemBillingCodeTypeListStatus === 'SUCCESS') {
      setBillingCodeTypeList(fetchSystemBillingCodeTypeListData);
    }
  }, [fetchSystemBillingCodeTypeListStatus]);

  useEffect(() => {
    if (fetchPaymentAdjustmentCodesStatus === 'SUCCESS') {
      setDataSource(fetchPaymentAdjustmentCodesData?.items);
      setCurrentPage(fetchPaymentAdjustmentCodesData?.currentPage);
      setTotalPages(fetchPaymentAdjustmentCodesData?.totalPages);
      dispatch(clearFetchPaymentAdjustmentCodesResponse());
    } else if (fetchPaymentAdjustmentCodesStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong!',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj(initialAlertState);
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearFetchPaymentAdjustmentCodesResponse());
    }
  }, [fetchPaymentAdjustmentCodesStatus]);

  useEffect(() => {
    if (addPaymentAdjustmentCodeStatus === 'SUCCESS') {
      setAlertObj({
        color: 'success',
        message: 'Successfully created !',
        error: false,
      });
      setVisibleAlert(true);
      // setVisibleModal(false);
      dispatch(closeRightSheet());

      if (searchTerm !== '') {
        setSearchTerm('');
      } else {
        fetchPaymentAdjustmentCodes(0, '');
      }

      setTimeout(() => {
        setMode('CREATE');
        setVisibleAlert(false);
        setAlertObj(initialAlertState);
        setCreateFormData({});
      }, 3000);
      dispatch(clearAddPaymentAdjustmentCodeResponse());
    } else if (addPaymentAdjustmentCodeStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: addPaymentAdjustmentCodeError?.message || 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj(initialAlertState);
      }, 3000);
      dispatch(clearAddPaymentAdjustmentCodeResponse());
    }
  }, [addPaymentAdjustmentCodeStatus]);

  useEffect(() => {
    if (fetchPaymentAdjustmentCodeByIdStatus === 'SUCCESS') {
      const updatedData = {
        ...fetchPaymentAdjustmentCodeByIdData,
        description: fetchPaymentAdjustmentCodeByIdData.name,
      };
      delete updatedData.name;

      setCreateFormData(updatedData);
      // setVisibleModal(true);
      dispatch(openRightSheet('PaymentCodes'));
      dispatch(clearFetchPaymentAdjustmentCodeByIdResponse());
    } else if (fetchPaymentAdjustmentCodeByIdStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj(initialAlertState);
      }, 3000);
      dispatch(clearFetchPaymentAdjustmentCodeByIdResponse());
    }
  }, [fetchPaymentAdjustmentCodeByIdStatus]);

  useEffect(() => {
    if (editPaymentAdjustmentCodeStatus === 'SUCCESS') {
      setAlertObj({
        color: 'success',
        message: 'Successfully updated !',
        error: false,
      });
      setVisibleAlert(true);
      // setVisibleModal(false);
      dispatch(closeRightSheet());
      fetchPaymentAdjustmentCodes();
      setTimeout(() => {
        setMode('CREATE');
        setVisibleAlert(false);
        setAlertObj(initialAlertState);
        setCreateFormData({});
      }, 3000);
      dispatch(clearUpdatePaymentAdjustmentCodeResponse());
    } else if (editPaymentAdjustmentCodeStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: editPaymentAdjustmentCodeError?.message || 'Something went wrong !',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj(initialAlertState);
      }, 3000);
      dispatch(clearUpdatePaymentAdjustmentCodeResponse());
    }
  }, [editPaymentAdjustmentCodeStatus]);

  const onPageChange = (page: any) => {
    setCurrentPage(page - 1);
    fetchPaymentAdjustmentCodes(page - 1);
  };

  const fetchPaymentAdjustmentCodes = (pageNumber = currentPage, searchValue = searchTerm) => {
    const updatedFilterObject = { page: pageNumber, size: PAGE_SIZE, search: searchValue };
    dispatch(getPaymentAdjustmentCodesRequest({ filters: updatedFilterObject }));
  };

  const fetchSystemBillingCodeTypes = () => {
    dispatch(getSystemBillingCodeTypeListRequest());
  };

  const handleBillingCodeTypeMapping = (id: any) => {
    const billingCodeType = billingCodeTypeList.find((item) => item.id === id);
    return billingCodeType ? billingCodeType.name : '';
  };

  const fetchPaymentAdjustmentCodeById = (id: number) => {
    dispatch(getPaymentAdjustmentCodeByIdRequest({ id }));
  };

  const handleModalVisible = () => {
    setMode('CREATE');
    setCreateFormData({ isActive: false, displayInStatement: false });
    // setVisibleModal(true);
    dispatch(openRightSheet('PaymentCodes'));
  };

  const handleOnClose = () => {
    setCreateFormData({});
    // setVisibleModal(false);
    dispatch(closeRightSheet());
    setVisibleAlert(false);
    setAlertObj(initialAlertState);
    setMode('CREATE');
  };

  const handleFormDataChange = (e: any) => {
    const { name, value, type, checked } = e.target;

    const convertedValue = type === 'checkbox' ? checked : value;

    setCreateFormData((prev: any) => ({ ...prev, [name]: convertedValue }));
  };

  const createErrorAlert = (validationDetails: any) => {
    const errorMessages = Object.values(validationDetails.newErrors);
    return {
      message: (
        <div>
          {errorMessages.map((msg: any, index) => (
            <div key={index} className="flex items-center">
              <IoIosInformationCircleOutline />
              <span className="ml-2">{msg}</span>
            </div>
          ))}
        </div>
      ),
      color: 'failure',
      error: true,
    };
  };

  const convertBeforeSubmit = (formData: any) => {
    const obj = {
      ...formData,
      type: parseInt(formData?.type, 10),
      description: formData?.description || null,
      isActive: Boolean(formData.isActive),
      displayInStatement: Boolean(formData.displayInStatement),
    };

    return obj;
  };

  const onSubmit = () => {
    if (mode === 'CREATE') {
      const validationDetails = paymentAdjustmentCodeValidation(createFormData);

      if (Object.keys(validationDetails?.newErrors)?.length > 0) {
        setAlertObj(createErrorAlert(validationDetails));
        setVisibleAlert(true);
      } else {
        dispatch(addPaymentAdjustmentCodeRequest(convertBeforeSubmit(createFormData)));
      }
    } else if (mode === 'EDIT') {
      const obj = {
        id: selectedId,
        codeData: convertBeforeSubmit(createFormData),
      };

      const validationDetails = paymentAdjustmentCodeValidation(obj?.codeData);

      if (Object.keys(validationDetails?.newErrors)?.length > 0) {
        setAlertObj(createErrorAlert(validationDetails));
        setVisibleAlert(true);
      } else {
        dispatch(updatePaymentAdjustmentCodeRequest(obj));
      }
    }
  };

  const onEdit = (event: any, rowIndex: any) => {
    event.preventDefault();
    setMode('EDIT');
    const dataSet = dataSource[rowIndex];
    setSelectedId(dataSet?.id);
    fetchPaymentAdjustmentCodeById(dataSet?.id);
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
  };

  return (
    <div className="main-content">
      <MainHeader />

      {visibleAlert && !alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}

      <PageTopic onClick={handleModalVisible} mainTitle="Payment / Adjustment Code" breadCrumbArr={breadCrumbArr} />

      <div className="flex justify-end mb-4 mt-4">
        <div className="flex items-center">
          <label htmlFor="search" className="mr-2 text-gray-600">
            Search:
          </label>
          <input
            type="text"
            className="border border-gray-300 rounded px-2 py-1 mr-2 focus:outline-0 focus:ring-primaryDefault focus:border-primaryDefault"
            name="search"
            title="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <PrimaryButton
            type="button"
            label="Export to Excel"
            icon={<FaFileExcel style={{ fontSize: '18px' }} />}
            style={{ height: '38px', maxWidth: '100px' }}
            disabled
          />
        </div>
      </div>

      {fetchPaymentAdjustmentCodesLoading ? (
        <Spinner />
      ) : (
        <>
          {dataSource?.length > 0 ? (
            <>
              <TableContent
                enableActions={true}
                columns={columns(handleBillingCodeTypeMapping)}
                dataSource={dataSource}
                enableEdit={true}
                onEdit={onEdit}
              />
              {totalPages > 1 && (
                <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
              )}
            </>
          ) : (
            <EmptyContent onClick={handleModalVisible} />
          )}
        </>
      )}

      {isRightSheetOpen && sheetId === 'PaymentCodes' && (
        <RightSheet
          onClose={handleOnClose}
          title={mode === 'EDIT' ? 'Update Payment / Adjustment Code' : 'Create Payment / Adjustment Code'}
          submitButtonTitle={mode === 'EDIT' ? 'Update' : 'Create'}
          cancelButtonTitle="Cancel"
          children={
            <PaymentRightSheetForm
              createFormData={createFormData}
              onChange={handleFormDataChange}
              mode={mode}
              billingCodeTypes={billingCodeTypeList}
            />
          }
          onSubmit={onSubmit}
          enableAlert={visibleAlert}
          alertDetails={{ color: alertObj?.color, message: alertObj?.message }}
          alertOnClose={handleAlertClose}
        />
      )}
    </div>
  );
};
