import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks/hooks';

import { MainHeader } from '../../components/mainHeader/MainHeader';
import { PageTopic } from '../../components/pageTopic/PageTopic';
import { TableContent } from '../../components/table/TableContent';
import { EmptyContent } from '../../components/emptyContent/EmptyContent';
import { CommonAlert } from '../../components/atoms/Alert';
import { Pagination } from '../../components/pagination/Pagination';
import { Spinner } from '../../components/atoms/Spinner';
import { BillingSnapshotReportAdvanceSearch } from '../../components/tableSearch/BillingSnapshotReportAdvanceSearch';

import {
  getBillingSnapshotReportRequest,
  getBillingSnapshotReportSelector,
  clearBillingSnapshotReportResponse,
} from '../../redux/slices/reports/getBillingSnapshotReportSlice';
import {
  getBillingSnapshotSummaryRequest,
  getBillingSnapshotSummarySelector,
  clearFetchBillingSnapshotSummaryResponse,
} from '../../redux/slices/reports/getBillingSnapshotSummarySlice';
import {
  getSystemProvidersRequest,
  getSystemProvidersSelector,
} from '../../redux/slices/system/getSystemProvidersSlice';

import { formatCurrency } from '../../utils/commonFunctions';

const columns = [
  {
    title: 'Total Charges',
    dataIndex: 'totalCharges',
    key: 'totalCharges',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Billed Charges',
    dataIndex: 'billedCharges',
    key: 'billedCharges',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Payments',
    dataIndex: 'payments',
    key: 'payments',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Adjustment',
    dataIndex: 'adjustments',
    key: 'adjustments',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Billed Outstanding',
    dataIndex: 'billedOutStanding',
    key: 'billedOutStanding',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Unbilled Outstanding',
    dataIndex: 'notBilledOutStanding',
    key: 'notBilledOutStanding',
    render: (text: number) => formatCurrency(text ?? 0),
  },
];

const dummyDataSource = [
  {
    totalCharges: 682.0,
    billedCharges: 256.32,
    payments: 171.68,
    adjustment: 254.0,
    billedOutstanding: 0.0,
    unbilledOutstanding: 1213,
  },
];

const dummySummaryDataSource = {
  primaryBilledOutstanding: 891.0,
  unbilledOutstandingExcludingCancelled: 129.21,
};

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  {
    id: 'billingSnapshot',
    label: 'Billing Snapshot',
    status: 'active',
    link: 'billing-snapshot',
  },
];

const initialAlertState = {
  color: '',
  message: '',
  error: false,
};

export const BillingSnapshotReportPage = () => {
  const dispatch = useAppDispatch();

  const { fetchBillingSnapshotReportData, fetchBillingSnapshotReportStatus, fetchBillingSnapshotReportLoading } =
    useAppSelector(getBillingSnapshotReportSelector);
  const { fetchBillingSnapshotSummaryData, fetchBillingSnapshotSummaryStatus } = useAppSelector(
    getBillingSnapshotSummarySelector
  );

  const [dataSource, setDataSource] = useState<any[]>([]);
  const [summaryDataSource, setSummaryDataSource] = useState<any>({});
  const [filterObject, setFilterObject] = useState<any>({
    type: 'CHARGE_CREATED',
    dateFrom: null,
    dateTo: null,
    paymentDateFrom: null,
    paymentDateTo: null,
    providerId: null,
  });
  const [searchTerm, setSearchTerm] = useState<string>('');

  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>(initialAlertState);

  useEffect(() => {
    fetchSystemProviders();
    setVisibleAlert(false);
    setAlertObj(initialAlertState);
  }, []);

  useEffect(() => {
    if (filterObject) {
      fetchBillingSnapshotReport(searchTerm);
      fetchBillingSnapshotSummary(searchTerm);
    }
  }, [filterObject, searchTerm]);

  useEffect(() => {
    if (fetchBillingSnapshotReportStatus === 'SUCCESS') {
      setDataSource(fetchBillingSnapshotReportData?.items);
      dispatch(clearBillingSnapshotReportResponse());
    } else if (fetchBillingSnapshotReportStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj(initialAlertState);
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearBillingSnapshotReportResponse());
    }
  }, [fetchBillingSnapshotReportStatus]);

  useEffect(() => {
    if (fetchBillingSnapshotSummaryStatus === 'SUCCESS') {
      setSummaryDataSource(fetchBillingSnapshotSummaryData);
      dispatch(clearFetchBillingSnapshotSummaryResponse());
    } else if (fetchBillingSnapshotSummaryStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong when fetching summary!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj(initialAlertState);
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearFetchBillingSnapshotSummaryResponse());
    }
  }, [fetchBillingSnapshotSummaryStatus]);

  const fetchBillingSnapshotReport = (searchValue = searchTerm) => {
    // dispatch(getBillingSnapshotReportRequest({ filters: { ...filterObject, search: searchValue } }));
    dispatch(getBillingSnapshotReportRequest({ filters: {} }));
  };

  const fetchBillingSnapshotSummary = (searchValue = searchTerm) => {
    dispatch(getBillingSnapshotSummaryRequest({ filters: { ...filterObject, search: searchValue } }));
  };

  const fetchSystemProviders = () => {
    dispatch(getSystemProvidersRequest());
  };

  const handleSearch = (data: any) => {
    const updatedData = { ...data };

    updatedData.providerId = updatedData.providerId ? parseInt(updatedData.providerId, 10) : null;

    setFilterObject(updatedData);
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
    setAlertObj(initialAlertState);
  };

  return (
    <div className="main-content">
      <MainHeader />
      <PageTopic mainTitle="Billing Snapshot" enablePrimaryButton={false} breadCrumbArr={breadCrumbArr} />

      <BillingSnapshotReportAdvanceSearch onSubmit={handleSearch} />

      {visibleAlert && alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}

      <div className="flex justify-end mb-4">
        <div className="flex items-center">
          <label htmlFor="search" className="mr-2 text-gray-600">
            Search:
          </label>
          <input
            type="text"
            className="border border-gray-300 rounded px-2 py-1 focus:outline-0 focus:ring-primaryDefault focus:border-primaryDefault"
            name="search"
            title="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      {fetchBillingSnapshotReportLoading ? (
        <Spinner />
      ) : (
        <>
          {dataSource?.length > 0 ? (
            <>
              <TableContent columns={columns} dataSource={dataSource} enableActions={false} />

              <table className="border border-gray-100 w-full text-center mt-6">
                <thead>
                  <tr className="bg-gray-100 text-gray7">
                    <th className="border border-gray-200 p-2">Primary Billed Outstanding</th>
                    <th className="border border-gray-200 p-2">Unbilled Outstanding Excluding Cancelled</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border border-gray-200 p-2">
                      {formatCurrency(summaryDataSource.primaryBilled ?? 0)}
                    </td>
                    <td className="border border-gray-200 p-2">{formatCurrency(summaryDataSource.notBilled ?? 0)}</td>
                  </tr>
                </tbody>
              </table>
            </>
          ) : (
            <EmptyContent mode="VIEW" enableCreateButton={false} />
          )}
        </>
      )}
    </div>
  );
};
