import { Modal } from 'flowbite-react';
import { InputField } from '../../atoms/InputField';
import { SelectInput } from '../../atoms/SelectInput';
import { CommonButton } from '../../atoms/CommonButton';
import { CommonAlert } from '../../atoms/Alert';

import { BankReconciledMiscPaymentCategories } from '../../../constants/reportsConstants';

export const AddMiscPaymentModal = ({
  visibleAddMiscPaymentModal,
  handleOnClose,
  addMiscPaymentFormData,
  onChange,
  onSubmit,
  alertObj,
  visibleAlert,
  handleAlertClose,
}: any) => {
  return (
    <Modal show={visibleAddMiscPaymentModal} onClose={handleOnClose} size="lg">
      <Modal.Header>Add Miscellaneous Payment</Modal.Header>
      <Modal.Body>
        {visibleAlert && alertObj?.error && (
          <CommonAlert
            color={alertObj?.color}
            message={alertObj?.message}
            onClose={handleAlertClose}
            alertClassName="w-1/2"
          />
        )}
        <div className="grid md:grid-cols-1 gap-5">
          <InputField label="Batch Type" name="batchType" value={addMiscPaymentFormData?.batchType ?? ''} disabled />

          <InputField
            label="Check Number"
            name="checkNumber"
            value={addMiscPaymentFormData?.checkNumber ?? ''}
            onChange={onChange}
          />

          <SelectInput
            label="Category"
            options={BankReconciledMiscPaymentCategories}
            enableDefaultPlaceholder={true}
            name="category"
            onChange={onChange}
            value={addMiscPaymentFormData?.category ?? undefined}
            required
          />

          <InputField
            label="Amount"
            name="amount"
            type="number"
            value={addMiscPaymentFormData?.amount ?? ''}
            onChange={onChange}
            required
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="grid md:grid-cols-2">
        <div className="col-span-1"></div>
        <div className="col-span-1 grid md:grid-cols-2 gap-2">
          <CommonButton type="button" label={'Close'} buttonType="secondary" onClick={handleOnClose} />
          <CommonButton type="button" label={'Submit'} buttonType="primary" onClick={onSubmit} />
        </div>
      </Modal.Footer>
    </Modal>
  );
};
