import { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks/hooks';
import { useLocalStorage } from '../../../hooks/localStorageHook';
import moment from 'moment';

import { MainHeader } from '../../../components/mainHeader/MainHeader';
import { PageTopic } from '../../../components/pageTopic/PageTopic';
import { Pagination } from '../../../components/pagination/Pagination';
import { TableContent } from '../../../components/table/TableContent';
import { CommonAlert } from '../../../components/atoms/Alert';
import { Spinner } from '../../../components/atoms/Spinner';
import { EmptyContent } from '../../../components/emptyContent/EmptyContent';
import { PrimaryAgingSubReportActionArea } from '../../../components/tableActionArea/PrimaryAgingSubReportActionArea';

import { formatCurrency } from '../../../utils/commonFunctions';

const PAGE_SIZE = 20;

const columns = [
  {
    title: 'Patient ID',
    dataIndex: 'patientId',
    key: 'patientId',
  },
  {
    title: 'Patient Name',
    dataIndex: 'patientName',
    key: 'patientName',
    render: (text: any, record: any) => (
      <a className="no-underline text-linkBlue" href={`/patients?id=${record?.patientId}`}>
        {text}
      </a>
    ),
  },
  {
    title: 'Visit ID',
    dataIndex: 'visitId',
    key: 'visitId',
  },
  {
    title: 'Claim ID',
    dataIndex: 'claimId',
    key: 'claimId',
  },
  {
    title: 'Visit Date',
    dataIndex: 'visitDate',
    key: 'visitDate',
    render: (text: string) => (moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : ''),
  },
  {
    title: 'Billed Date',
    dataIndex: 'billedDate',
    key: 'billedDate',
    render: (text: string) => (moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : ''),
  },
  {
    title: 'CPT Code',
    dataIndex: 'cptCode',
    key: 'cptCode',
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    key: 'balance',
    render: (text: number) => formatCurrency(text ?? 0),
  },
];

const dummyDataSource = [
  {
    patientId: '1786',
    patientName: 'Ravanda Terry',
    visitId: '66570',
    claimId: '47791',
    visitDate: '01/03/2024',
    billedDate: '01/03/2024',
    cptCode: '99305',
    balance: 210.0,
  },
];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  {
    id: 'primaryInsuranceSubReport',
    label: 'Primary Insurance Aging',
    status: 'active',
    link: 'primary-insurance-report',
  },
];

const initialAlertState = {
  color: '',
  message: '',
  error: false,
};

const columnTitles = [
  'Current',
  'Past 1-30 Days',
  'Past 31-60 Days',
  'Past 61-90 Days',
  'Past 91-120 Days',
  'Past > 120 Days',
];

export const PrimaryInsuranceAgingSubReportPage = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { insuranceId, reportId } = useParams();

  const [insuranceName, setInsuranceName] = useLocalStorage('primarySubReportInsuranceName', '');
  const [columnTitle, setColumnTitle] = useLocalStorage('primarySubReportColumnName', '');
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [totalBalance, setTotalBalance] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>(initialAlertState);

  useEffect(() => {
    setVisibleAlert(false);
    setAlertObj(initialAlertState);
  }, []);

  useEffect(() => {
    if (location?.state?.insurance) {
      const { insurance } = location?.state;

      setInsuranceName(insurance);
    }
  }, [location?.state?.insurance]);

  useEffect(() => {
    if (reportId) {
      setColumnTitle(columnTitles[parseInt(reportId, 10) - 1] || '');
    }
  }, [reportId]);

  useEffect(() => {
    return () => {
      setColumnTitle('');
      setInsuranceName('');
    };
  }, [navigate]);

  const onPageChange = (page: any) => {
    setCurrentPage(page - 1);
  };

  const handleSearch = (value: string) => {
    setSearchTerm(value);
    setVisibleAlert(false);
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
    setAlertObj(initialAlertState);
  };

  // console.log('insuranceId', insuranceId);
  // console.log('columns', reportId);
  // console.log('columnTitle', columnTitle);
  // console.log('Insurance name', insuranceName);
  // console.log('Search', searchTerm);

  return (
    <div className="main-content">
      <MainHeader />
      <PageTopic
        mainTitle={`${insuranceName}${columnTitle ? ` [${columnTitle}]` : ''}`}
        enablePrimaryButton={false}
        breadCrumbArr={breadCrumbArr}
      />

      <PrimaryAgingSubReportActionArea
        totalBalance={formatCurrency(12324)}
        searchTerm={searchTerm}
        setSearchTerm={handleSearch}
      />

      {visibleAlert && alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}

      {dummyDataSource && dummyDataSource?.length > 0 ? (
        <>
          <TableContent enableActions={false} columns={columns} dataSource={dummyDataSource} />

          {totalPages > 1 && (
            <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
          )}
        </>
      ) : (
        <EmptyContent enableCreateButton={false} mode="VIEW" />
      )}
    </div>
  );
};
