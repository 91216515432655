import { PrimaryButton } from '../atoms/PrimaryButton';
import { Badge } from '../atoms/Badge';
import { FaFileExcel } from 'react-icons/fa';

import { PrimaryAgingSubReportActionAreaProps } from '../../interfaces/reportsInterfaces';

export const PrimaryAgingSubReportActionArea = ({
  totalBalance,
  searchTerm,
  setSearchTerm,
}: PrimaryAgingSubReportActionAreaProps) => {
  return (
    <div className="flex justify-between mt-7 mx-4 items-center">
      <div className="flex gap-2">
        <div className="font-semibold">Total Balance - </div>
        <Badge value={totalBalance} />
      </div>
      <div className="flex items-center">
        <label htmlFor="search" className="mr-2 text-gray-600">
          Search:
        </label>
        <input
          type="text"
          className="border border-gray-300 rounded px-2 py-1 mr-2 focus:outline-0 focus:ring-primaryDefault focus:border-primaryDefault"
          name="search"
          title="Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <PrimaryButton
          type="button"
          label="Export to Excel"
          icon={<FaFileExcel style={{ fontSize: '18px' }} />}
          style={{ height: '38px', maxWidth: '100px' }}
        />
      </div>
    </div>
  );
};
