import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  FetchReconciledMiscPaymentListInterface,
  FetchReconciledMiscPaymentListInitialInterface,
} from '../../../interfaces/reportsInterfaces';

const initialState: FetchReconciledMiscPaymentListInitialInterface = {
  fetchReconciledMiscPaymentListLoading: false,
  fetchReconciledMiscPaymentListData: { items: [], total: 0 },
  fetchReconciledMiscPaymentListError: null,
  fetchReconciledMiscPaymentListStatus: 'IDLE',
};

export const getReconciledMiscPaymentsRequest: any = createAsyncThunk(
  'reports/bank-reconciled/batch/get-payments',
  async (searchParams: FetchReconciledMiscPaymentListInterface, thunkAPI: any) => {
    try {
      //   const response: any = await getReconciledMiscPaymentsAPIRequest(searchParams);
      //   return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getReconciledMiscPaymentsSlice = createSlice({
  name: 'fetchReconciledMiscPaymentsList',
  initialState,
  reducers: {
    clearFetchReconciledMiscPaymentsResponse: (state) => {
      state.fetchReconciledMiscPaymentListLoading = false;
      state.fetchReconciledMiscPaymentListData = { items: [], total: 0 };
      state.fetchReconciledMiscPaymentListStatus = 'IDLE';
      state.fetchReconciledMiscPaymentListError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReconciledMiscPaymentsRequest.pending, (state, action) => {
        state.fetchReconciledMiscPaymentListLoading = true;
        state.fetchReconciledMiscPaymentListStatus = 'PENDING';
      })
      .addCase(getReconciledMiscPaymentsRequest.fulfilled, (state, action) => {
        state.fetchReconciledMiscPaymentListLoading = false;
        state.fetchReconciledMiscPaymentListStatus = 'SUCCESS';
        state.fetchReconciledMiscPaymentListData = action.payload;
      })
      .addCase(getReconciledMiscPaymentsRequest.rejected, (state, action) => {
        state.fetchReconciledMiscPaymentListLoading = false;
        state.fetchReconciledMiscPaymentListStatus = 'FAILED';
        state.fetchReconciledMiscPaymentListError = action.payload;
      });
  },
});

export const { clearFetchReconciledMiscPaymentsResponse } = getReconciledMiscPaymentsSlice.actions;

export const getReconciledMiscPaymentsSelector = (state: RootState) => state.GetReconciledMiscPayments;

export default getReconciledMiscPaymentsSlice.reducer;
