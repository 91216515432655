import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  FetchBilledAndUnbilledReportInterface,
  FetchBilledAndUnbilledReportInitialInterface,
} from '../../../interfaces/reportsInterfaces';
import { getBilledAndUnbilledReportAPIRequest } from '../../../api/reports/billedAndUnbilledReport';

const initialState: FetchBilledAndUnbilledReportInitialInterface = {
  fetchBilledAndUnbilledReportLoading: false,
  fetchBilledAndUnbilledReportData: {
    totals: {},
    items: [],
  },
  fetchBilledAndUnbilledReportError: null,
  fetchBilledAndUnbilledReportStatus: 'IDLE',
};

export const getBilledAndUnbilledReportRequest: any = createAsyncThunk(
  'reports/billed-and-unbilled/get',
  async (reportData: FetchBilledAndUnbilledReportInterface, thunkAPI: any) => {
    try {
      const response: any = await getBilledAndUnbilledReportAPIRequest(reportData);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getBilledAndUnbilledReportSlice = createSlice({
  name: 'fetchBilledAndUnbilledReport',
  initialState,
  reducers: {
    clearBilledAndUnbilledReportResponse: (state) => {
      state.fetchBilledAndUnbilledReportLoading = false;
      state.fetchBilledAndUnbilledReportData = { totals: {}, items: [] };
      state.fetchBilledAndUnbilledReportStatus = 'IDLE';
      state.fetchBilledAndUnbilledReportError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBilledAndUnbilledReportRequest.pending, (state, action) => {
        state.fetchBilledAndUnbilledReportLoading = true;
        state.fetchBilledAndUnbilledReportStatus = 'PENDING';
      })
      .addCase(getBilledAndUnbilledReportRequest.fulfilled, (state, action) => {
        state.fetchBilledAndUnbilledReportLoading = false;
        state.fetchBilledAndUnbilledReportStatus = 'SUCCESS';
        state.fetchBilledAndUnbilledReportData = action.payload;
      })
      .addCase(getBilledAndUnbilledReportRequest.rejected, (state, action) => {
        state.fetchBilledAndUnbilledReportLoading = false;
        state.fetchBilledAndUnbilledReportStatus = 'FAILED';
        state.fetchBilledAndUnbilledReportError = action.payload;
      });
  },
});

export const { clearBilledAndUnbilledReportResponse } = getBilledAndUnbilledReportSlice.actions;

export const getBilledAndUnbilledReportSelector = (state: RootState) => state.GetBilledAndUnbilledReport;

export default getBilledAndUnbilledReportSlice.reducer;
