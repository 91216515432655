import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  FetchToBeReconciledMiscPaymentListInterface,
  FetchToBeReconciledMiscPaymentListInitialInterface,
} from '../../../interfaces/reportsInterfaces';
import { getToBeReconciledMiscPaymentsAPIRequest } from '../../../api/reports/payment';

const initialState: FetchToBeReconciledMiscPaymentListInitialInterface = {
  fetchToBeReconciledMiscPaymentListLoading: false,
  fetchToBeReconciledMiscPaymentListData: { items: [], total: 0 },
  fetchToBeReconciledMiscPaymentListError: null,
  fetchToBeReconciledMiscPaymentListStatus: 'IDLE',
};

export const getToBeReconciledMiscPaymentsRequest: any = createAsyncThunk(
  'reports/bank-to-be-reconciled/batch/get-misc-payments/id',
  async (payment: FetchToBeReconciledMiscPaymentListInterface, thunkAPI: any) => {
    try {
      const response: any = await getToBeReconciledMiscPaymentsAPIRequest(payment);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getToBeReconciledMiscPaymentsSlice = createSlice({
  name: 'fetchToBeReconciledMiscPaymentsList',
  initialState,
  reducers: {
    clearFetchToBeReconciledMiscPaymentsResponse: (state) => {
      state.fetchToBeReconciledMiscPaymentListLoading = false;
      state.fetchToBeReconciledMiscPaymentListData = { items: [], total: 0 };
      state.fetchToBeReconciledMiscPaymentListStatus = 'IDLE';
      state.fetchToBeReconciledMiscPaymentListError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getToBeReconciledMiscPaymentsRequest.pending, (state, action) => {
        state.fetchToBeReconciledMiscPaymentListLoading = true;
        state.fetchToBeReconciledMiscPaymentListStatus = 'PENDING';
      })
      .addCase(getToBeReconciledMiscPaymentsRequest.fulfilled, (state, action) => {
        state.fetchToBeReconciledMiscPaymentListLoading = false;
        state.fetchToBeReconciledMiscPaymentListStatus = 'SUCCESS';
        state.fetchToBeReconciledMiscPaymentListData = action.payload;
      })
      .addCase(getToBeReconciledMiscPaymentsRequest.rejected, (state, action) => {
        state.fetchToBeReconciledMiscPaymentListLoading = false;
        state.fetchToBeReconciledMiscPaymentListStatus = 'FAILED';
        state.fetchToBeReconciledMiscPaymentListError = action.payload;
      });
  },
});

export const { clearFetchToBeReconciledMiscPaymentsResponse } = getToBeReconciledMiscPaymentsSlice.actions;

export const getToBeReconciledMiscPaymentsSelector = (state: RootState) => state.GetBankToBeReconciledMiscPayments;

export default getToBeReconciledMiscPaymentsSlice.reducer;
