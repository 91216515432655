import { useState } from 'react';

export const useSessionStorage = <T>(key: string, defaultValue: T): [T, (newValue: T) => void] => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    const value = sessionStorage.getItem(key);
    return value ? JSON.parse(value) : defaultValue;
  });

  const setValue = (newValue: T) => {
    setStoredValue(newValue);
    sessionStorage.setItem(key, JSON.stringify(newValue));
  };

  return [storedValue, setValue];
};
