import { authenticatedRequest } from '../../utils/commonAxios';
import { FetchVisitReportInterface } from '../../interfaces/reportsInterfaces';

import { queryParamGenerator } from '../../utils/commonFunctions';

export const getVisitReportAPIRequest = async (reportData: FetchVisitReportInterface) => {
  const queryParams = queryParamGenerator(reportData?.filters);

  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/report/visit${queryParams}`, 'get', {});
};
