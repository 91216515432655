import { Middleware } from '@reduxjs/toolkit';

export const persistRightSheet: Middleware = (store) => (next) => (action) => {
  const result = next(action);

  const state = store.getState().RightSheet;
  sessionStorage.setItem('sheetId', state.sheetId);
  sessionStorage.setItem('isRightSheetOpen', state.isRightSheetOpen.toString());

  return result;
};
