import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks/hooks';

import moment from 'moment';
import { debounce } from 'lodash';

import { MainHeader } from '../../components/mainHeader/MainHeader';
import { PageTopic } from '../../components/pageTopic/PageTopic';
import { TableContent } from '../../components/table/TableContent';
import { EmptyContent } from '../../components/emptyContent/EmptyContent';
import { CommonAlert } from '../../components/atoms/Alert';
import { Pagination } from '../../components/pagination/Pagination';
import { Spinner } from '../../components/atoms/Spinner';
import { ChargeDetailVisitReportAdvanceSearch } from '../../components/tableSearch/ChargeDetailVisitReportAdvanceSearch';

import {
  getChargeDetailVisitReportRequest,
  getChargeDetailVisitReportSelector,
  clearFetchChargeDetailVisitReportResponse,
} from '../../redux/slices/management/chargeDetailVisitReport/getChargeDetailVisitReportSlice';
import { getClientsListRequest } from '../../redux/slices/system/getClientsListSlice';
import {
  getSystemProvidersRequest,
  getSystemProvidersSelector,
} from '../../redux/slices/system/getSystemProvidersSlice';
import {
  getSystemFacilityRequest,
  getSystemFacilitiesSelector,
} from '../../redux/slices/system/getSystemFacilitiesSlice';

import { formatCurrency, queryParamGenerator } from '../../utils/commonFunctions';
import { handleExcelDownload } from '../../utils/excelDownload';

const PAGE_SIZE = 25;

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'PCN',
    dataIndex: 'pcn',
    key: 'pcn',
  },
  {
    title: 'Patient Name',
    dataIndex: 'patientName',
    key: 'patientName',
  },
  {
    title: 'Provider Name',
    dataIndex: 'providerName',
    key: 'providerName',
  },
  {
    title: 'Insurance Name',
    dataIndex: 'insuranceName',
    key: 'insuranceName',
  },
  {
    title: 'Visit Date',
    dataIndex: 'visitDate',
    key: 'visitDate',
    render: (text: string) => {
      return moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : '';
    },
  },
  {
    title: 'CPT Code',
    dataIndex: 'cpts',
    key: 'cpts',
    render: (text: any, record: any) => {
      return record.cpts ? record.cpts.split(',').join(', ') : '';
    },
  },
  {
    title: 'Charge',
    dataIndex: 'charge',
    key: 'charge',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Payment',
    dataIndex: 'payment',
    key: 'payment',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Recouped',
    dataIndex: 'recouple',
    key: 'recouple',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Adjustment',
    dataIndex: 'adjustment',
    key: 'adjustment',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    key: 'balance',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Visit Status',
    dataIndex: 'visitStatus',
    key: 'visitStatus',
  },
  {
    title: 'Visit Reason',
    dataIndex: 'visitReason',
    key: 'visitReason',
  },
  {
    title: 'Bill Date',
    dataIndex: 'billDate',
    key: 'billDate',
    render: (text: string) => {
      return moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : '';
    },
  },
  {
    title: 'Charge Created At',
    dataIndex: 'chargeCreated',
    key: 'chargeCreated',
    render: (text: string) => {
      return moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : '';
    },
  },
];

const dummyDataSource = [
  {
    patientId: 233,
    patientName: 'Aanaid Al Jebary',
    clientName: 'Dr. Sami AbuFarha, MD PC',
    provider: 'Mohammed Yazbek',
    insurance: 'Mi Molina Healthcare',
    visitDate: '2024-05-02',
    cpts: '99439,99490',
    charge: 170.0,
    payment: 0.0,
    recoup: 0.0,
    adjustment: 0.0,
    balance: 170.0,
  },
  {
    patientId: 489,
    patientName: 'Aanaid Al Jebary',
    clientName: 'Dr. Sami AbuFarha, MD PC',
    provider: 'Mohammed Yazbek',
    insurance: 'Mi Molina Healthcare',
    visitDate: '2024-07-23',
    cpts: '99490',
    charge: 102.0,
    payment: 43.24,
    recoup: 0.0,
    adjustment: 58.76,
    balance: 0.0,
  },
  {
    patientId: 343,
    patientName: 'Aanaid Al Jebary',
    clientName: 'Dr. Sami AbuFarha, MD PC',
    provider: 'Mohammed Yazbek',
    insurance: 'Mi Molina Healthcare',
    visitDate: '2024-06-07',
    cpts: '99490',
    charge: 102.0,
    payment: 43.24,
    recoup: 0.0,
    adjustment: 58.76,
    balance: 0.0,
  },
  {
    patientId: 5396,
    patientName: 'Aaron Hogan',
    clientName: 'Pulse Primary Care',
    provider: 'Abdulla Abdel Hafeez',
    insurance: 'Mi Bcbs',
    visitDate: '2024-07-30',
    cpts: '99490',
    charge: 102.0,
    payment: 72.6,
    recoup: 0.0,
    adjustment: 9.38,
    balance: 20.02,
  },
  {
    patientId: 5396,
    patientName: 'Aaron Hogan',
    clientName: 'Pulse Primary Care',
    provider: 'Abdulla Abdel Hafeez',
    insurance: 'Mi Bcbs',
    visitDate: '2024-08-12',
    cpts: '99439,99490',
    charge: 170.0,
    payment: 121.9,
    recoup: 0.0,
    adjustment: 14.48,
    balance: 33.62,
  },
];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  {
    id: 'chargeDetailVisitReport',
    label: 'Charge Detail Report - Visit',
    status: 'active',
    link: 'charge-detail-visit',
  },
];

const initialAlertState = {
  color: '',
  message: '',
  error: false,
};

export const ChargeDetailVisitReportPage = () => {
  const dispatch = useAppDispatch();

  const { fetchChargeDetailVisitReportStatus, fetchChargeDetailVisitReportData, fetchChargeDetailVisitReportLoading } =
    useAppSelector(getChargeDetailVisitReportSelector);
  const { systemProvidersProviderData } = useAppSelector(getSystemProvidersSelector);
  const { systemFacilityData } = useAppSelector(getSystemFacilitiesSelector);

  const initialFromDate = moment().startOf('month').format('YYYY-MM-DD');
  const initialToDate = moment().endOf('month').format('YYYY-MM-DD');

  const [dataSource, setDataSource] = useState<any[]>([]);
  const [filterObject, setFilterObject] = useState<any>({
    filterType: 'DATE_OF_SERVICE',
    dateFrom: initialFromDate,
    dateTo: initialToDate,
    providerId: null,
    facilityId: null,
    state: null,
  });
  const [searchTerm, setSearchTerm] = useState<string>('');

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>(initialAlertState);
  const [excelLoading, setExcelLoading] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!systemProvidersProviderData || systemProvidersProviderData?.length === 0) {
      fetchSystemProviders();
    }
    if (!systemFacilityData || systemFacilityData?.length === 0) {
      fetchSystemFacilities();
    }
    fetchClientsList();
    setVisibleAlert(false);
    setAlertObj(initialAlertState);
  }, []);

  useEffect(() => {
    // if (filterObject) {
    //   fetchChargeDetailVisitReport(0, searchTerm);
    // }

    const debouncedFetch = debounce((searchTerm) => {
      if (filterObject) {
        fetchChargeDetailVisitReport(0, searchTerm);
      }
    }, 550);

    setFetchLoading(true);
    debouncedFetch(searchTerm);

    return () => {
      debouncedFetch.cancel();
    };
  }, [filterObject, searchTerm]);

  useEffect(() => {
    if (fetchChargeDetailVisitReportStatus === 'SUCCESS') {
      setDataSource(fetchChargeDetailVisitReportData?.items);
      setCurrentPage(fetchChargeDetailVisitReportData?.currentPage);
      setTotalPages(fetchChargeDetailVisitReportData?.totalPages);
      setFetchLoading(false);
      dispatch(clearFetchChargeDetailVisitReportResponse());
    } else if (fetchChargeDetailVisitReportStatus === 'FAILED') {
      setFetchLoading(false);
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj(initialAlertState);
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearFetchChargeDetailVisitReportResponse());
    }
  }, [fetchChargeDetailVisitReportStatus]);

  const onPageChange = (page: any) => {
    setCurrentPage(page - 1);
    setFetchLoading(true);
    fetchChargeDetailVisitReport(page - 1);
  };

  const fetchChargeDetailVisitReport = async (pageNumber = currentPage, searchValue = searchTerm) => {
    const updatedFilterObject = { page: pageNumber, size: PAGE_SIZE, ...filterObject, search: searchValue };
    dispatch(getChargeDetailVisitReportRequest({ filters: updatedFilterObject }));
  };

  const fetchClientsList = async () => {
    dispatch(getClientsListRequest());
  };

  const fetchSystemProviders = () => {
    dispatch(getSystemProvidersRequest());
  };

  const fetchSystemFacilities = () => {
    dispatch(getSystemFacilityRequest());
  };

  const handleDownloadChargeDetailVisitExcel = (filters: any) => {
    setExcelLoading(true);

    const updatedFilters = {
      ...filters,
      search: searchTerm,
    };

    const queryParams = queryParamGenerator(updatedFilters);
    const apiUrl = `${process.env.REACT_APP_API_URL}/v1/report/visit-charge-excel-download${queryParams}`;

    handleExcelDownload({
      url: apiUrl,
      fileName: `Charge Detail as of ${moment().format('MMMM DD YYYY, hh-mm A')}.xlsx`,
      isBlob: true,
      onSuccess: () => {
        setExcelLoading(false);
      },
      onError: () => {
        setExcelLoading(false);

        setAlertObj({
          color: 'failure',
          message: 'Something went wrong when fetching report!',
          error: true,
        });
        setVisibleAlert(true);
        setTimeout(() => {
          setVisibleAlert(false);
          setAlertObj({
            color: '',
            message: '',
            error: false,
          });
        }, 3000);
      },
    });
  };

  const handleSearch = (data: any) => {
    const updatedData = { ...data };

    updatedData.providerId = updatedData.providerId ? parseInt(updatedData.providerId, 10) : null;

    updatedData.facilityId = updatedData.facilityId ? parseInt(updatedData.facilityId, 10) : null;

    updatedData.state = updatedData.state || null;

    setCurrentPage(0);
    setFilterObject(updatedData);
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
    setAlertObj(initialAlertState);
  };

  return (
    <div className="main-content">
      <MainHeader />
      <PageTopic mainTitle="Charge Detail Report - Visit" enablePrimaryButton={false} breadCrumbArr={breadCrumbArr} />

      <ChargeDetailVisitReportAdvanceSearch
        onSubmit={handleSearch}
        onExport={handleDownloadChargeDetailVisitExcel}
        excelLoading={excelLoading}
      />

      {visibleAlert && alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}

      <div className="flex justify-end mb-4">
        <div className="flex items-center">
          <label htmlFor="search" className="mr-2 text-gray-600">
            Search:
          </label>
          <input
            type="text"
            className="border border-gray-300 rounded px-2 py-1 focus:outline-0 focus:ring-primaryDefault focus:border-primaryDefault"
            name="search"
            title="Search"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      {fetchLoading ? (
        <Spinner />
      ) : (
        <>
          {dataSource?.length > 0 ? (
            <>
              <div className={`overflow-x-auto ${totalPages > 1 ? '' : 'pb-7'}`}>
                <TableContent columns={columns} dataSource={dataSource} enableActions={false} isReport={true} />
              </div>
              {totalPages > 1 && (
                <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
              )}
            </>
          ) : (
            <EmptyContent mode="VIEW" enableCreateButton={false} />
          )}
        </>
      )}
    </div>
  );
};
