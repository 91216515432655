export const providerVisitReportLagDays = [
  { id: 7, name: '7 Days' },
  { id: 14, name: '14 Days' },
  { id: 21, name: '21 Days' },
  { id: 30, name: '30 Days' },
  { id: 45, name: '45 Days' },
  { id: 60, name: '60 Days' },
  { id: 90, name: '90 Days' },
];

export const billingSnapshotReportFilterBy = [
  { id: 'CHARGE_CREATED', name: 'Charge Created' },
  { id: 'DATE_OF_SERVICE', name: 'Date of Service' },
];

export const billingSnapshotReportTypes = [
  { id: 'CI', name: 'CI' },
  { id: 'MB', name: 'MB' },
  { id: 'MC', name: 'MC' },
  { id: 'VA', name: 'VA' },
];

export const visitCountReportFilterBy = [
  { id: 'PROVIDER', name: 'Provider' },
  { id: 'FACILITY', name: 'Facility' },
];

export const ARTypes = [
  { id: 'PRIMARY', name: 'Primary Responsible' },
  { id: 'SECONDARY', name: 'Secondary Responsible' },
  { id: 'TERTIARY', name: 'Tertiary Responsible' },
  { id: 'PATIENT', name: 'Patient Responsible' },
];

export const FinancialDRArFilterBy = [
  { id: 'MONTH', name: 'Filter by Month' },
  { id: 'YEAR', name: 'Filter by Year' },
];

export const BankReconciliationReportTypes = [
  { id: 'EFT', name: 'EFTs' },
  { id: 'LOCKBOX', name: 'LockBoxes' },
  // { id: 'ERN', name: 'ERNs' },
];

export const BankReconciledMiscPaymentCategories = [
  { id: 'Bonus', name: 'Bonus' },
  { id: 'EFT Transaction Fee', name: 'EFT Transaction Fee' },
  { id: 'Missing Eobs/ ERA', name: 'Missing Eobs/ ERA' },
  { id: 'Non of our payment', name: 'Payment not related to our billing' },
  { id: 'Payer Interest Payment', name: 'Payer Interest Payment' },
  { id: 'Payer Test Deposit', name: 'Payer Test Deposit' },
];

export const CrossoverCarriersReportFoundOptions = [
  { id: 'FOUND', name: 'Secondary Found' },
  { id: 'NOT_FOUND', name: 'Secondary Not Found' },
];
