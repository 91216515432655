import { authenticatedRequest } from '../../utils/commonAxios';
import { FetchWeeklyAnalysisReportInterface } from '../../interfaces/reportsInterfaces';

import { queryParamGenerator } from '../../utils/commonFunctions';

export const getWeeklyAnalysisReportAPIRequest = async (searchParams: FetchWeeklyAnalysisReportInterface) => {
  const queryParams = queryParamGenerator(searchParams?.filters);

  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/report/weekly-analysis${queryParams}`,
    'get',
    {}
  );
};
