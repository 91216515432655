import { useState, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks/hooks';
import moment from 'moment';

import { MainHeader } from '../../components/mainHeader/MainHeader';
import { PageTopic } from '../../components/pageTopic/PageTopic';
import { TableContent } from '../../components/table/TableContent';
import { EmptyContent } from '../../components/emptyContent/EmptyContent';
import { CommonAlert } from '../../components/atoms/Alert';
import { Pagination } from '../../components/pagination/Pagination';
import { Spinner } from '../../components/atoms/Spinner';
import { ChargeDetailNoActionReportAdvanceSearch } from '../../components/tableSearch/ChargeDetailNoActionReportAdvanceSearch';

import { getSystemProvidersRequest } from '../../redux/slices/system/getSystemProvidersSlice';
import { getSystemFacilityRequest } from '../../redux/slices/system/getSystemFacilitiesSlice';

import { formatCurrency } from '../../utils/commonFunctions';

const PAGE_SIZE = 25;

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'PCN',
    dataIndex: 'pcn',
    key: 'pcn',
  },
  {
    title: 'Patient Name',
    dataIndex: 'patientName',
    key: 'patientName',
  },
  {
    title: 'Provider Name',
    dataIndex: 'provider',
    key: 'provider',
  },
  {
    title: 'Insurance Name',
    dataIndex: 'insurance',
    key: 'insurance',
  },
  {
    title: 'Visit Date',
    dataIndex: 'visitDate',
    key: 'visitDate',
    render: (text: string) => {
      return moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : '';
    },
  },
  {
    title: 'CPT Code',
    dataIndex: 'cptCode',
    key: 'cptCode',
    render: (text: any, record: any) => {
      return record.cpts ? record.cpts.split(',').join(', ') : '';
    },
  },
  {
    title: 'Charge',
    dataIndex: 'charge',
    key: 'charge',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Payment',
    dataIndex: 'payment',
    key: 'payment',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Recouped',
    dataIndex: 'recouped',
    key: 'recouped',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Adjustment',
    dataIndex: 'adjustment',
    key: 'adjustment',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    key: 'balance',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Visit Status',
    dataIndex: 'visitStatus',
    key: 'visitStatus',
  },
  {
    title: 'Visit Reason',
    dataIndex: 'visitReason',
    key: 'visitReason',
  },
  {
    title: 'Bill Date',
    dataIndex: 'billDate',
    key: 'billDate',
    render: (text: string) => {
      return moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : '';
    },
  },
  {
    title: 'Charge Created At',
    dataIndex: 'chargeCreatedAt',
    key: 'chargeCreatedAt',
    render: (text: string) => {
      return moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : '';
    },
  },
];

const dummyDataSource = [
  {
    id: '1',
    pcn: 'PCN12345',
    patientName: 'John Doe',
    provider: 'Jake K',
    insurance: 'Blue Cross',
    visitDate: '2024-02-15',
    cptCode: '99213, 99395',
    cpts: '99213,99395',
    charge: 200.0,
    payment: 100.0,
    recouped: 0.0,
    adjustment: 20.0,
    balance: 80.0,
    visitStatus: 'Completed',
    visitReason: 'Routine Checkup',
    billDate: '2024-02-20',
    chargeCreatedAt: '2024-02-21',
  },
  {
    id: '2',
    pcn: 'PCN67890',
    patientName: 'Jane Smith',
    provider: 'Jake K',
    insurance: 'United Healthcare',
    visitDate: '2024-01-10',
    cptCode: '99214',
    cpts: '99214',
    charge: 250.0,
    payment: 150.0,
    recouped: 10.0,
    adjustment: 15.0,
    balance: 75.0,
    visitStatus: 'Pending',
    visitReason: 'Routine Checkup',
    billDate: '2024-01-15',
    chargeCreatedAt: '2024-01-16',
  },
  {
    id: '3',
    pcn: 'PCN11223',
    patientName: 'Mike Johnson',
    provider: 'Jake K',
    insurance: 'Aetna',
    visitDate: '2024-03-05',
    cptCode: '99212, 99396',
    cpts: '99212,99396',
    charge: 180.0,
    payment: 120.0,
    recouped: 5.0,
    adjustment: 10.0,
    balance: 45.0,
    visitStatus: 'Completed',
    visitReason: 'Routine Checkup',
    billDate: '2024-03-08',
    chargeCreatedAt: '2024-03-09',
  },
];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  {
    id: 'chargeDetailNoActionReport',
    label: 'Charge Detail Report - No Action in 35 days',
    status: 'active',
    link: 'charge-detail-no-action',
  },
];

const initialAlertState = {
  color: '',
  message: '',
  error: false,
};

export const ChargeDetailNoActionReportPage = () => {
  const dispatch = useAppDispatch();

  const [searchTerm, setSearchTerm] = useState<string>('');

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>(initialAlertState);
  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);

  useEffect(() => {
    fetchSystemProviders();
    fetchSystemFacilities();
    setVisibleAlert(false);
    setAlertObj(initialAlertState);
  }, []);

  const onPageChange = (page: any) => {
    setCurrentPage(page - 1);
  };

  const fetchSystemProviders = () => {
    dispatch(getSystemProvidersRequest());
  };

  const fetchSystemFacilities = () => {
    dispatch(getSystemFacilityRequest());
  };

  const handleSearch = (data: any) => {};

  const handleAlertClose = () => {
    setVisibleAlert(false);
    setAlertObj(initialAlertState);
  };

  return (
    <div className="main-content">
      <MainHeader />
      <PageTopic
        mainTitle="Charge Detail Report - No Action in 35 days"
        enablePrimaryButton={false}
        breadCrumbArr={breadCrumbArr}
      />

      <ChargeDetailNoActionReportAdvanceSearch onSubmit={handleSearch} />

      {visibleAlert && alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}

      <div className="flex justify-end mb-4">
        <div className="flex items-center">
          <label htmlFor="search" className="mr-2 text-gray-600">
            Search:
          </label>
          <input
            type="text"
            className="border border-gray-300 rounded px-2 py-1 focus:outline-0 focus:ring-primaryDefault focus:border-primaryDefault"
            name="search"
            title="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      {dummyDataSource?.length > 0 ? (
        <>
          <div className={`overflow-x-auto ${totalPages > 1 ? '' : 'pb-7'}`}>
            <TableContent columns={columns} dataSource={dummyDataSource} enableActions={false} />
          </div>
          {totalPages > 1 && (
            <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
          )}
        </>
      ) : (
        <EmptyContent mode="VIEW" enableCreateButton={false} />
      )}
    </div>
  );
};
