import { authenticatedRequest } from '../../utils/commonAxios';
import { FetchProviderVisitLagReportInterface } from '../../interfaces/reportsInterfaces';

import { queryParamGenerator } from '../../utils/commonFunctions';

export const getProviderVisitLagReportAPIRequest = async (reportData: FetchProviderVisitLagReportInterface) => {
  const queryParams = queryParamGenerator(reportData?.filters);

  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/report/visit-lag${queryParams}`, 'get', {});
};
