import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  DeleteToBeReconciledMiscPaymentInterface,
  DeleteToBeReconciledMiscPaymentInitialInterface,
} from '../../../interfaces/reportsInterfaces';
import { deleteToBeReconciledMiscPaymentAPIRequest } from '../../../api/reports/payment';

const initialState: DeleteToBeReconciledMiscPaymentInitialInterface = {
  deleteToBeReconciledMiscPaymentLoading: false,
  deleteToBeReconciledMiscPaymentData: {},
  deleteToBeReconciledMiscPaymentStatus: 'IDLE',
  deleteToBeReconciledMiscPaymentError: null,
};

export const deleteToBeReconciledMiscPaymentRequest: any = createAsyncThunk(
  'reports/bank-to-be-reconciled/misc-payment/delete',
  async (payment: DeleteToBeReconciledMiscPaymentInterface, thunkAPI: any) => {
    try {
      const response: any = await deleteToBeReconciledMiscPaymentAPIRequest(payment);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const deleteToBeReconciledMiscPaymentSlice = createSlice({
  name: 'deleteBankToBeReconciledMiscPayment',
  initialState,
  reducers: {
    clearDeleteToBeReconciledMiscPaymentResponse: (state) => {
      state.deleteToBeReconciledMiscPaymentLoading = false;
      state.deleteToBeReconciledMiscPaymentStatus = 'IDLE';
      state.deleteToBeReconciledMiscPaymentData = {};
      state.deleteToBeReconciledMiscPaymentError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteToBeReconciledMiscPaymentRequest.pending, (state, action) => {
        state.deleteToBeReconciledMiscPaymentLoading = true;
        state.deleteToBeReconciledMiscPaymentStatus = 'PENDING';
      })
      .addCase(deleteToBeReconciledMiscPaymentRequest.fulfilled, (state, action) => {
        state.deleteToBeReconciledMiscPaymentLoading = false;
        state.deleteToBeReconciledMiscPaymentData = action.payload;
        state.deleteToBeReconciledMiscPaymentStatus = 'SUCCESS';
      })
      .addCase(deleteToBeReconciledMiscPaymentRequest.rejected, (state, action) => {
        state.deleteToBeReconciledMiscPaymentLoading = false;
        state.deleteToBeReconciledMiscPaymentError = action.payload;
        state.deleteToBeReconciledMiscPaymentStatus = 'FAILED';
      });
  },
});

export const { clearDeleteToBeReconciledMiscPaymentResponse } = deleteToBeReconciledMiscPaymentSlice.actions;

export const deleteToBeReconciledMiscPaymentSelector = (state: RootState) => state.DeleteBankToBeReconciledMiscPayment;

export default deleteToBeReconciledMiscPaymentSlice.reducer;
