import { useState } from 'react';

import { useAppSelector } from '../../hooks/storeHooks/hooks';
import moment from 'moment';

import { PrimaryButton } from '../atoms/PrimaryButton';
import { CommonButton } from '../atoms/CommonButton';
import { DatePickerField } from '../atoms/DatePickerField';
import { SelectInput } from '../atoms/SelectInput';
import { FaFileExcel } from 'react-icons/fa';

import { getSystemProvidersSelector } from '../../redux/slices/system/getSystemProvidersSlice';
import { getSystemFacilitiesSelector } from '../../redux/slices/system/getSystemFacilitiesSlice';
// import { getClientsListSelector } from '../../redux/slices/system/getClientsListSlice';

import { usStates } from '../../constants/systemConstants';
import { chargeDetailVisitReportFilterBy } from '../../constants/managementConstants';

import { formatDatePickerDate } from '../../utils/commonFunctions';

const dummyClientsList = [
  {
    id: 1,
    name: 'Acme Corporation',
  },
  {
    id: 2,
    name: 'Global Industries',
  },
  {
    id: 3,
    name: 'Tech Innovators LLC',
  },
  {
    id: 4,
    name: 'Healthcare Partners',
  },
  {
    id: 5,
    name: 'Finance Solutions Group',
  },
];

export const ChargeDetailVisitReportAdvanceSearch = ({ onSubmit, onExport, excelLoading }: any) => {
  const { systemProvidersProviderData } = useAppSelector(getSystemProvidersSelector);
  const { systemFacilityData } = useAppSelector(getSystemFacilitiesSelector);

  const initialFromDate = moment().startOf('month').format('YYYY-MM-DD');
  const initialToDate = moment().endOf('month').format('YYYY-MM-DD');

  const [filterObject, setFilterObject] = useState<any>({
    filterType: 'DATE_OF_SERVICE',
    dateFrom: initialFromDate,
    dateTo: initialToDate,
    providerId: null,
    facilityId: null,
    state: null,
  });

  const onChangeFilter = (e: any) => {
    setFilterObject((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onChangeDate = (name: string, date: any) => {
    setFilterObject((prev: any) => ({ ...prev, [name]: moment(date).format('YYYY-MM-DD') }));
  };

  //   console.log('filterObject', filterObject);

  return (
    <div className="table-content__adv-search">
      <form>
        <div className="table-content__adv-search-content">
          <div className="grid md:grid-cols-4 gap-4 items-end">
            <SelectInput
              enableDefaultPlaceholder={false}
              name="filterType"
              options={chargeDetailVisitReportFilterBy}
              onChange={onChangeFilter}
              value={filterObject?.filterType ?? ''}
            />
            <DatePickerField
              selectedDate={formatDatePickerDate(filterObject?.dateFrom)}
              onChange={(date: Date | null) => {
                if (date) {
                  onChangeDate('dateFrom', date);
                }
              }}
            />
            <DatePickerField
              selectedDate={formatDatePickerDate(filterObject?.dateTo)}
              onChange={(date: Date | null) => {
                if (date) {
                  onChangeDate('dateTo', date);
                }
              }}
            />
            <SelectInput
              defaultPlaceholder="Select Provider / PA"
              name="providerId"
              options={systemProvidersProviderData}
              onChange={onChangeFilter}
              value={filterObject?.providerId ?? ''}
            />
          </div>
          <div className="grid md:grid-cols-3 gap-4 mb-4 mt-4 items-end">
            <SelectInput
              defaultPlaceholder="All Facility"
              name="facilityId"
              options={systemFacilityData}
              onChange={onChangeFilter}
              value={filterObject?.facilityId ?? ''}
            />
            <SelectInput
              defaultPlaceholder="All States"
              name="state"
              options={usStates}
              onChange={onChangeFilter}
              value={filterObject?.state ?? ''}
            />
            <div className="flex gap-2 justify-start">
              <PrimaryButton
                type="button"
                label="Submit"
                style={{ height: '40px', maxWidth: '100px' }}
                onClick={() => onSubmit(filterObject)}
              />
              <CommonButton
                label="Reset"
                buttonType="secondary"
                style={{ height: '40px', maxWidth: '100px' }}
                onClick={() => {
                  setFilterObject({
                    filterType: 'DATE_OF_SERVICE',
                    dateFrom: initialFromDate,
                    dateTo: initialToDate,
                    providerId: null,
                    facilityId: null,
                    state: null,
                  });
                  onSubmit({
                    filterType: 'DATE_OF_SERVICE',
                    dateFrom: initialFromDate,
                    dateTo: initialToDate,
                    providerId: null,
                    facilityId: null,
                    state: null,
                  });
                }}
              />
              <CommonButton
                label=""
                icon={<FaFileExcel style={{ fontSize: '18px' }} />}
                style={{ height: '40px', maxWidth: '100px' }}
                onClick={() => onExport(filterObject)}
                loading={excelLoading}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
