import { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks/hooks';
import { useLocalStorage } from '../../../hooks/localStorageHook';
import moment from 'moment';

import { MainHeader } from '../../../components/mainHeader/MainHeader';
import { PageTopic } from '../../../components/pageTopic/PageTopic';
import { Pagination } from '../../../components/pagination/Pagination';
import { TableContent } from '../../../components/table/TableContent';
import { PrimaryButton } from '../../../components/atoms/PrimaryButton';
import { CommonAlert } from '../../../components/atoms/Alert';
import { Spinner } from '../../../components/atoms/Spinner';
import { EmptyContent } from '../../../components/emptyContent/EmptyContent';

import { FaFileExcel } from 'react-icons/fa';

import { formatCurrency } from '../../../utils/commonFunctions';

const PAGE_SIZE = 20;

const columns = [
  {
    title: 'Patient ID',
    dataIndex: 'patientId',
    key: 'patientId',
  },
  {
    title: 'Patient Name',
    dataIndex: 'patientName',
    key: 'patientName',
    render: (text: any, record: any) => (
      <a className="no-underline text-linkBlue" href={`/patients?id=${record?.patientId}`}>
        {text}
      </a>
    ),
  },
  {
    title: 'Visit ID',
    dataIndex: 'visitId',
    key: 'visitId',
  },
  {
    title: 'Claim ID',
    dataIndex: 'claimId',
    key: 'claimId',
  },
  {
    title: 'Visit Date',
    dataIndex: 'visitDate',
    key: 'visitDate',
    render: (text: string) => (moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : ''),
  },
  {
    title: 'Billed Date',
    dataIndex: 'billedDate',
    key: 'billedDate',
    render: (text: string) => (moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : ''),
  },
  {
    title: 'CPT Code',
    dataIndex: 'cptCode',
    key: 'cptCode',
  },
  {
    title: 'Charge',
    dataIndex: 'charge',
    key: 'charge',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Payment',
    dataIndex: 'payment',
    key: 'payment',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Adjustment',
    dataIndex: 'adjustment',
    key: 'adjustment',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    key: 'balance',
    render: (text: number) => formatCurrency(text ?? 0),
  },
];

const summaryColumns = [
  {
    title: 'Total Visits',
    dataIndex: 'totalVisits',
    key: 'totalVisits',
  },
  {
    title: 'Total Charges',
    dataIndex: 'totalCharges',
    key: 'totalCharges',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Total Payments',
    dataIndex: 'totalPayments',
    key: 'totalPayments',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Total Adjustments',
    dataIndex: 'totalAdjustments',
    key: 'totalAdjustments',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Total Balance',
    dataIndex: 'totalBalance',
    key: 'totalBalance',
    render: (text: number) => formatCurrency(text ?? 0),
  },
];

const dummyDataSource = [
  {
    patientId: '1786',
    patientName: 'Ravanda Terry',
    visitId: '66570',
    claimId: '47791',
    visitDate: '01/03/2024',
    billedDate: '01/03/2024',
    cptCode: '99305',
    charge: 210.0,
    payment: 210.0,
    adjustment: 210.0,
    balance: 210.0,
  },
];

const dummySummaryDataSource = [
  {
    totalVisits: '213',
    totalCharges: 12323,
    totalPayments: 9023,
    totalAdjustments: 2134,
    totalBalance: 3224,
  },
];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  {
    id: 'secondaryInsuranceSubReport',
    label: 'Secondary Insurance Aging',
    status: 'active',
    link: 'secondary-insurance-report',
  },
];

const initialAlertState = {
  color: '',
  message: '',
  error: false,
};

const columnTitles = [
  'Current',
  'Past 1-30 Days',
  'Past 31-60 Days',
  'Past 61-90 Days',
  'Past 91-120 Days',
  'Past > 120 Days',
];

export const CrossoverCarrierAgingSubPage = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { insuranceId, reportId } = useParams();

  const [crossoverCarrierName, setCrossoverCarrierName] = useLocalStorage('crossoverSubReportCarrierName', '');
  const [primaryPayerName, setPrimaryPayerName] = useLocalStorage('crossoverSubReportPrimaryPayerName', '');
  const [columnTitle, setColumnTitle] = useLocalStorage('crossoverSubReportColumnName', '');
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [summaryDataSource, setSummaryDataSource] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>(initialAlertState);

  useEffect(() => {
    setVisibleAlert(false);
    setAlertObj(initialAlertState);
  }, []);

  useEffect(() => {
    if (location?.state?.primaryPayer && location?.state?.crossoverCarrier) {
      const { primaryPayer, crossoverCarrier } = location?.state;

      setCrossoverCarrierName(crossoverCarrier);
      setPrimaryPayerName(primaryPayer);
    }
  }, [location?.state]);

  useEffect(() => {
    if (reportId) {
      setColumnTitle(columnTitles[parseInt(reportId, 10) - 1] || '');
    }
  }, [reportId]);

  useEffect(() => {
    return () => {
      setColumnTitle('');
      setPrimaryPayerName('');
      setCrossoverCarrierName('');
    };
  }, [navigate]);

  const onPageChange = (page: any) => {
    setCurrentPage(page - 1);
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
    setAlertObj(initialAlertState);
  };

  return (
    <div className="main-content">
      <MainHeader />
      <PageTopic
        mainTitle={`${primaryPayerName} / ${crossoverCarrierName}${columnTitle ? ` [${columnTitle}]` : ''}`}
        enablePrimaryButton={false}
        breadCrumbArr={breadCrumbArr}
      />

      <div className="flex justify-end mb-4">
        <div className="flex items-center">
          <label htmlFor="search" className="mr-2 text-gray-600">
            Search:
          </label>
          <input
            type="text"
            className="border border-gray-300 rounded px-2 py-1 mr-2 focus:outline-0 focus:ring-primaryDefault focus:border-primaryDefault"
            name="search"
            title="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <PrimaryButton
            type="button"
            label="Export to Excel"
            icon={<FaFileExcel style={{ fontSize: '18px' }} />}
            style={{ height: '38px', maxWidth: '100px' }}
          />
        </div>
      </div>

      {visibleAlert && alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}

      {dummyDataSource && dummyDataSource?.length > 0 ? (
        <>
          <TableContent enableActions={false} columns={columns} dataSource={dummyDataSource} />

          {totalPages > 1 && (
            <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
          )}

          <TableContent
            enableActions={false}
            columns={summaryColumns}
            dataSource={dummySummaryDataSource}
            enableLastRowStyles
            lastRowStyles="font-bold"
          />
        </>
      ) : (
        <EmptyContent enableCreateButton={false} mode="VIEW" />
      )}
    </div>
  );
};
